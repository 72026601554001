import type { LocalizationMessages } from "@internal/utils-i18n";

export interface LocalizedKeys {
    aiTemplatesDesignsTitle: string; // Content Panel title text for using AI Templates
    aiTemplatesImageSearchAltText: string; // Image search alt text
    aiTemplatesPanelLoaderLabel: string; // Loading label for the panel
    aiTemplateSearchInput: string; // Search input
    searchPlaceholderText: string; // This is the placeholder text inside of a search bar
    aiTemplateSearchResetButtonLabel: string; // Reset the search bar label
    searchAITemplatesButtonLabel: string; // Search label
    aiTemplatesInfiniteScrollList: string;
    title: string; // Title for the Panel Content
    closeButtonAccessibilityLabel: string;
    backButtonAccessibilityLabel: string;
    aiTemplatesSheet: string;
    refineSearchText: string; // Message to indicate to use search input for more results
    generateIdeasTabTitle: string;
    // Error messages
    noMatch: string;
    exceedSearchText: string;
    failedSearch: string;
    // Confirmation modal
    replaceAITemplateModalTitle: string;
    replaceAITemplateModalDescription: string;
    replaceAITemplateModalConfirm: string;
    replaceAITemplateModalCancel: string;
    replaceAITemplateModalClose: string;
    // Recently used
    recentlyUsedAITemplates: string;
    recentlyUsedAITemplatesPrevious: string;
    recentlyUsedAITemplatesNext: string;
    recentlyUsedScrollRight: string;
    recentlyUsedScrollLeft: string;
    //placeholder config
    primaryTextFormLabel: string;
    secondaryTextFormLabel: string;
}

export const localizationMessages: LocalizationMessages<LocalizedKeys> = {
    aiTemplatesDesignsTitle: {
        id: "editorUI.addContent.panel.aiTemplates.title",
        defaultMessage: "Designs",
        description: "Content Panel title text for using AI Templates"
    },
    aiTemplatesImageSearchAltText: {
        id: "editorUI.addContent.panel.aiTemplates.aiTemplatesImageSearchAltText",
        defaultMessage: "design"
    },
    aiTemplatesPanelLoaderLabel: {
        id: "editorUI.addContent.panel.aiTemplates.aiTemplatesPanelLoaderLabel",
        defaultMessage: "loading"
    },
    title: {
        id: "editorUI.addContent.panel.aiTemplates.title",
        defaultMessage: "Designs",
        description: "Title for the PPAG AI Templates panel"
    },
    searchPlaceholderText: {
        id: "editorUI.addContent.panel.aiTemplates.searchPlaceholderText",
        defaultMessage: "Search for designs...",
        description: "This is the placeholder text inside of a search bar"
    },
    aiTemplateSearchInput: {
        id: "editorUI.addContent.panel.aiTemplates.aiTemplateSearchInput",
        defaultMessage: "AI Template Search Input",
        description: "Label for a reset AI templates search input"
    },
    aiTemplateSearchResetButtonLabel: {
        id: "editorUI.addContent.panel.aiTemplates.aiTemplateSearchResetButtonLabel",
        defaultMessage: "Reset Search",
        description: "Label for a reset AI templates search button"
    },
    searchAITemplatesButtonLabel: {
        id: "editorUI.addContent.panel.aiTemplatessearchAITemplatesButtonLabel",
        defaultMessage: "Search designs",
        description: "Label for a search AI templates button"
    },
    aiTemplatesInfiniteScrollList: {
        id: "editorUI.addContent.panel.aiTemplatessearchAITemplatesButtonLabel",
        defaultMessage: "AI Templates infinite scroll list"
    },
    closeButtonAccessibilityLabel: {
        id: "editorUI.addContent.panel.aiTemplates.closeButtonAccessibilityLabel",
        defaultMessage: "Close",
        description: "Text for accessibility for the closes button"
    },
    backButtonAccessibilityLabel: {
        id: "editorUI.addContent.panel.aiTemplates.backButtonAccessibilityLabel",
        defaultMessage: "Back",
        description: "Text for accessibility for the back button"
    },
    aiTemplatesSheet: {
        id: "editorUI.addContent.panel.aiTemplates.sheet",
        defaultMessage: "AI Templates Sheet"
    },
    noMatch: {
        id: "editorUI.addContent.panel.aiTemplates.noMatch",
        defaultMessage: "Sorry, we couldn't find a match.",
        description: "Error message for search term without a match"
    },
    exceedSearchText: {
        id: "editorUI.addContent.panel.aiTemplates.exceedSearchText",
        defaultMessage: "Search term cannot exceed 100 character limit.",
        description: "Error message when the search term is greater than 100 characters"
    },
    refineSearchText: {
        id: "editorUI.addContent.panel.aiTemplates.refineSearchText",
        defaultMessage: "Refine search to generate more results",
        description: "Message to indicate to use the search input for more results"
    },
    failedSearch: {
        id: "editorUI.addContent.panel.aiTemplates.failedSearch",
        defaultMessage: "Sorry, we couldn't access our designs. Please try searching again.",
        description: "Error message for failed search of AI templates"
    },
    replaceAITemplateModalTitle: {
        id: "editorUI.addContent.panel.aiTemplatesPanel.replaceAITemplateModalTitle",
        defaultMessage: "Change to this design?",
        description: "Heading for changing AI templates modal"
    },
    replaceAITemplateModalDescription: {
        id: "editorUI.addContent.panel.aiTemplatesPanel.replaceAITemplateModalDescription",
        defaultMessage:
            "Adding this design will remove everything on this side of the product. Do you want to continue?",
        description: "Description for changing AI template modal"
    },
    replaceAITemplateModalConfirm: {
        id: "editorUI.addContent.panel.aiTemplatesPanel.replaceAITemplateModalConfirm",
        defaultMessage: "Change design",
        description: "Confirm button for changing AI template modal"
    },
    replaceAITemplateModalCancel: {
        id: "editorUI.addContent.panel.aiTemplatesPanel.replaceAITemplateModalCancel",
        defaultMessage: "Cancel",
        description: "Cancel button for changing AI template modal"
    },
    replaceAITemplateModalClose: {
        id: "editorUI.addContent.panel.aiTemplatesPanel.replaceAITemplateModalClose",
        defaultMessage: "Close",
        description: "Close button for changing AI template modal"
    },
    recentlyUsedAITemplates: {
        id: "editorUI.addContent.panel.aiTemplatesPanel.recentlyUsedAITemplates",
        defaultMessage: "Recently used"
    },
    recentlyUsedAITemplatesPrevious: {
        id: "editorUI.addContent.panel.aiTemplatesPanel.recentlyUsedAITemplatesPrevious",
        defaultMessage: "Go to previous templates"
    },
    recentlyUsedAITemplatesNext: {
        id: "editorUI.addContent.panel.aiTemplatesPanel.recentlyUsedAITemplatesNext",
        defaultMessage: "Go to next templates"
    },
    recentlyUsedScrollRight: {
        id: "editorUI.addContent.panel.aiTemplatesPanel.recentlyUsedAITemplates.scrollRight",
        defaultMessage: "Scroll right",
        description: "Aria label for button to scroll right"
    },
    recentlyUsedScrollLeft: {
        id: "editorUI.addContent.panel.aiTemplatesPanel.recentlyUsedAITemplates.scrollLeft",
        defaultMessage: "Scroll left",
        description: "Aria label for button to scroll left"
    },
    generateIdeasTabTitle: {
        id: "editorUI.addContent.panel.generateIdeas.title",
        defaultMessage: "Ideas",
        description: "Content Panel title text for using Generate Ideas panel"
    },
    primaryTextFormLabel: {
        id: "studio.components.panels.aiTemplatesPanel.primaryTextFormLabel",
        defaultMessage: "Primary Text",
        description: "default placeholder for primary text"
    },
    secondaryTextFormLabel: {
        id: "studio.components.panels.aiTemplatesPanel.secondaryTextFormLabel",
        defaultMessage: "Secondary Text",
        description: "default placeholder for secondary text"
    }
};
