import { ItemReference, Panel } from "@design-stack-vista/cdif-types";
import { ItemState, DesignState } from "@design-stack-vista/cimdoc-state-manager";
import {
    BaseExtension,
    EXECUTE_COMMAND_TOKEN,
    ExecuteCommand
} from "@design-stack-vista/interactive-design-engine-core";
import { action, computed, observable, makeObservable, flowResult, flow } from "mobx";
import { getPanel, getPanelColors, isVectorUrl } from "../shared/utils";
import { ColorOverrides, getFullColorOverridesWithDefaultValues } from "@internal/utils-color";
import { setColorOverrides } from "../commands";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
import { colorOverridesMatch } from "../commands/setColorOverrides";
import { VectorItemReferenceData } from "../shared/types";
import { getScopedNewrelicWrapper, NewrelicError } from "@internal/utils-newrelic";
import { ERROR_CODES } from "@internal/utils-errors";
import { NR_SCOPE } from "../constants";

type CancellablePromise<T> = Promise<T> & { cancel(): void };
function catchCancellation(promise: CancellablePromise<void>) {
    promise.catch(() => {});
    return promise;
}

type ImageColorsStatus = "loading" | "ready" | "failed";

const colorsCache = new Map<string, string[]>();
const newrelic = getScopedNewrelicWrapper(NR_SCOPE);

export class VectorExtension extends BaseExtension {
    declare designState: ItemState<ItemReference<VectorItemReferenceData>>;

    static supports(designState: DesignState): boolean {
        return (
            designState.isItemReference() &&
            designState.model.type === ItemReferenceTypes.VECTOR_PANEL &&
            isVectorUrl(designState.model.url)
        );
    }

    @observable private status: ImageColorsStatus = "loading";

    static override inject = [EXECUTE_COMMAND_TOKEN];

    private pendingColorRead: CancellablePromise<void>;

    constructor(designState: DesignState, private executeCommand: ExecuteCommand) {
        super(designState);
        makeObservable(this);
        this.pendingColorRead = catchCancellation(flowResult(this.extractImageColors()));
    }

    @computed
    get subpanelUrl(): string {
        const { model } = this.designState;
        return model.data.subpanelUrl;
    }

    @computed
    get colorOverrides(): ColorOverrides {
        const { model } = this.designState;
        return model.data.colorOverrides;
    }

    @computed
    get colors(): string[] {
        return this.colorOverrides.slice(0, 4).map(item => {
            return Object.entries(item)[0][0];
        });
    }

    @flow
    private *extractImageColors() {
        this.status = "loading";

        const { id: vectorId } = this.designState;

        try {
            if (colorsCache.has(this.subpanelUrl)) {
                this.executeCommand(cimdoc => {
                    setColorOverrides(
                        cimdoc,
                        vectorId,
                        getFullColorOverridesWithDefaultValues(colorsCache.get(this.subpanelUrl) as string[]),
                        true
                    );
                }, {});
            } else {
                const panel: Panel = yield getPanel(this.subpanelUrl);
                const colors = getPanelColors(panel);

                if (colors?.length) {
                    const overrides = getFullColorOverridesWithDefaultValues(colors);
                    const match = colorOverridesMatch(this.designState.model.data.colorOverrides, overrides, true);

                    if (!match) {
                        colorsCache.set(this.subpanelUrl, colors);
                        this.executeCommand(cimdoc => {
                            setColorOverrides(cimdoc, vectorId, overrides, true);
                        }, {});
                    }
                }
            }
            this.status = "ready";
        } catch (e) {
            newrelic.noticeError(
                new NewrelicError("Error extracting Vector colors", {
                    source: "client",
                    code: ERROR_CODES.VECTORS,
                    error: e as Error
                }),
                {
                    vectorId,
                    eventDetail: "extractImageColors"
                }
            );
            this.status = "failed";
        }
    }

    @action.bound
    changeColor(newOverrides: ColorOverrides) {
        const { id: vectorId } = this.designState;

        this.executeCommand(cimdoc => {
            setColorOverrides(cimdoc, vectorId, newOverrides, false);
        }, {});
    }

    @computed
    get isReady() {
        return this.status === "ready";
    }

    @computed
    get isLoading() {
        return this.status === "loading";
    }

    @computed
    get isFailed() {
        return this.status === "failed";
    }

    dispose() {
        super.dispose();

        this.pendingColorRead.cancel();
    }
}
