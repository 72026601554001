import { getOptionalExtension, useActivePanel, useDesignEngine } from "@design-stack-vista/core-features";
import { PatternItemExtension, patternStore } from "@pattern-sim/extensions";
import { PatternPanelMode, patternMessage } from "@pattern-sim/shared";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Button, Icon } from "@vp/swan";
import { observer } from "mobx-react-lite";
import React from "react";

export const PatternCustomization = observer(() => {
    const designEngine = useDesignEngine();
    const { activePanel } = useActivePanel();
    const patternItemExtension = activePanel && getOptionalExtension(designEngine, activePanel, PatternItemExtension);
    const { t } = useTranslationSSR();
    const { setPatternPanelMode } = patternStore;

    return (
        <>
            {patternItemExtension?.patternItem && (
                <Button
                    width="full-width"
                    iconPosition="left"
                    onClick={() => setPatternPanelMode(PatternPanelMode.Edit)}
                    size="mini"
                    data-testid="pattern-customization-button"
                >
                    <Icon
                        src={patternItemExtension.initialPatternUrl}
                        alt={t(patternMessage.customizeButtonLabel.id)}
                        aria-label={t(patternMessage.customizeButtonLabel.id)}
                        size="24p"
                        objectFit="contain"
                    />
                    {t(patternMessage.customizeButtonLabel.id)}
                </Button>
            )}
        </>
    );
});
