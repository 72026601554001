import { defineMessages } from "@vp/i18n-helper";

export const messages = defineMessages({
    dialogTitle: {
        id: "sims.teamsName.contentDiscoveryZone.howToDialog.dialogTitle",
        defaultMessage: "Design once and personalize for the entire group"
    },
    closeAccessibilityText: {
        id: "sims.teamsName.contentDiscoveryZone.howToDialog.closeAccessibilityText",
        defaultMessage: "Close the modal"
    },
    stepOneDescription: {
        id: "sims.teamsName.contentDiscoveryZone.howToDialog.stepOneDescription",
        defaultMessage: "Decide where you want to position the placeholders."
    },
    stepTwoDescription: {
        id: "sims.teamsName.contentDiscoveryZone.howToDialog.stepTwoDescription",
        defaultMessage: "On the next page, provide the details of each member of your group."
    },
    stepThreeDescription: {
        id: "sims.teamsName.contentDiscoveryZone.howToDialog.stepThreeDescription",
        defaultMessage: "Review every personalized item before adding to cart."
    }
});

export const CAROUSEL_DATA = [
    { localeKey: messages.stepOneDescription.id, imgURL: "/teamsName/step-1.jpg", step: 1 },
    { localeKey: messages.stepTwoDescription.id, imgURL: "/teamsName/step-2.jpg", step: 2, isCentral: true },
    { localeKey: messages.stepThreeDescription.id, imgURL: "/teamsName/step-3.jpg", step: 3 }
];
