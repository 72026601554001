import type { SimInterface } from "@internal/sim-framework";
import {
    ThingAContentDiscoveryZoneButton,
    ThingBContentDiscoveryZoneButton
} from "./contentDiscoveryZone/ContentDiscoveryZoneButtons";
import { PrototypeSimPreloaderPanelExtension } from "./extensions/PrototypePreloaderItemExtension";
import { name } from "./simName";
import { afterDuplicatePanelCallback } from "./afterDuplicatePanel/afterDuplicatePanelCallback";
import {
    PrototypeDraggableValue,
    useHandleDropTarget,
    useHandleItemPlaceholderDropTarget
} from "./dragAndDrop/dragAndDrop";
import defaultTranslations from "./i18n/en-US.json";
import { openContentPanelAfterItemSelectionCallback } from "./openContentPanelAfterItemSelectionCallback/openContentPanelAfterItemSelectionCallback";
import { usePrototypeSim } from "./useOverrideEnableState";
import { prototypeActiveDialogTypes } from "./prototypeContentSymbols";
import { ItemPreview } from "./itemPreview/ItemPreview";

const prototypeSim = {
    name,
    core: {
        defaultTranslations,
        extensions: [PrototypeSimPreloaderPanelExtension],
        contentDiscoveryZone: {
            Default: [
                {
                    activeDialogType: prototypeActiveDialogTypes["thing A"],
                    component: ThingAContentDiscoveryZoneButton
                },
                {
                    activeDialogType: prototypeActiveDialogTypes["thing B"],
                    component: ThingBContentDiscoveryZoneButton
                }
            ]
        },
        afterDuplicatePanelCallback,
        dragAndDrop: {
            [PrototypeDraggableValue]: {
                useHandleDropTarget,
                useHandleItemPlaceholderDropTarget
            }
        },
        selectedZone: {
            detailZoneIds: new Set([
                { type: "ColorPicker", activeDialogType: prototypeActiveDialogTypes.ColorPicker },
                { type: "General", activeDialogType: prototypeActiveDialogTypes.SomeSelectionToolUI }
            ])
        },
        openContentPanelAfterItemSelectionCallback,
        useOverrideEnableState: usePrototypeSim,
        itemPreview: {
            matchSelection: "shape",
            ItemPreview: ItemPreview
        }
    },
    deferredLoader: () => import("./index.deferred")
} satisfies SimInterface<
    { feature: "CustomItemPreview"; matchSelection: "shape" } | "ContentDiscoveryZone" | "DragAndDrop" | "SelectedZone"
>;

export default prototypeSim;
