import { useCallback } from "react";
import { getBoundingBox } from "@design-stack-vista/utility-core";
import {
    PanelChromesExtension,
    PanelTextRecommendationExtension,
    useDesignEngine,
    useFontConfiguration,
    getRequiredExtension,
    getActivePanel
} from "@design-stack-vista/core-features";
import { parsePanelDimensionsToMm } from "@design-stack-vista/cimdoc-state-manager";
import { useGetRandomPosition } from "@internal/utils-item-position";
import { useGetItemDefaultColor } from "@internal/utils-item-color";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useGetTeamsNameTextPlaceholderSuffix } from "./useGetTeamsNameTextPlaceholderSuffix";
import { addTeamsNameItem } from "../commands";
import { useSimTeamsName } from "../shared";
import { TeamsNamePlaceholderType } from "../@types";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";

interface UseAddTeamsNameItemProps {
    placeholderContent: string;
    placeholderKey: string;
    placeholderType: TeamsNamePlaceholderType;
}

const DEFAULT_FONT_FAMILY = "Noto Sans";

export function useAddTeamsNameItem() {
    const designEngine = useDesignEngine();
    const { executeCommand, idaStore, cimDocStore, layoutStore } = designEngine;
    const getRandomPosition = useGetRandomPosition();
    const getTeamsNameTextPlaceholderSuffix = useGetTeamsNameTextPlaceholderSuffix();
    const getItemDefaultColor = useGetItemDefaultColor();
    const { getSubstrateColor } = useSimTeamsName();
    const { studioSelectedProductOptions } = useProductAndProjectStateManager().data;

    const currentPanelId = idaStore.activeDesignPanelId ?? cimDocStore.panels[0].id;
    const currentPanel = getActivePanel(designEngine) ?? layoutStore.visiblePanels[0];
    if (!currentPanel) {
        throw new Error(`useAddTeamsNameItem: no panel found with id: ${currentPanelId}`);
    }

    const panelExtension = getRequiredExtension(designEngine, currentPanel, PanelChromesExtension);
    const safeMask = panelExtension.masks.find(mask => mask.type === "SAFE");
    const { width: safeAreaWidth } = safeMask
        ? getBoundingBox(safeMask.paths[0].points)
        : parsePanelDimensionsToMm(currentPanel);

    const { defaultFontFamily: defaultFontFamilyFromConfig } = useFontConfiguration();
    const panelTextRecommendationComponent = getRequiredExtension(
        designEngine,
        currentPanel,
        PanelTextRecommendationExtension
    );
    const fontFamily =
        panelTextRecommendationComponent.defaultFontFamily ?? defaultFontFamilyFromConfig ?? DEFAULT_FONT_FAMILY;

    return useCallback(
        ({ placeholderContent, placeholderKey, placeholderType }: UseAddTeamsNameItemProps) => {
            let key = placeholderKey;
            let content = placeholderContent;
            if (placeholderType === "Text") {
                // Placeholder 1, Placeholder 2, Placeholder 3, Placeholder 4, etc.
                key = `${key} ${getTeamsNameTextPlaceholderSuffix()}`;
                content = key;
            }
            const width = (safeAreaWidth * 2) / 3;
            const height = width / (placeholderType === "Number" ? 2 : 6.25);
            const { x, y } = getRandomPosition({ width, height });
            const { commandOutput } = executeCommand(addTeamsNameItem, {
                panel: currentPanel,
                fontFamily,
                /**
                 * @todo: need to pass the substrate color as a param, will be added in another ticket
                 */
                color: getItemDefaultColor({
                    substrateColor: getSubstrateColor(studioSelectedProductOptions),
                    itemType: ItemReferenceTypes.TEAMS_NAME
                }),
                placeholderData: {
                    content,
                    placeholderType,
                    placeholderKey: key
                },
                width,
                height,
                x,
                y
            });
            idaStore.setSelectedIds([commandOutput.id]);
        },
        [
            safeAreaWidth,
            getRandomPosition,
            executeCommand,
            currentPanel,
            fontFamily,
            getItemDefaultColor,
            getSubstrateColor,
            studioSelectedProductOptions,
            idaStore,
            getTeamsNameTextPlaceholderSuffix
        ]
    );
}
