import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { PanelContent, PanelDescription, PanelTitle } from "@internal/ui-studio-chrome";
import { TeamsNamePlaceholderButtons } from "./TeamsNamePlaceholderButtons";
import { contentPanelMessages } from "./messages";
import { HowToDialog } from "src/contentDiscoveryZone/components/HowToDialog";
import styles from "./TeamsNameContentPanel.module.scss";

export const TeamsNameContentPanel = () => {
    const { t } = useTranslationSSR();

    return (
        <PanelContent className={styles.teamsNamePanel} aria-label={t(contentPanelMessages.contentTitle.id)}>
            <PanelTitle>{t(contentPanelMessages.title.id)}</PanelTitle>
            <PanelDescription>{t(contentPanelMessages.info.id)}</PanelDescription>
            <TeamsNamePlaceholderButtons />
            <HowToDialog />
        </PanelContent>
    );
};

TeamsNameContentPanel.displayName = "TeamsNameContentPanel";
