import { CimDoc } from "@design-stack-vista/cdif-types";
import { DotPattern, EyePattern, LegacyQRCodeItemReferenceData } from "../types";
import { apiConfig } from "../clients/apiConfig";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";

export const DEFAULT_QR_CODE_COLOR = "#000000";
export const DEFAULT_BASE_COLOR = "#ffffff";

export const DEFAULT_QR_CODE_DATA = {
    backgroundColor: DEFAULT_BASE_COLOR,
    dotsOptions: {
        pattern: DotPattern.Square,
        color: DEFAULT_QR_CODE_COLOR
    },
    eyeOptions: {
        eyePattern: EyePattern.Square,
        eyeColor: DEFAULT_QR_CODE_COLOR,
        eyeBorderColor: DEFAULT_QR_CODE_COLOR
    }
};

export function fillQRDataWithDefaultValues(partialQRData: LegacyQRCodeItemReferenceData) {
    const { color, ...rest } = partialQRData;

    // merging legacy QR code data with new QR data properties
    const QRCodeData = { ...DEFAULT_QR_CODE_DATA, ...rest };
    return QRCodeData;
}

export const upgradeLegacyQRCode = (cimDoc: CimDoc) => {
    const {
        qrCodeGenerator: { baseUrl, itemRefEndPoint }
    } = apiConfig;

    cimDoc.document.panels.forEach(panel => {
        panel.itemReferences?.forEach(itemRef => {
            if (itemRef.type === ItemReferenceTypes.QR_CODE && !itemRef.data.dotsOptions) {
                itemRef.url = `${baseUrl}${itemRefEndPoint}`;
                itemRef.data = fillQRDataWithDefaultValues(itemRef.data);
            }
        });
    });
};
