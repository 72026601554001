import { useMemo } from "react";
import produce from "immer";
import { CimDoc } from "@design-stack-vista/cdif-types";
import { useDesignEngine } from "@design-stack-vista/core-features";
import {
    mapDesignAttributeToProductOption,
    mapProductOptionValueToDesignAttributeValue
} from "@internal/utils-studio-configuration-core";
import { useDesignAttributeMappings } from "@shared/features/StudioBootstrap";
// eslint-disable-next-line import/no-restricted-paths
import { removeUnreplacedPlaceholdersFromCimDoc } from "@six/features/DesignDocument/useGetDesignDocument";
// eslint-disable-next-line import/no-restricted-paths
import { SceneConfiguration, useSceneConfiguration } from "@six/features/editorUI";
import { DSS } from "@vp/types-ddif";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";

type UsePanelPreviewDataReturn = {
    cimDoc: DSS.DesignDocument;
    sceneConfiguration: SceneConfiguration | undefined;
    isFoldedProduct: boolean;
};

export const usePanelPreviewData = (): UsePanelPreviewDataReturn => {
    const { cimDocStore } = useDesignEngine();
    const sceneConfiguration = useSceneConfiguration();

    const designAttributeMappings = useDesignAttributeMappings();
    const { studioSelectedProductOptions } = useProductAndProjectStateManager().data;

    const isFoldedProduct = useMemo<boolean>(() => {
        const foldedProductOption = mapDesignAttributeToProductOption(designAttributeMappings, "Fold");
        return (
            !!studioSelectedProductOptions[foldedProductOption] &&
            mapProductOptionValueToDesignAttributeValue(
                designAttributeMappings,
                foldedProductOption,
                studioSelectedProductOptions[foldedProductOption]
            ) === "Folded"
        );
    }, [designAttributeMappings, studioSelectedProductOptions]);

    const cimDoc = useMemo<CimDoc>(() => {
        return produce(cimDocStore.asJson, draft => {
            const newCimDoc = removeUnreplacedPlaceholdersFromCimDoc(draft);
            if (isFoldedProduct) {
                delete newCimDoc.projectionId;
            }
            return newCimDoc;
        });
    }, [cimDocStore.asJson, isFoldedProduct]);

    return { cimDoc, sceneConfiguration, isFoldedProduct };
};
