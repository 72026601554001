import { CimDoc } from "@design-stack-vista/cdif-types";
import { ColorOverrides } from "@internal/utils-color";
import { mergeColorOverrides } from "../shared/utils/mergeColorOverrides";
import { COLOR_SWATCHES_LIMIT } from "../constants";
import { getCimDocNodeById } from "@design-stack-vista/cimdoc-state-manager";
import { isVectorItem } from "../shared/utils";

export const colorOverridesMatch = (override1: ColorOverrides, override2: ColorOverrides, keepOldValues: boolean) => {
    return (
        override1.length === override2.length &&
        override1.every(o1 =>
            override2.some(o2 => {
                const o1Keys = Object.keys(o1);
                const o2Keys = Object.keys(o2);

                // We only care about the first index (we also assume that there is only one key)
                // If keepOldValues is true, then we only care if the keys match; otherwise the entire object needs to match
                return keepOldValues
                    ? o1Keys[0] === o2Keys[0]
                    : o1Keys[0] === o2Keys[0] && o1[o1Keys[0]] === o2[o2Keys[0]];
            })
        )
    );
};

export function setColorOverrides(
    cimDoc: CimDoc,
    itemReferenceId: string,
    overrides: ColorOverrides,
    keepOldValues?: boolean
) {
    const node = getCimDocNodeById(cimDoc, itemReferenceId);

    if (!node) {
        throw new Error(`No itemReference found with matching id: ${itemReferenceId}`);
    }

    const { element: item } = node;

    if (!isVectorItem(item)) {
        throw new Error("Only vector itemReference supports setting colorOverrides for now");
    }

    if (!Array.isArray(overrides)) {
        throw new Error("Overrides should be an array of strings");
    }

    item.data.colorOverrides = mergeColorOverrides(item.data.colorOverrides, overrides, keepOldValues).slice(
        0,
        COLOR_SWATCHES_LIMIT
    );
}
