import { z } from "zod";
import type { SimInterface } from "@internal/sim-framework";
import defaultTranslations from "./i18n/en-US.json";

const api = z.object({
    addNewTextItem: z.array(z.enum(["text", "wordart"])),
    allowDeleteText: z.boolean()
});

/**
 * @note this is a stub of the text SIM. For now, it only exports
 * the API aspects of the SIM. The rest of the code still lives in
 * the Studio app and will eventually migrate here. Thus, we need to
 * manually "merge" these two parts and will ultimately treat this
 * code differently than a normal sim until it is fully extracted
 * in this sim library.
 */
const textSim = {
    name: "@internal/advanced-editor-sim-text",
    core: {
        defaultTranslations,
        extensions: []
    },
    api
} satisfies SimInterface;

export default textSim;
