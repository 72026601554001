import { useContext } from "react";
import { SimLocaleContext } from "./SimLocaleProvider";

export const useSimLocale = () => {
    const result = useContext(SimLocaleContext);

    if (result === undefined) {
        throw new Error("Component must be wrapped in SimLocaleProvider");
    }

    return result;
};
