import React, { useEffect, useState } from "react";
import { useDesignEngine } from "@design-stack-vista/core-features";
import { PatternsContentManager, PatternsContentProvider } from "@design-stack-vista/pattern-sdk";
import { CategoryList } from "./CategoryList";
import { observer } from "mobx-react-lite";
import { FlexBox } from "@vp/swan";
import { PatternDetailView } from "./PatternDetailView";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { PatternTextDescription } from "./PatternTextDescription";
import { PatternAction } from "./PatternAction";
import { PatternList } from "./PatternList";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { useSimLocale } from "@internal/sim-framework";
import { acceptedFormatLocal } from "@pattern-sim/shared";
import styles from "./PatternContainer.module.scss";

/**
 * The profile type of the user.
 */
const PROFILE_TYPE = "https://claims.cimpress.io/account";
/**
 * The folder name of the sherbert patterns.
 */
const sherbertFolderName = "IoBX4KKMc0uyUyA0tFQDg";

export const PatternContainer = observer(() => {
    const { authProvider } = useDesignEngine();
    const { auth } = useIdentityContext();
    const locale = useSimLocale();
    const trackingClient = useTrackingClient();

    const tenant = auth.getProfile()[PROFILE_TYPE];

    const [showDetailedView, setShowDetailedView] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const [patternContentManager, setPatternContentManager] = useState<PatternsContentManager | null>(null);

    useEffect(() => {
        /**
         * Patterns-sdk accept format of locale as "en-US"
         * The local we get in studio is "en-us".
         * Converting it to acceptable format.
         */
        const manager = new PatternsContentManager({
            authProvider,
            renderingTenant: tenant,
            locale: acceptedFormatLocal(locale),
            sherbertFolderName
        });

        setPatternContentManager(manager);
    }, [authProvider, locale, tenant]);

    const handleSelectedCategory = (categoryName: string) => {
        trackingClient.track(Events.DesignToolUsed, {
            eventDetail: `Pattern category selected`
        });
        if (categoryName) {
            setSelectedCategory(categoryName);
            setShowDetailedView(true);
        }
    };

    return (
        <>
            {patternContentManager && (
                <PatternsContentProvider contentManager={patternContentManager}>
                    {showDetailedView ? (
                        <PatternDetailView
                            selectedCategory={selectedCategory}
                            returnCallback={() => setShowDetailedView(false)}
                        />
                    ) : (
                        <FlexBox flexDirection="column" className={styles.patternContainer}>
                            <FlexBox flexDirection="column" className={styles.patternHeader}>
                                <PatternTextDescription />
                                <PatternAction />
                                <PatternList handleSelectedCategory={handleSelectedCategory} />
                            </FlexBox>
                            <CategoryList handleSelectedCategory={handleSelectedCategory} />
                        </FlexBox>
                    )}
                </PatternsContentProvider>
            )}
        </>
    );
});

PatternContainer.displayName = "PatternContainer";
