import { defineMessages } from "@vp/i18n-helper";

export const validationsMessages = defineMessages({
    "default.validationIcon.warning": {
        id: "editorUI.validations.default.validationIcon.warning",
        defaultMessage: "Validation warning",
        description: {}
    },
    "default.validationIcon.error": {
        id: "editorUI.validations.default.validationIcon.error",
        defaultMessage: "Validation error",
        description: {}
    },
    "outsideBounds.print.image": {
        id: "easel.designer.translation.validations.outsideBounds.print.image",
        defaultMessage: "The image is outside of the design canvas and will be cut during printing.",
        description: {}
    },
    "outsideBounds.print.shape": {
        id: "easel.designer.translation.validations.outsideBounds.print.shape",
        defaultMessage: "The shape is outside of the design canvas and will be cut during printing.",
        description: {}
    },
    "outsideBounds.print.text": {
        id: "easel.designer.translation.validations.outsideBounds.print.text",
        defaultMessage: "The text is outside of the design canvas and will be cut during printing.",
        description: {}
    },
    "outsideBounds.print.itemReference": {
        id: "easel.designer.translation.validations.outsideBounds.print.itemReference",
        defaultMessage: "The item is outside of the design canvas and will be cut during printing.",
        description: {}
    },
    "outsideBounds.print.subpanel": {
        id: "easel.designer.translation.validations.outsideBounds.print.subpanel",
        defaultMessage: "The item is outside of the design canvas and will be cut during printing.",
        description: {}
    },
    "outsideBounds.print.validationIcon.warning": {
        id: "editorUI.validations.outsideBounds.print.validationIcon.warning",
        defaultMessage: "Outside canvas warning",
        description: {}
    },
    "outsideBounds.print.validationIcon.error": {
        id: "editorUI.validations.outsideBounds.print.validationIcon.error",
        defaultMessage: "Outside canvas error",
        description: {}
    },
    "outsideBounds.embroidery.validationIcon.warning": {
        id: "editorUI.validations.outsideBounds.embroidery.validationIcon.warning",
        defaultMessage: "Outside canvas warning",
        description: {}
    },
    "outsideBounds.embroidery.validationIcon.error": {
        id: "editorUI.validations.outsideBounds.embroidery.validationIcon.error",
        defaultMessage: "Outside canvas error",
        description: {}
    },
    "outsideBounds.embroidery.image": {
        id: "easel.designer.translation.validations.outsideBounds.embroidery.image",
        defaultMessage:
            "The image is outside of the design area. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
        description: {}
    },
    "outsideBounds.embroidery.text": {
        id: "easel.designer.translation.validations.outsideBounds.embroidery.text",
        defaultMessage:
            "The text is outside of the design area. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
        description: {}
    },
    "outsideBounds.embroidery.shape": {
        id: "easel.designer.translation.validations.outsideBounds.embroidery.shape",
        defaultMessage:
            "The shape is outside of the design area. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
        description: {}
    },
    "outsideBounds.embroidery.itemReference": {
        id: "easel.designer.translation.validations.outsideBounds.embroidery.itemReference",
        defaultMessage:
            "The item is outside of the design area. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
        description: {}
    },
    "outsideBounds.embroidery.subpanel": {
        id: "easel.designer.translation.validations.outsideBounds.embroidery.subpanel",
        defaultMessage:
            "The item is outside of the design area. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
        description: {}
    },
    "betweenBoundaries.print.image": {
        id: "easel.designer.translation.validations.betweenBoundaries.print.image",
        defaultMessage: "Extend your image to the outer edge to avoid unintended white borders when printed.",
        description: {}
    },
    "betweenBoundaries.print.shape": {
        id: "easel.designer.translation.validations.betweenBoundaries.print.shape",
        defaultMessage: "Extend your shape to the outer edge to avoid unintended white borders when printed.",
        description: {}
    },
    "betweenBoundaries.print.text": {
        id: "easel.designer.translation.validations.betweenBoundaries.print.text",
        defaultMessage: "Extend your text to the outer edge to avoid unintended white borders when printed.",
        description: {}
    },
    "betweenBoundaries.print.itemReference": {
        id: "easel.designer.translation.validations.betweenBoundaries.print.itemReference",
        defaultMessage: "Extend your item to the outer edge to avoid unintended white borders when printed.",
        description: {}
    },
    "betweenBoundaries.print.subpanel": {
        id: "easel.designer.translation.validations.betweenBoundaries.print.subpanel",
        defaultMessage: "Extend your item to the outer edge to avoid unintended white borders when printed.",
        description: {}
    },
    "betweenBoundaries.print.validationIcon.warning": {
        id: "editorUI.validations.betweenBoundaries.print.validationIcon.warning",
        defaultMessage: "White borders warning",
        description: {}
    },
    "betweenBoundaries.print.validationIcon.error": {
        id: "editorUI.validations.betweenBoundaries.print.validationIcon.error",
        defaultMessage: "White borders error",
        description: {}
    },
    "outsideMargins.print.image": {
        id: "easel.designer.translation.validations.outsideMargins.print.image",
        defaultMessage: "The image is outside the margins, and parts of it might be cut off during printing.",
        description: {}
    },
    "outsideMargins.print.shape": {
        id: "easel.designer.translation.validations.outsideMargins.print.shape",
        defaultMessage: "The shape is outside the margins, and parts of it might be cut off during printing.",
        description: {}
    },
    "outsideMargins.print.text": {
        id: "easel.designer.translation.validations.outsideMargins.print.text",
        defaultMessage: "The text is outside the margins, and parts of it might be cut off during printing.",
        description: {}
    },
    "outsideMargins.print.itemReference": {
        id: "easel.designer.translation.validations.outsideMargins.print.itemReference",
        defaultMessage: "The item is outside the margins, and parts of it might be cut off during printing.",
        description: {}
    },
    "outsideMargins.print.subpanel": {
        id: "easel.designer.translation.validations.outsideMargins.print.subpanel",
        defaultMessage: "The item is outside the margins, and parts of it might be cut off during printing.",
        description: {}
    },
    "outsideMargins.mailingValidation.print.text": {
        id: "easel.designer.translation.validations.outsideMargins.mailingValidation.print.text",
        defaultMessage: "This element is outside the design area and will not appear when your postcard is printed.",
        description: {}
    },
    "outsideMargins.mailingValidation.print.image": {
        id: "easel.designer.translation.validations.outsideMargins.mailingValidation.print.image",
        defaultMessage: "This element is outside the design area and will not appear when your postcard is printed.",
        description: {}
    },
    "outsideMargins.mailingValidation.print.shape": {
        id: "easel.designer.translation.validations.outsideMargins.mailingValidation.print.shape",
        defaultMessage: "This element is outside the design area and will not appear when your postcard is printed.",
        description: {}
    },
    "outsideMargins.mailingValidation.print.itemReference": {
        id: "easel.designer.translation.validations.outsideMargins.mailingValidation.print.itemReference",
        defaultMessage: "This element is outside the design area and will not appear when your postcard is printed.",
        description: {}
    },
    "outsideMargins.mailingValidation.print.subpanel": {
        id: "easel.designer.translation.validations.outsideMargins.mailingValidation.print.subpanel",
        defaultMessage: "This element is outside the design area and will not appear when your postcard is printed.",
        description: {}
    },
    "outsideMargins.mailingValidation.print.validationIcon.warning": {
        id: "editorUI.validations.outsideMargins.mailingValidation.print.validationIcon.warning",
        defaultMessage: "Outside margins warning",
        description: {}
    },
    "outsideMargins.mailingValidation.print.validationIcon.error": {
        id: "editorUI.validations.outsideMargins.mailingValidation.print.validationIcon.error",
        defaultMessage: "Outside margins error",
        description: {}
    },
    "outsideMargins.print.validationIcon.warning": {
        id: "editorUI.validations.outsideMargins.print.validationIcon.warning",
        defaultMessage: "Outside margins warning",
        description: {}
    },
    "outsideMargins.print.validationIcon.error": {
        id: "editorUI.validations.outsideMargins.print.validationIcon.error",
        defaultMessage: "Outside margins error",
        description: {}
    },
    "outsideMargins.embroidery.validationIcon.warning": {
        id: "editorUI.validations.outsideMargins.embroidery.validationIcon.warning",
        defaultMessage: "Outside margins warning",
        description: {}
    },
    "outsideMargins.embroidery.validationIcon.error": {
        id: "editorUI.validations.outsideMargins.embroidery.validationIcon.error",
        defaultMessage: "Outside margins error",
        description: {}
    },
    "outsideMargins.embroidery.image": {
        id: "easel.designer.translation.validations.outsideMargins.embroidery.image",
        defaultMessage:
            "The image is outside the margins. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
        description: {}
    },
    "outsideMargins.embroidery.text": {
        id: "easel.designer.translation.validations.outsideMargins.embroidery.text",
        defaultMessage:
            "The text is outside the margins. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
        description: {}
    },
    "outsideMargins.embroidery.itemReference": {
        id: "easel.designer.translation.validations.outsideMargins.embroidery.itemReference",
        defaultMessage:
            "The item is outside the margins. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
        description: {}
    },
    "outsideMargins.embroidery.subpanel": {
        id: "easel.designer.translation.validations.outsideMargins.embroidery.subpanel",
        defaultMessage:
            "The item is outside the margins. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
        description: {}
    },
    "outsideMargins.embroidery.shape": {
        id: "easel.designer.translation.validations.outsideMargins.embroidery.shape",
        defaultMessage:
            "The shape is outside the margins. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
        description: {}
    },
    "overlap.print.image": {
        id: "easel.designer.translation.validations.overlap.print.image",
        defaultMessage: "The image overlaps with another object and might not print properly.",
        description: {}
    },
    "overlap.print.shape": {
        id: "easel.designer.translation.validations.overlap.print.shape",
        defaultMessage: "The shape overlaps with another object and might not print properly.",
        description: {}
    },
    "overlap.print.text": {
        id: "easel.designer.translation.validations.overlap.print.text",
        defaultMessage: "The text overlaps with another object and might not print properly.",
        description: {}
    },
    "overlap.print.itemReference": {
        id: "easel.designer.translation.validations.overlap.print.itemReference",
        defaultMessage: "The item overlaps with another object and might not print properly.",
        description: {}
    },
    "overlap.print.subpanel": {
        id: "easel.designer.translation.validations.overlap.print.subpanel",
        defaultMessage: "The item overlaps with another object and might not print properly.",
        description: {}
    },
    "overlap.print.validationIcon.warning": {
        id: "editorUI.validations.overlap.print.validationIcon.warning",
        defaultMessage: "Overlap warning",
        description: {}
    },
    "overlap.print.validationIcon.error": {
        id: "editorUI.validations.overlap.print.validationIcon.error",
        defaultMessage: "Overlap error",
        description: {}
    },
    "overlap.embroidery.image": {
        id: "easel.designer.translation.validations.overlap.embroidery.image",
        defaultMessage:
            "The image overlaps with another object in your design. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
        description: {}
    },
    "overlap.embroidery.text": {
        id: "easel.designer.translation.validations.overlap.embroidery.text",
        defaultMessage:
            "The text overlaps with another object in your design. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
        description: {}
    },
    "overlap.embroidery.shape": {
        id: "easel.designer.translation.validations.overlap.embroidery.shape",
        defaultMessage:
            "The shape overlaps with another object in your design. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
        description: {}
    },
    "overlap.embroidery.itemReference": {
        id: "easel.designer.translation.validations.overlap.embroidery.itemReference",
        defaultMessage:
            "The item overlaps with another object in your design. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
        description: {}
    },
    "overlap.embroidery.subpanel": {
        id: "easel.designer.translation.validations.overlap.embroidery.subpanel",
        defaultMessage:
            "The item overlaps with another object in your design. It will be rejected when it goes to production because it cannot be embroidered by the machines.",
        description: {}
    },
    "imageResolution.warning": {
        id: "easel.designer.translation.validations.imageResolution.warning",
        defaultMessage:
            "Your image has low resolution and might look blurry when printed. Try uploading a higher resolution image.",
        description: {}
    },
    "imageResolution.error": {
        id: "easel.designer.translation.validations.imageResolution.error",
        defaultMessage:
            "Your image has very low resolution and won't print well. Try uploading a higher resolution image.",
        description: {}
    },
    "imageResolution.validationIcon.warning": {
        id: "editorUI.validations.imageResolution.validationIcon.warning",
        defaultMessage: "Image resolution warning",
        description: {}
    },
    "imageResolution.validationIcon.error": {
        id: "editorUI.validations.imageResolution.validationIcon.error",
        defaultMessage: "Image resolution error",
        description: {}
    }
});
