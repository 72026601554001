import { type OpenContentPanelAfterItemSelectionCallbackProps } from "@internal/sim-framework";
import { dialogTypes } from "../dialogTypes";
import { PatternPanelMode, isPatternTypeReference } from "@pattern-sim/shared";
import { patternStore } from "@pattern-sim/extensions";

export function openContentPanelOnItemSelect({
    firstSelectedItem,
    designEngine
}: OpenContentPanelAfterItemSelectionCallbackProps) {
    if (isPatternTypeReference(firstSelectedItem)) {
        patternStore.setPatternPanelMode(PatternPanelMode.Edit);
        return dialogTypes.pattern;
    }
    /**
     * If there is a pattern within the panel we return dialogTypes
     *
     * isPatternTypeReference can be used??
     *
     * otherwise we would return undefined;
     */
    return undefined;
}
