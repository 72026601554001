import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useUploadManager } from "@design-stack-vista/upload-components";
import { AssetStoreEvent, ImageInfo } from "@design-stack-ct/assets-sdk";
import { VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { getScopedNewrelicWrapper } from "@internal/utils-newrelic";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { NR_SCOPE } from "../constants";
import type { AssetImageInfoEventDTO, FieldData, UploadErrorParamters } from "../types";

function getBooleanDataFields(
    fields: FieldData[],
    imageInfo?: ImageInfo,
    properties?: Record<string, any>,
    dataInfo?: ImageInfo
): Record<string, boolean> {
    const getBooleanData = ({ field, analysisField }: FieldData) => {
        if (imageInfo) {
            // @ts-ignore FIXME: must handle implicit `any` type
            return !!imageInfo[field];
        }
        if (properties && (properties[analysisField] as string) !== "False") {
            return !!properties[analysisField];
        }
        // @ts-ignore FIXME: must handle implicit `any` type
        if (dataInfo && dataInfo[field]) {
            return true;
        }
        return false;
    };

    const fieldValues = fields.reduce<Record<string, boolean>>((acc, currentData) => {
        acc[currentData.field] = getBooleanData(currentData);
        return acc;
    }, {});

    return fieldValues;
}

const getMimeType = (asset: VistaAsset | undefined, eventImageInfo?: AssetImageInfoEventDTO) =>
    asset?.data?.info?.storage?.contentType ||
    asset?.data?.info?.image?.format ||
    eventImageInfo?.imageInfo?.format ||
    "notAvailable";

function extractMetaData(asset?: VistaAsset, imageInfo?: ImageInfo) {
    try {
        if (!asset) {
            return {};
        }
        const { data } = asset;

        const timeToUpload = data?.totalUploadTimeMs;
        if (data?.info?.image?.format === "pdf" || data?.info?.image?.format === "unknown") {
            // Less data available for PDFs
            return {
                id: data?.id,
                fileType: getMimeType(asset),
                size: data?.info?.storage?.fileSizeBytes,
                clientInitiator: "studio",
                timeToUpload
            };
        }

        const fields = getBooleanDataFields(
            [
                { field: "isLogo", analysisField: "analysisIsLogo" },
                { field: "isPhoto", analysisField: "analysisIsPhoto" },
                { field: "isVector", analysisField: "analysisIsVector" }
            ],
            imageInfo,
            data?.properties,
            data?.info?.image
        );

        return {
            id: data?.id,
            fileType: getMimeType(asset),
            size: data?.info?.storage?.fileSizeBytes,
            ...fields,
            lineartness:
                (imageInfo && "lineartness" in imageInfo && imageInfo?.lineartness) ||
                (data?.properties?.analysisLineartness as string) ||
                // eslint-disable-next-line no-unsafe-optional-chaining -- data in response may not respect our type
                (data?.info?.image && "lineartness" in data?.info?.image && `${data?.info?.image.lineartness}`),
            clientInitiator: "studio",
            timeToUpload
        };
    } catch (e) {
        return {};
    }
}

export const onUploadErrorEvent = ({
    asset,
    error = "Unknown Error",
    source,
    eventImageInfo,
    trackingClient
}: UploadErrorParamters) => {
    // We're not using handleError so that we mimic how pre-UC studio5 worked and our dashboards don't break
    let assetUrl = "";
    try {
        if (asset) {
            assetUrl = asset.getUrl({ showDeleted: true });
        }
    } catch {
        /* empty */
    }

    const assetId = asset?.data?.id ?? eventImageInfo?.id;

    const errorMessage = error instanceof Error ? error.message : error;

    getScopedNewrelicWrapper(NR_SCOPE).addPageAction("studio-onUpload-failed", {
        fileType: getMimeType(asset, eventImageInfo),
        errorMessage,
        isDamAsset: asset?.isDamAsset(),
        fileName: asset?.data?.info?.storage?.fileName,
        assetId,
        assetName: asset?.data?.name,
        status: asset?.status?.type,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- error in asset response may not exist
        // @ts-ignore
        rawError: asset?.status?.rawError instanceof Error ? asset?.status?.rawError.message : asset?.status?.rawError,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- error in asset response may not exist
        // @ts-ignore
        assetError: asset?.data?.info?.image?.error,
        assetUrl,
        errorSource: source
    });
    trackingClient?.track(Events.StudioDiagnostic, {
        eventDetail: "Upload Failed: Overall",
        label: "Image",
        extraData: () => {
            return { ...extractMetaData(asset), errorMessage };
        }
    });
};

// Sherbert is pretty stingy about providing data so we'll track stuff locally here
// There is a disconnect between UploadSuccess (which just as an asset with no useful data yet)
// and the ImageInfo events which do add additional data
const successfulUploadedAssets: VistaAsset[] = [];

export const CustomUploadEvents = observer(() => {
    const { addEventListener, removeEventListener } = useUploadManager();
    const trackingClient = useTrackingClient();

    useEffect(() => {
        if (!addEventListener || !removeEventListener) {
            return () => {};
        }

        const logSuccessfulUpload = (event: CustomEvent<AssetImageInfoEventDTO>, asset: VistaAsset) => {
            const info = event.detail.imageInfo;
            trackingClient.track(Events.StudioDiagnostic, {
                eventDetail: "Upload Complete",
                label: "Image",
                extraData: () => {
                    return extractMetaData(asset, info);
                }
            });
        };

        const onUploadSuccessEvent = (e: Event) => {
            const event = e as CustomEvent<VistaAsset>;
            // On success the asset still has very little data.  The image info is fetched after.
            successfulUploadedAssets.push(event.detail);
        };

        const onUploadErrorWrapper = (e: Event) => {
            const event = e as CustomEvent<VistaAsset>;
            // the types are pretty useless here so see
            // https://gitlab.com/Cimpress-Technology/FileReview/sherbert/sdk/assets-sdk/-/blob/staging/packages/assets-sdk/src/store/AssetStore.ts#L244
            // for how this is generated
            const asset = event.detail as VistaAsset | undefined;
            // @ts-expect-error this exists if the status is in error but I'm not comfortable assuming anything from the assets-sdk, the types are not reliable
            const rawError = asset?.status?.rawError;
            onUploadErrorEvent({ asset, error: rawError, source: "onUploadErrorWrapper", trackingClient });
        };

        const onImageInfoServerSuccess = (e: Event) => {
            const event = e as CustomEvent<AssetImageInfoEventDTO>;
            if (event.detail?.id) {
                const uploadedAssetIndex = successfulUploadedAssets.findIndex(
                    asset => asset.data?.id === event.detail?.id
                );
                if (uploadedAssetIndex > -1) {
                    const uploadedAsset = successfulUploadedAssets[uploadedAssetIndex];
                    successfulUploadedAssets.splice(uploadedAssetIndex, 1);
                    if (event.detail.imageInfo?.format !== "unknown" && !event.detail.imageInfo?.isError) {
                        logSuccessfulUpload(event, uploadedAsset);
                    }
                    // don't log the error - it'll be logged if/when the user tries to use the asset
                }
            }
        };

        addEventListener(AssetStoreEvent.UploadSuccess, onUploadSuccessEvent);
        addEventListener(AssetStoreEvent.UploadError, onUploadErrorWrapper);
        addEventListener(AssetStoreEvent.ImageInfoServerSuccess, onImageInfoServerSuccess);

        return () => {
            removeEventListener(AssetStoreEvent.UploadSuccess, onUploadSuccessEvent);
            removeEventListener(AssetStoreEvent.UploadError, onUploadErrorWrapper);
            removeEventListener(AssetStoreEvent.ImageInfoServerSuccess, onImageInfoServerSuccess);
        };
    }, [addEventListener, removeEventListener, trackingClient]);

    return <></>;
});

CustomUploadEvents.displayName = "CustomUploadEvents";
