import { Panel } from "@design-stack-vista/cdif-types";

export async function getPanel(subpanelUrl: string): Promise<Panel> {
    const response = await fetch(subpanelUrl, {
        method: "GET",
        headers: {
            Accept: "application/json"
        }
    });

    return response.json();
}
