import { useEffect } from "react";
import type { UseInitializeStudioConfigurationManager } from "@internal/utils-studio-configuration-core";
import { useStudioConfigurationManager } from "./useStudioConfigurationManager";
import { DESIGN_EXPERIENCE_EXTENSIONS } from "./designExperienceExtensions";

/**
 * This hook is used to initialize the state of the of the Studio Configuration Manager.
 * @param config
 */
export const useInitializeStudioConfigurationManager: UseInitializeStudioConfigurationManager = config => {
    const studioConfigurationManager = useStudioConfigurationManager();

    const {
        decorationTechnology,
        productGroupConfigurationState,
        designExperienceManagementState,
        locale,
        isTeamsProduct,
        isDesignAssistantTabEnabled
    } = config ?? {};

    useEffect(() => {
        if (
            decorationTechnology !== undefined &&
            productGroupConfigurationState !== undefined &&
            designExperienceManagementState !== undefined &&
            locale !== undefined &&
            isTeamsProduct !== undefined &&
            isDesignAssistantTabEnabled !== undefined
        ) {
            studioConfigurationManager.init({
                decorationTechnology: decorationTechnology,
                productGroupConfigurationState: productGroupConfigurationState,
                designExperienceManagementState: designExperienceManagementState,
                locale: locale,
                isTeamsProduct: isTeamsProduct,
                studioDXExtensions: DESIGN_EXPERIENCE_EXTENSIONS,
                isDesignAssistantTabEnabled: isDesignAssistantTabEnabled
            });
        }
    }, [
        decorationTechnology,
        productGroupConfigurationState,
        designExperienceManagementState,
        locale,
        isTeamsProduct,
        studioConfigurationManager,
        isDesignAssistantTabEnabled
    ]);
};
