import React, { useCallback } from "react";
import type {
    CreateDragThumbnail,
    UseHandleDropTarget,
    UseHandleItemPlaceholderDropTarget
} from "@internal/sim-framework";
import { useDesignEngine } from "@design-stack-vista/core-features";
import { addImage } from "@design-stack-vista/cimdoc-state-manager";
import { replaceImageInCimDoc } from "./replaceImageInCimDoc";

export const PrototypeDraggableValue = Symbol("MarvelousDoodad");

export interface DraggedPrototype {
    id: string;
    url: string;
    width: number;
    height: number;
}

export const useHandleDropTarget: UseHandleDropTarget<DraggedPrototype> = () => {
    const designEngine = useDesignEngine();
    return useCallback(
        config => {
            const { dropped, panelId, position } = config;

            const { height, width, url } = dropped.item;

            const { commandOutput } = designEngine.executeCommand(addImage, {
                image: {
                    pageNumber: 1,
                    position: {
                        x: `${position.x}mm`,
                        y: `${position.y}mm`,
                        height: `${height}mm`,
                        width: `${width}mm`
                    },
                    previewUrl: url,
                    printUrl: url,
                    originalSourceUrl: url
                },
                panelId
            });

            designEngine.idaStore.setSelectedIds([commandOutput.id]);
        },
        [designEngine]
    );
};

export const useHandleItemPlaceholderDropTarget: UseHandleItemPlaceholderDropTarget<DraggedPrototype> = () => {
    const designEngine = useDesignEngine();
    return useCallback(
        config => {
            const { item, dropped } = config;
            const { url, id } = dropped.item;

            designEngine.executeCommand(replaceImageInCimDoc, {
                id: item.id,
                imageAttributes: {
                    printUrl: url,
                    previewUrl: url
                }
            });

            designEngine.idaStore.setSelectedIds([id]);
        },
        [designEngine]
    );
};

export const createDragThumbnail: CreateDragThumbnail<DraggedPrototype> = (item: DraggedPrototype) => {
    const { url, width, height } = item;
    return (
        <img
            style={{
                width,
                height,
                transform: `translate(-50%,-50%)`
            }}
            alt={""}
            src={url}
        />
    );
};
