import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useAbTestContext, ExperimentData } from "@internal/ab-test-framework";

type ContextData = {
    isQRCodeImageUploadABEnabled: boolean;
    isTestActive: boolean;
    trackImpression: () => void;
};

export const UploadQRABcontext = createContext<ContextData | undefined>(undefined);

enum Variations {
    Control = "control",
    Enabled = "test"
}

export const UploadQRABExperimentData: ExperimentData = {
    experimentKey: "qr_code_image_upload_v2",
    experimentName: "QR Code Image Upload",
    variations: Variations
};

export const UploadQRABProvider = ({ children }: React.PropsWithChildren) => {
    const { Provider } = UploadQRABcontext;
    const ABTest = useAbTestContext();
    const { experimentKey } = UploadQRABExperimentData;
    const [isEnabled, setIsEnabled] = useState(false);
    const [isTestActive, setIsTestActive] = useState(false);

    useEffect(() => {
        if (!experimentKey || !ABTest) {
            return;
        }
        const { isExperimentActive, isExperimentUsingVariation } = ABTest;
        isExperimentActive(experimentKey).then((active: boolean) => {
            setIsTestActive(active);
        });

        const checkEnabledVariation = async () => {
            const res = await isExperimentUsingVariation(experimentKey, Variations.Enabled);
            setIsEnabled(!!res);
        };
        checkEnabledVariation();
    }, [experimentKey, ABTest]);

    const trackImpression = useCallback(() => {
        if (!experimentKey || !ABTest) {
            return;
        }
        const { isExperimentUsingVariation, trackImpression: trackABImpression } = ABTest;
        const trackImpressionIfInVariant = async (variation: Variations) => {
            const res = await isExperimentUsingVariation(experimentKey, variation);
            if (res) {
                trackABImpression(experimentKey, variation);
            }
        };
        trackImpressionIfInVariant(Variations.Enabled);
        trackImpressionIfInVariant(Variations.Control);
    }, [ABTest, experimentKey]);

    const contextObject = useMemo(
        () => ({
            isQRCodeImageUploadABEnabled: isEnabled,
            isTestActive,
            trackImpression
        }),
        [isEnabled, isTestActive, trackImpression]
    );
    return <Provider value={contextObject}>{children}</Provider>;
};

UploadQRABProvider.displayName = "UploadQRABProvider";
