import React from "react";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { Box } from "@vp/swan";
import { DeletePattern } from "./DeletePattern";
import { useDesignEngine, useActivePanel, getOptionalExtension } from "@design-stack-vista/core-features";
import { PatternItemExtension } from "@pattern-sim/extensions";
import { observer } from "mobx-react-lite";
import styles from "./DesktopPatternDelete.module.scss";

export const DesktopPatternDelete = observer(() => {
    const designEngine = useDesignEngine();
    const { activePanel } = useActivePanel();
    const { isSmall } = useStudioLayout();

    const patternItemExtension = activePanel && getOptionalExtension(designEngine, activePanel, PatternItemExtension);

    return (
        <>
            {!isSmall && patternItemExtension?.patternItem && patternItemExtension?.patternItem.iid && (
                <Box className={styles.buttonContainer}>
                    <DeletePattern />
                </Box>
            )}
        </>
    );
});
