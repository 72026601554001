import {
    getWorkEntity,
    getWorkEntityFromSession,
    setWorkEntityInSession,
    type WorkEntity
} from "@internal/data-access-work-entity-service";
import { getScopedNewrelicWrapper } from "@internal/utils-newrelic";
import { getMPVId } from "@internal/data-access-product";
import { BaseTrackingClient, Events } from "@internal/utils-tracking";
import { transferResources } from "@internal/data-access-ownership-transfer";
import { buildHistoryUrl, getQueryParams, isCareAgent } from "@internal/utils-browser";
import { migrateAltDocIdToWork } from "@internal/data-access-document-gateway-service";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { InitializationQueryParamOverrides } from "../ProductLoadingProvider/ProductLoadingContext";

type Identity = ReturnType<typeof useIdentityContext>["identity"];

interface IncomingInitializationDataParams {
    queryParamOverrides?: InitializationQueryParamOverrides;
    workEntity?: WorkEntity;
    identity: Identity;
    auth: any;
    locale: string;
    fireGenericTrackingEvent: BaseTrackingClient["track"];
}

interface ResolvedProductData {
    workId: string;
    productKey?: string;
    productVersion?: number;
    mpvId?: string;
    customerSelectedProductOptions?: Record<string, string>;
    studioSelectedProductOptions?: Record<string, string>;
    quantity?: number;
    quantityPerSize?: string;
    documentUrl?: string;
    template?: string;
    isFullBleed: boolean;
    documentRevisionUrl?: string;
    workRevisionId?: string;
    workName?: string;
    workLastSaved?: string;
    owner?: string;
}

interface ResolvedInitializationData {
    resolvedProductData: ResolvedProductData;
    workEntity?: WorkEntity;
}

const scopedNewRelicWrapper = getScopedNewrelicWrapper("product-loading-provider-core");

/**
 * Uses Input and Query Parameters to resolve initializationd data (primarily product data) that would generally be used for a Calcifer initialization call
 * Will throw on error
 * @param incomingInitializationDataParams
 * @returns a promise that resolves to the resolved initialization data or undefined if some sort of redirect - such as for login - is required
 */
export async function resolveInitializationData(
    incomingInitializationDataParams: IncomingInitializationDataParams
): Promise<ResolvedInitializationData | undefined> {
    const { auth, identity, locale, fireGenericTrackingEvent } = incomingInitializationDataParams;

    let {
        key: productKey,
        mpvId,
        documentUrl,
        template,
        productVersion,
        documentRevisionUrl
    } = incomingInitializationDataParams.queryParamOverrides || getQueryParams();

    // we retrieve the work separately in case there is any extra metadata otherwise not stored in studio that we need to retrieve
    // the current use case is for the Design Assistant flow
    let { workId } = getQueryParams();

    const {
        // The customer selected options
        selectedOptions,
        qty,
        qtyPerSize,
        fullBleedElected,
        studioSelectedOptions,
        alt_doc_id: altDocId
    } = incomingInitializationDataParams.queryParamOverrides || getQueryParams();

    const { owner } = getQueryParams();
    const authToken = auth.getToken();

    let quantity = 0;
    let quantityPerSize: string | undefined = "0";
    switch (typeof qty) {
        case "object":
            quantity = Object.keys(qty).reduce((sum, size) => sum + qty[size], 0);
            quantityPerSize = JSON.stringify(qty);
            break;
        case "number":
            quantity = Number.isNaN(qty) ? 0 : Math.floor(qty);
            quantityPerSize = String(quantity);
            break;
        // this case we'll get from queryParamOverrides(redux). So we're sure about correct type and value
        case "string":
            quantity = Number(qty);
            quantityPerSize = qtyPerSize;
            break;
        default:
            break;
    }

    // fullBleedElected is a boolean from queryParamOverrides or a string from getQueryParams
    const isFullBleed = typeof fullBleedElected === "string" ? fullBleedElected === "true" : !!fullBleedElected;
    let studioSelectedProductOptions = studioSelectedOptions;
    let customerSelectedProductOptions = selectedOptions;

    let workEntity = incomingInitializationDataParams.workEntity;

    let workRevisionId;
    let workName;
    let workLastSaved;

    scopedNewRelicWrapper.addPageAction("studio-loading-initstate-start");

    // existing logic only tried to migrate the legacy document if we don't have any other way to load studio
    // unsure if that's necessary but keeping the logic just in case
    if (!workId && !productKey && !mpvId && !workEntity) {
        if (altDocId) {
            if (!identity.isSignedIn) {
                scopedNewRelicWrapper.addPageAction("studio-loading-forcelogin-altdocid");
                auth.signIn();
                return undefined;
            }
            workId = await migrateAltDocIdToWork(owner, authToken, locale, altDocId);
            /* istanbul ignore else --@preserve */ // had trouble testing the else but it just falls to the below if statement anyway
            if (workId) {
                scopedNewRelicWrapper.addPageAction("studio-loading-found-migrated-work");
                window.history.pushState("update-workId", "Title", buildHistoryUrl({ workId }));
                // monolith urls can contain the template parameter
                template = undefined;
            }
        }
    }

    // transfer resources before getting work entity
    // TODO: Also check here if the workId is not equal to the workEntity.workId?
    if (workId && !workEntity) {
        await transferResources(identity, auth.getToken());
        workEntity = getWorkEntityFromSession(workId);

        // check to make sure the current user owns the work, if not force them to log in
        const currentUserID = identity.isSignedIn
            ? identity.shopperId || identity.cimpressADFSUserId
            : identity.anonymousUserId;
        if (workEntity && workEntity.ownerId !== currentUserID && !isCareAgent()) {
            auth.signIn();
            return undefined;
        }

        const fireTrackingEvent = (data: {
            event?: string;
            eventDetail: string;
            label?: string;
            pageNameBase?: string | null;
            extraData?: any | null;
            timeSinceLoad?: number;
        }) => fireGenericTrackingEvent(Events.CareAgentLoadedWork, data);

        if (!workEntity) {
            workEntity = await getWorkEntity(auth.getToken(), identity, workId, fireTrackingEvent);
            setWorkEntityInSession(workEntity);
        }
    }

    // if we have queryParamOverrides then we're updating studio during the design session
    // we no longer want to treat the work as the source of truth, instead we trust our own data
    if (workEntity && !incomingInitializationDataParams.queryParamOverrides) {
        customerSelectedProductOptions = workEntity.merchandising.merchandisingSelections;
        try {
            studioSelectedProductOptions = JSON.parse(workEntity.design.metadata.studioSelectedProductOptions);
        } catch {
            // I guess in some cases these options aren't parseable, but they're not necessary and calcifer can resolve the full set, so whatevs
        }
        productKey =
            workEntity.product?.key || workEntity.design.metadata.productKey || workEntity.resources?.productKey;
        productVersion =
            workEntity.product?.version ||
            (typeof workEntity.design.metadata.productVersion === "string" &&
                parseInt(workEntity.design.metadata.productVersion)) ||
            undefined;
        quantity = workEntity.merchandising.quantity;
        quantityPerSize = workEntity.resources?.qty;
        documentUrl = workEntity.design.designUrl || workEntity.design.metadata.udsDocumentUrl;
        documentRevisionUrl = workEntity.design.designUrl;
        workRevisionId = workEntity.workRevisionId;
        workName = workEntity.workName;
        workLastSaved = workEntity.modified;

        // figure out mpvId so that it gets recorded in newRelic if Calcifer call fails
        if (workEntity.merchandising.mpvUrl) {
            mpvId = getMPVId(workEntity.merchandising.mpvUrl, mpvId);
        }
        scopedNewRelicWrapper.updateCustomAttributes({
            productKey,
            productVersion,
            selectedProductOptions: customerSelectedProductOptions,
            quantity,
            quantityPerSize,
            mpvId,
            workId
        });
    }

    // this handles loading documents either via work or via query parameter
    if (!documentRevisionUrl && documentUrl) {
        documentRevisionUrl = documentUrl;
    }

    // strip off revisions since we save to the document url
    if (documentUrl && documentUrl.includes("/revision")) {
        const ind = documentUrl.indexOf("/revision");
        documentUrl = documentUrl.substring(0, ind);
    }

    return {
        workEntity,
        resolvedProductData: {
            workId,
            productKey,
            productVersion,
            mpvId,
            customerSelectedProductOptions,
            studioSelectedProductOptions,
            quantity,
            quantityPerSize,
            documentUrl,
            template,
            isFullBleed,
            documentRevisionUrl,
            workRevisionId,
            workName,
            workLastSaved,
            owner
        }
    };
}
