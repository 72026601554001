import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { queryOptions } from "@tanstack/react-query";
import { getSecondaryConfigFromCalciferV2 } from "@internal/data-access-calcifer";
import type { DEXConfiguration } from "@internal/dex-framework";

export const useCreateSecondaryConfigQueryOptions: DEXConfiguration["useCreateSecondaryConfigQueryOptions"] = () => {
    const { productKey, locale, productVersion, template, isFullBleed, studioSelectedProductOptions } =
        useProductAndProjectStateManager().data;

    return queryOptions({
        queryKey: [
            "querySecondaryConfig",
            productKey,
            locale,
            productVersion,
            template,
            isFullBleed,
            studioSelectedProductOptions
        ],
        queryFn: () =>
            getSecondaryConfigFromCalciferV2(
                productKey,
                productVersion,
                studioSelectedProductOptions,
                locale,
                isFullBleed,
                template
            )
    });
};
