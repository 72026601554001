import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const currencySlice = createSlice({
    name: "currency",
    initialState: "",
    reducers: {
        setCurrency: (state, action: PayloadAction<string | null>) => action.payload || state
    }
});

export const { setCurrency } = currencySlice.actions;
export const currency = currencySlice.reducer;
