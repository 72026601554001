import { isShowWarningsMode } from "@internal/utils-browser";
import { setAlerts, setScenesConfiguration, setWarnings, useAppDispatch } from "@shared/redux";
import React, { useCallback } from "react";
import { setHasDesigns } from "@shared/redux/slices/hasDesigns";
import { batch } from "react-redux";
import { CalciferSceneConfiguration } from "@internal/data-access-calcifer";
import { ProductLoadingProvider } from "@internal/dex";

// Wraps the PLP to provide access to redux until dependencies are extracted
export function ProductLoadingProviderWrapper({ children }: React.PropsWithChildren) {
    const dispatch = useAppDispatch();

    const showWarnings = useCallback(
        (warnings: string[]) => {
            if (isShowWarningsMode() && warnings.length > 0) {
                dispatch(setWarnings(warnings));
            }
        },
        [dispatch]
    );

    const showAdjustmentAlert = useCallback(
        (changes: string) => {
            dispatch(
                setAlerts({
                    alerts: [
                        {
                            key: "studio.components.Toast.significantAdjustedDimensions",
                            includeCareLink: true,
                            careOnlyMessage: changes
                        }
                    ]
                })
            );
        },
        [dispatch]
    );

    const setRemainingDefaultState = useCallback(
        (scenesConfiguration: CalciferSceneConfiguration | undefined, hasDesigns: boolean) => {
            batch(() => {
                if (scenesConfiguration) {
                    dispatch(setScenesConfiguration(scenesConfiguration));
                }
                dispatch(setHasDesigns(hasDesigns));
            });
        },
        [dispatch]
    );

    return (
        <ProductLoadingProvider
            showWarnings={showWarnings}
            showAdjustmentAlert={showAdjustmentAlert}
            setRemainingDefaultState={setRemainingDefaultState}
        >
            {children}
        </ProductLoadingProvider>
    );
}
ProductLoadingProviderWrapper.displayName = "ProductLoadingProviderWrapper";
