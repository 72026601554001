import { defineMessages } from "@vp/i18n-helper";

export const uploadsAndAssetsMessages = defineMessages({
    uploadFailedGeneral: {
        id: "studio.addImage.uploadFailed",
        defaultMessage:
            "Your image was not uploaded because of an unknown error. Try again, or upload a different image.",
        description: {
            note: "Error shown when an upload fails unexpectedly"
        }
    }
});
