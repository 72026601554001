import type { Dimensions } from "@design-stack-vista/ida-framework";

export function getImageAsPromiseFromSvg(svgData: string, dimension: Dimensions): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        const parser = new DOMParser();
        const svgDocument = parser.parseFromString(svgData, "image/svg+xml");
        const svgElement = svgDocument.documentElement;
        const svgHeight = svgElement.getAttribute("height");
        const svgWidth = svgElement.getAttribute("width");
        let svg = svgData;
        if (!svgHeight && !svgWidth) {
            /*
             *** height and width attributes are required field for firefox to convert svg into image ***
             *** This is to handle following issue ***
             *** Reference: https://stackoverflow.com/questions/28690643/firefox-error-rendering-an-svg-image-to-html5-canvas-with-drawimage ***
             */
            svgElement.setAttribute("height", `${dimension.height}`);
            svgElement.setAttribute("width", `${dimension.width}`);
            svg = svgElement.outerHTML;
        }

        const url = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
        image.onload = () => resolve(image);
        image.onerror = () => reject(image);
        image.src = url;
        image.setAttribute("crossOrigin", "");
    });
}
