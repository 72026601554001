import { PanelTitle } from "@internal/ui-studio-chrome";
import React, { useEffect, useRef } from "react";
import { FlexBox, Range } from "@vp/swan";
import { useBackgroundPattern } from "@pattern-sim/hooks";
import { observer } from "mobx-react-lite";
import { useDesignEngine } from "@design-stack-vista/core-features";

import styles from "./Resize.module.scss";

import { useTranslationSSR } from "@vp/i18n-helper";
import { patternMessage } from "@pattern-sim/shared";
import { PatternExtension } from "@pattern-sim/extensions";
import { useActiveDialogSetters } from "@internal/utils-active-dialog";
import { dialogTypes } from "dialogTypes";

export const Resize = observer(() => {
    const designEngine = useDesignEngine();
    const { t } = useTranslationSSR();
    const { designExtensionSystem } = designEngine;
    const { editPattern, patternItem } = useBackgroundPattern();
    const patternItemExtension = patternItem && designExtensionSystem.getExtension(patternItem.iid, PatternExtension);
    const { setCurrentActiveDialog } = useActiveDialogSetters();

    const scaleRangeRef = useRef<HTMLInputElement>(null);
    const rotateRangeRef = useRef<HTMLInputElement>(null);
    const horizontalRangeRef = useRef<HTMLInputElement>(null);
    const verticalRangeRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!patternItem || !patternItemExtension) {
            setCurrentActiveDialog(dialogTypes.pattern);
        }
    }, [patternItem, patternItemExtension, setCurrentActiveDialog]);

    if (!patternItemExtension) {
        return null;
    }

    const onScaleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        patternItemExtension?.setDimensionRatio(value / 100);
        patternItemExtension?.setIsInEditingMode(true);
    };

    const onScaleSliderRelease = () => {
        if (!scaleRangeRef.current?.value) {
            return;
        }
        const value = parseInt(scaleRangeRef.current?.value);
        const ratioValue = value / 100;
        if (patternItemExtension?.patternToPanelDimensionRatio) {
            editPattern({ dimensionRatio: ratioValue });
            patternItemExtension?.setDimensionRatio(patternItemExtension?.patternToPanelDimensionRatio);
        }
        editPattern({ dimensionRatio: ratioValue });
        patternItemExtension?.setDimensionRatio(patternItemExtension?.patternToPanelDimensionRatio);
        patternItemExtension?.setIsInEditingMode(false);
    };

    const onRotationSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        patternItemExtension?.setRotationAngle(value);
        patternItemExtension?.setIsInEditingMode(true);
    };

    const onRotationSliderRelease = () => {
        if (!rotateRangeRef.current?.value) {
            return;
        }
        const value = parseInt(rotateRangeRef.current?.value);
        if (value !== patternItemExtension?.patternRotationAngle) {
            editPattern({ rotationAngle: value });
            patternItemExtension?.setRotationAngle(patternItemExtension.rotationAngle);
        }
        patternItemExtension?.setIsInEditingMode(false);
    };

    const onXOffsetSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        patternItemExtension?.setXOffsetRatio(value);
        patternItemExtension?.setIsInEditingMode(true);
    };

    const onXOffsetSliderRelease = () => {
        if (!horizontalRangeRef.current?.value) {
            return;
        }
        const value = parseInt(horizontalRangeRef.current?.value);
        const ratioValue = value / 100;
        editPattern({ xOffsetRatio: ratioValue });
        patternItemExtension?.setXOffsetRatio(100 - patternItemExtension.patternXOffsetPercentage);
        patternItemExtension?.setIsInEditingMode(false);
    };

    const onYOffsetSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        patternItemExtension?.setYOffsetRatio(value);
        patternItemExtension?.setIsInEditingMode(true);
    };

    const onYOffsetSliderRelease = () => {
        if (!verticalRangeRef.current?.value) {
            return;
        }
        const value = parseInt(verticalRangeRef.current?.value);
        const ratioValue = value / 100;
        editPattern({ yOffsetRatio: ratioValue });
        patternItemExtension?.setYOffsetRatio(100 - patternItemExtension.patternYOffsetPercentage);
        patternItemExtension?.setIsInEditingMode(false);
    };

    return (
        <FlexBox flexDirection="column" className={styles.container}>
            <PanelTitle className={styles.resizePanelTitle}>{t(patternMessage.resizePanelTitle.id)}</PanelTitle>
            <label htmlFor="scale" className={styles.rangeLabel}>
                {t(patternMessage.scaleLabel.id)}
            </label>
            <Range
                step={2}
                min={14}
                max={70}
                onChange={onScaleSliderChange}
                className={styles.rangeInput}
                ref={scaleRangeRef}
                onMouseUp={onScaleSliderRelease}
                onPointerUp={onScaleSliderRelease}
                id="scale"
                value={
                    patternItemExtension.isInEditingMode
                        ? patternItemExtension.dimensionRatio * 100
                        : patternItemExtension.patternToPanelDimensionRatio * 100
                }
            />
            <label htmlFor="rotation" className={styles.rangeLabel}>
                {t(patternMessage.rotationLabel.id)}
            </label>
            <Range
                max={360}
                min={0}
                step={1}
                className={styles.rangeInput}
                ref={rotateRangeRef}
                value={
                    patternItemExtension.isInEditingMode
                        ? patternItemExtension.rotationAngle
                        : patternItemExtension.patternRotationAngle
                }
                onChange={onRotationSliderChange}
                onMouseUp={onRotationSliderRelease}
                onPointerUp={onRotationSliderRelease}
            />
            <label htmlFor="horizontalPosition" className={styles.rangeLabel}>
                {t(patternMessage.horizontalPositionLabel.id)}
            </label>
            <Range
                max={100}
                min={0}
                step={1}
                className={styles.rangeInput}
                ref={horizontalRangeRef}
                value={
                    patternItemExtension.isInEditingMode
                        ? patternItemExtension.xOffsetRatio
                        : 100 - patternItemExtension.patternXOffsetPercentage
                }
                onChange={onXOffsetSliderChange}
                onMouseUp={onXOffsetSliderRelease}
                onPointerUp={onXOffsetSliderRelease}
            />
            <label htmlFor="verticalPosition" className={styles.rangeLabel}>
                {t(patternMessage.verticalPositionLabel.id)}
            </label>
            <Range
                max={100}
                min={0}
                step={1}
                className={styles.rangeInput}
                ref={verticalRangeRef}
                value={
                    patternItemExtension.isInEditingMode
                        ? patternItemExtension.yOffsetRatio
                        : 100 - patternItemExtension.patternYOffsetPercentage
                }
                onChange={onYOffsetSliderChange}
                onMouseUp={onYOffsetSliderRelease}
                onPointerUp={onYOffsetSliderRelease}
            />
        </FlexBox>
    );
});
