import React from "react";
import {
    TextInput,
    FormError,
    FormField,
    FormInputGroup,
    TextInputWithFloatingLabel,
    TextInputFloatingLabel
} from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { qrCodeMessages as messages } from "../../../shared/QRCodeMessages";
import { RenderWhen } from "../../../shared/components";

type Props = {
    onUrlChange: React.ChangeEventHandler<HTMLInputElement>;
    url: string;
    isUrlInvalid: boolean;
    potentiallyWrongUrl: boolean;
    autoFocusInput?: boolean;
    isPreviewLoading: boolean;
};

export const QRCodeURLInput = (props: Props) => {
    const { onUrlChange, url, autoFocusInput, isUrlInvalid, potentiallyWrongUrl } = props;
    const { t } = useTranslationSSR();

    return (
        <FormField>
            <FormInputGroup marginBottom={potentiallyWrongUrl ? "6" : "7"}>
                <TextInputWithFloatingLabel>
                    <TextInput
                        placeholder={t(messages.url.id)}
                        value={url}
                        onChange={onUrlChange}
                        // eslint-disable-next-line jsx-a11y/no-autofocus -- autoFocus is passed as a prop
                        autoFocus={autoFocusInput}
                    />
                    <TextInputFloatingLabel>{t(messages.example.id)}</TextInputFloatingLabel>
                    <RenderWhen condition={isUrlInvalid}>
                        <FormError ml={"4"}>{t(messages.invalidURL.id)}</FormError>
                    </RenderWhen>
                    <RenderWhen condition={!isUrlInvalid && potentiallyWrongUrl}>
                        <FormError ml={"4"}>{t(messages.wrongURL.id)}</FormError>
                    </RenderWhen>
                </TextInputWithFloatingLabel>
            </FormInputGroup>
        </FormField>
    );
};

QRCodeURLInput.displayName = "QRCodeUrlInput";
