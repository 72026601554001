import { type ExtendedSurfaceUpsell, useAppSelector } from "@shared/redux";
import { useCallback } from "react";
import { useShowPanelSections } from "@shared/features/PanelSections";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { StudioPanelDesignRequirements } from "../DesignRequirementsProvider";

export enum PrintColorProductOptions {
    insideOnly = "0/4 - 0/CMYK",
    outsideOnly = "4/0 - CMYK"
}

// handle packaging products using a BoxUp sku with two color surfaces where only one should be designable
function isNonDesignableFromProductOptions(
    panelIndex: number,
    productOptions: Record<string, string>,
    showPanelSections: boolean
) {
    // only apply to products that have panel sections enabled
    if (!showPanelSections) {
        return false;
    }

    // if insideOnly is selected, only the second panel is designable
    if (productOptions.PrintColor === PrintColorProductOptions.insideOnly) {
        return panelIndex !== 1;
    }
    // if outsideOnly is selected, only the first panel is designable
    if (productOptions.PrintColor === PrintColorProductOptions.outsideOnly) {
        return panelIndex !== 0;
    }
    return false;
}

export function isBlankAndHasNoUpsells(
    panel: StudioPanelDesignRequirements,
    panelIndex: number,
    surfaceUpsells: Record<string, ExtendedSurfaceUpsell>,
    productOptions: Record<string, string>,
    showPanelSections: boolean
) {
    const isCanvasBlank = panel.colorMode === "blank";
    return (
        (isCanvasBlank && !surfaceUpsells[panel.name]) ||
        isNonDesignableFromProductOptions(panelIndex, productOptions, showPanelSections)
    );
}

export function useIsBlankAndHasNoUpsells() {
    const surfaceUpsells = useAppSelector(state => state.surfaceUpsells);
    const { studioSelectedProductOptions } = useProductAndProjectStateManager().data;
    const showPanelSections = useShowPanelSections();

    return useCallback(
        (panel: StudioPanelDesignRequirements, panelIndex: number) => {
            return isBlankAndHasNoUpsells(
                panel,
                panelIndex,
                surfaceUpsells,
                studioSelectedProductOptions,
                showPanelSections
            );
        },
        [studioSelectedProductOptions, surfaceUpsells, showPanelSections]
    );
}
