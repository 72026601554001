import { FullCroppingValues } from "@design-stack-vista/core-features";
import { ImageResolutionPayload } from "@design-stack-vista/vista-validations";
import { VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import type { ImageResolutionValidatorItemConfig } from "@internal/utils-assets";
import type { StudioMetadata } from "@internal/utils-custom-metadata";
import { validationsMessages } from "../../validationsMessages";
import { ImageResolutionStatus } from "../types";

/**
 * getImageResolutionStatus returns status based on this logic \
 * ERROR <= errorTreshold < WARNING <= warningThreshold < OK
 */
export function getImageResolutionStatus(ppi: number, config: ImageResolutionValidatorItemConfig) {
    const isPpiAboveWarningTreshold = ppi > config.minimumPpi * config.warningThreshold;
    if (isPpiAboveWarningTreshold) {
        return ImageResolutionStatus.OK;
    }
    const isPpiEqOrBelowErrorTreshold = ppi <= config.minimumPpi * config.errorThreshold;
    return isPpiEqOrBelowErrorTreshold ? ImageResolutionStatus.ERROR : ImageResolutionStatus.WARNING;
}

export function getIconAdditionalData(
    payload: ImageResolutionPayload,
    config: ImageResolutionValidatorItemConfig,
    t: any,
    localizationKey: string
) {
    const imageResolutionStatus = getImageResolutionStatus(payload.ppi, config);
    // because this is used in warning/error icon we can ignore possible status OK
    const isError = imageResolutionStatus === ImageResolutionStatus.ERROR;
    const translationSuffix = isError ? "error" : "warning";
    // @ts-ignore FIXME: must handle implicit `any` type
    const label = t(validationsMessages[`${localizationKey}.${translationSuffix}`].id);
    // @ts-ignore FIXME: must handle implicit `any` type
    const iconLabel = t(validationsMessages[`${localizationKey}.validationIcon.${translationSuffix}`].id);

    return { label, isError, iconLabel };
}

export function getItemPixelDimensions(
    asset: VistaAsset | undefined,
    cropping: FullCroppingValues | undefined,
    isSharpened: boolean,
    studioMetadata?: StudioMetadata
) {
    if (!asset?.data?.info.image) return null;
    const imageData = asset.data.info.image;

    const specialCases = ["pdf", "unknown", "svg", "ai", "eps"];
    const isSpecialCase = ("isVector" in imageData && imageData.isVector) || specialCases.includes(imageData.format);

    if (isSpecialCase) {
        // Special case
        return null;
    }

    if (!("width" in imageData && "height" in imageData)) {
        return null;
    }

    let { width, height } = imageData;

    if (isSharpened) {
        if (
            studioMetadata?.sharpenedDimensions &&
            studioMetadata.sharpenedDimensions.width &&
            studioMetadata.sharpenedDimensions.height
        ) {
            width = studioMetadata.sharpenedDimensions.width ?? width;
            height = studioMetadata.sharpenedDimensions.height ?? height;
        } else {
            // if no dimensions available then we just use what cimpress designer does
            // once studio6 is widely used then this case becomes less likely
            // for now, it means that studio6 uses the same logic as studio5 for existing uploads
            if (width) width *= 4;
            if (height) height *= 4;
        }
    }

    const isCropped = !!cropping?.crop && Object.values(cropping?.crop).some(value => value !== 0);
    if (isCropped && width && height) {
        const { top, bottom, left, right } = cropping.crop;
        width *= 1 - (left + right);
        height *= 1 - (top + bottom);
    }
    return { width, height };
}
