import React from "react";
import type { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";
import { useCutlineConfiguration } from "@internal/feature-cutline-generation";
import { useActivePanel } from "@design-stack-vista/core-features";
import { useTranslationSSR } from "@vp/i18n-helper";
import { cutlineSimMessages } from "../shared/cutlineSimMessages";
import { CutlineIcon } from "./components/CutlinePanel/CutlineIcons";
import { messages } from "./components/CutlinePanel/CutlineGenerationPanel";

export const CutlineContentDiscoveryZoneButton: ContentDiscoveryZoneButton = ({ screenSize, children }) => {
    const { setCutlineMessages } = useCutlineConfiguration();
    const { activePanel } = useActivePanel();
    const currentPanelHasImageItems = Boolean(activePanel?.items.find(item => item.type === "image"));
    const { t } = useTranslationSSR();

    setCutlineMessages(cutlineSimMessages);
    if (screenSize === "largeScreen") {
        return children({
            icon: <CutlineIcon aria-hidden="true" />,
            label: t(messages.panelTitle.id),
            enabled: currentPanelHasImageItems
        });
    }
    return children({
        icon: <CutlineIcon aria-hidden="true" />,
        label: t(messages.panelTitle.id),
        enabled: currentPanelHasImageItems
    });
};
