import { Panel } from "@design-stack-vista/cdif-types";

export const getPanelColors = (panel: Panel): string[] => {
    const shapeColors = [] as string[];
    panel.shapes?.forEach((shape: any) => {
        if (shape.color) {
            shapeColors.push(shape.color);
        }
        if (shape.stroke?.color) {
            shapeColors.push(shape.stroke?.color);
        }
    });

    return [...new Set(shapeColors)];
};
