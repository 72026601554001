import {
    ItemPosition,
    PanelLayoutExtension,
    useDesignEngine,
    useActivePanel,
    useOptionalExtension,
    useRequiredExtension,
    applyZoom
} from "@design-stack-vista/core-features";
import { ItemPreview } from "@design-stack-vista/ida-framework";
import { observer } from "mobx-react-lite";
import React, { Fragment, useRef, useState } from "react";

import { getCanvasDimensionAndPosition } from "../../shared/PositionAndDimensionCalculations";
import { PatternItemPreview } from "./PatternItemPreview";
import { PatternExtension } from "@pattern-sim/extensions";
import { getLayoutDimensions, getItemStatePreviewUrl } from "@pattern-sim/shared";
import type { CustomItemReferencePreviewComponent } from "@internal/sim-framework";
import type { PatternItemReferenceData } from "shared/PatternItemReferenceTypes";
import { toMmValues } from "@design-stack-vista/utility-core";

const BUILD_ITEM_PREVIEW_URL_TENANT = "vistaprint";

export const PatternItemPreviewWrapper: CustomItemReferencePreviewComponent<PatternItemReferenceData> = observer(
    ({ item }) => {
        const designEngine = useDesignEngine();
        const { activePanel } = useActivePanel();
        const [imagePreviewLoaded, setImagePreviewLoaded] = useState(false);

        const patternItemExtension = useOptionalExtension(item, PatternExtension);
        const panelPreviewBox = useRequiredExtension(item.parent, PanelLayoutExtension);

        const oldPreviewUrl = useRef("");

        // Building an item preview url using a transient document with the current model
        const previewUrl = getItemStatePreviewUrl({
            itemState: item,
            tenant: BUILD_ITEM_PREVIEW_URL_TENANT,
            cimDocProperties: designEngine.cimDocStore.cimDocProperties,
            zoom: designEngine.layoutStore.zoom
        });

        if (previewUrl && oldPreviewUrl.current !== previewUrl) {
            oldPreviewUrl.current = previewUrl;
            setImagePreviewLoaded(false);
        }

        if (!patternItemExtension) {
            return null;
        }

        const patternFileUrl = patternItemExtension?.fileUrl;

        // Normally, we would use the bounding box returned from Fusion for this, but this item is not rendered with Fusion so we must use the position directly
        const position = applyZoom(toMmValues(item.model.position), designEngine.layoutStore.zoom);
        const getPatternDimension = () => {
            if (patternItemExtension) {
                return patternItemExtension.isInEditingMode && activePanel
                    ? getCanvasDimensionAndPosition(
                          panelPreviewBox.dimensions.width,
                          panelPreviewBox.dimensions.height,
                          patternItemExtension.dimensionRatio,
                          patternItemExtension.xOffsetRatio,
                          patternItemExtension.yOffsetRatio,
                          1
                      )
                    : {
                          canvasWidth: position.width,
                          canvasHeight: position.height,
                          patternTileDimension: getLayoutDimensions(designEngine, patternItemExtension.tileDimensions),
                          canvasPositionX: position.x,
                          canvasPositionY: position.y
                      };
            }
            return {
                canvasWidth: 0,
                canvasHeight: 0,
                patternTileDimension: { width: 0, height: 0 },
                canvasPositionX: 0,
                canvasPositionY: 0
            };
        };

        const { canvasWidth, canvasHeight, patternTileDimension, canvasPositionX, canvasPositionY } =
            getPatternDimension();

        const showRenderingPreview = () => {
            patternItemExtension?.setIsInEditingMode(false);
        };

        const onImagePreviewLoad = () => {
            setImagePreviewLoaded(true);
        };
        const showPatternClientSidePreview =
            patternItemExtension?.isInEditingMode ||
            (!patternItemExtension?.isInEditingMode && !imagePreviewLoaded) ||
            !!previewUrl;

        return patternItemExtension ? (
            <Fragment>
                <div
                    style={{
                        transform: `translate(${canvasPositionX}px, ${canvasPositionY}px) rotate(${patternItemExtension.rotationAngle}deg)`,
                        width: canvasWidth,
                        height: canvasHeight,
                        zIndex: -999,
                        display: showPatternClientSidePreview ? "block" : "none"
                    }}
                >
                    <PatternItemPreview
                        show={!!showPatternClientSidePreview}
                        height={canvasHeight}
                        width={canvasWidth}
                        patternTileWidth={patternTileDimension.width}
                        patternTileHeight={patternTileDimension.height}
                        isUrlSvg={patternItemExtension.isUrlSvg}
                        svgData={patternItemExtension?.svgData}
                        patternFileUrl={patternFileUrl}
                        fallbackToRenderingPreview={showRenderingPreview}
                        repeatPattern={true}
                    />
                </div>
                <ItemPosition item={item}>
                    <ItemPreview
                        src={previewUrl}
                        onImageLoad={onImagePreviewLoad}
                        show={!showPatternClientSidePreview}
                    />
                </ItemPosition>
            </Fragment>
        ) : null;
    }
);
