import { INewrelicError, SimpleType } from "./types";

type AttributedError = Error & Record<string, SimpleType>;

type ShortNetworkParameters = {
    url?: string;
};

type ExpandedNetworkParameters = {
    hostname?: string;
    path?: string;
    search?: string;
};

export type NewrelicNetworkErrorParameters = (ShortNetworkParameters | ExpandedNetworkParameters) & {
    status?: number;
};

export type NewrelicErrorParameters = NewrelicNetworkErrorParameters & {
    code: number | string;
    priority?: "critical" | "high" | "medium" | "low";
    error?: Error;
} & Record<string, unknown>;

export class NewrelicError extends Error implements INewrelicError {
    code: number | string;

    scope: string = "studio";

    status: SimpleType;

    hostname?: string;

    path?: string;

    search?: string;

    url?: string;

    priority: "critical" | "high" | "medium" | "low";

    extra: Record<string, unknown>;

    constructor(message: string, params: NewrelicErrorParameters) {
        super(message);

        const {
            code,
            error,
            status,
            priority = "medium",
            search: searchParam,
            hostname: hostnameParam,
            path: pathParam,
            url: urlParam,
            ...rest
        } = params;

        const typedError = params.error instanceof Error ? (params.error as AttributedError) : undefined;

        let extra = rest ?? {};
        let url = urlParam ? new URL(urlParam as string) : undefined;

        if (typedError) {
            const { message, stack, name, ...restError } = typedError;
            this.message = `${message}: ${message}`;
            this.stack = stack;
            this.name = name;

            extra = {
                ...extra,
                ...restError
            };

            if (!url && typeof typedError.url === "string") {
                url = new URL(typedError.url);
            }
        }

        this.code = code;
        this.status = status ?? typedError?.status;
        this.hostname = url?.hostname ?? (hostnameParam as string);
        this.path = url?.pathname ?? (pathParam as string);
        this.search = url?.search ?? (searchParam as string);
        this.priority = priority;
        this.extra = extra;
    }

    toJSON() {
        return {
            message: this.message,
            name: this.name,
            code: this.code,
            scope: this.scope,
            status: this.status,
            hostname: this.hostname,
            path: this.path,
            search: this.search,
            priority: this.priority,
            stack: this.stack,
            extra: this.extra
        };
    }

    toString() {
        const details = `(\
code: ${this.code}, \
status: ${this.status}, \
hostname: ${this.hostname}, \
path: ${this.path}, \
search: ${this.search}, \
priority: ${this.priority}, \
extra: ${JSON.stringify(this.extra)}\
)`;

        return `[${this.scope}] ${super.toString()} ${details}`;
    }
}
