import { ItemReference } from "@design-stack-vista/cdif-types";
import { ItemState, SubpanelState } from "@design-stack-vista/cimdoc-state-manager";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
import { TeamsNamePlaceholderType } from "../@types";

export interface TeamsNameItemReference {
    focus: string;
    fontFamily: string;
    fontStyle: string;
    content: string;
    placeholderKey: string;
    placeholderType: TeamsNamePlaceholderType;
    color: string;
    curve: {
        radius: number;
        angle: number;
        height: number;
    };
}

export const isTeamsNameItemReference = (
    item: ItemState<any> | SubpanelState
): item is ItemState<ItemReference<TeamsNameItemReference>> => {
    return item.isItemReference() && item.model.type === ItemReferenceTypes.TEAMS_NAME;
};
