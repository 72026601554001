import { UnitlessDimensions, getAspectRatio } from "@design-stack-vista/utility-core";

export const calculateTileDimension = (patternDimension: number, image: UnitlessDimensions) => {
    const { width, height } = image;
    const imageAspectRatio = getAspectRatio({ width, height });

    let imageHeight = 0;
    let imageWidth = 0;
    // don't scale image, if it is smaller than pattern dimension
    if (height > width) {
        imageHeight = Math.min(patternDimension, height);
    } else {
        imageWidth = Math.min(patternDimension, width);
    }

    // calc tile dimension maintaining aspect ratio
    const tileDimension = imageHeight
        ? {
              width: imageHeight * imageAspectRatio,
              height: imageHeight
          }
        : {
              width: imageWidth,
              height: imageWidth / imageAspectRatio
          };

    return {
        width: tileDimension.width,
        height: tileDimension.height,
        x: tileDimension.width,
        y: tileDimension.height
    };
};
