import React, { useCallback, useMemo, ChangeEvent } from "react";
import { withPrefix } from "gatsby";
import { observer } from "mobx-react-lite";
import type { SelectedValues } from "@vp/swan";
import {
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
    SelectionSetTileImageContainer,
    FluidImage,
    SelectionSetTileContents,
    SelectionSetTileName,
    SelectionSetTilePrice
} from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { useAddTeamsNameItem } from "../../hooks";
import { placeholderButtonsMessages } from "./messages";
import styles from "./TeamsNamePlaceholderButtons.module.scss";
import { TeamsNamePlaceholderType } from "../../@types";

interface PlaceholderButtonConfig {
    title: string;
    details: string;
    imageAlt: string;
    imageSrc: string;
    placeholderKey: string;
    placeholderContent: string;
    placeholderType: TeamsNamePlaceholderType;
    trackingEvent: string;
}

export const TeamsNamePlaceholderButtons = observer(() => {
    const { t } = useTranslationSSR();
    const addItem = useAddTeamsNameItem();
    const trackingClient = useTrackingClient();

    const placeholderButtonsConfig = useMemo<PlaceholderButtonConfig[]>(
        () => [
            {
                title: t(placeholderButtonsMessages.addNameButtonTitle.id),
                details: t(placeholderButtonsMessages.addNameButtonDetails.id),
                imageAlt: t(placeholderButtonsMessages.addNameButtonImageAlt.id),
                imageSrc: ASSET_PREFIX + withPrefix("/teamsName/teamsName.png"),
                placeholderKey: t(placeholderButtonsMessages.addNamePlaceholder.id),
                placeholderContent: t(placeholderButtonsMessages.addNamePlaceholder.id),
                placeholderType: "Name",
                trackingEvent: "click:teamsNamePlaceholder"
            },
            {
                title: t(placeholderButtonsMessages.addRoleButtonTitle.id),
                details: t(placeholderButtonsMessages.addRoleButtonDetails.id),
                imageAlt: t(placeholderButtonsMessages.addRoleButtonImageAlt.id),
                imageSrc: ASSET_PREFIX + withPrefix("/teamsName/teamsRole.png"),
                placeholderKey: t(placeholderButtonsMessages.addRolePlaceholder.id),
                placeholderContent: t(placeholderButtonsMessages.addRolePlaceholder.id),
                placeholderType: "Role",
                trackingEvent: "click:teamsRolePlaceholder"
            },
            {
                title: t(placeholderButtonsMessages.addNumberButtonTitle.id),
                details: t(placeholderButtonsMessages.addNumberButtonDetails.id),
                imageAlt: t(placeholderButtonsMessages.addNumberButtonImageAlt.id),
                imageSrc: ASSET_PREFIX + withPrefix("/teamsName/teamsNumber.png"),
                placeholderKey: t(placeholderButtonsMessages.addNumberPlaceholder.id),
                placeholderContent: "00",
                placeholderType: "Number",
                trackingEvent: "click:teamsNumberPlaceholder"
            },
            {
                title: t(placeholderButtonsMessages.addTextPlaceholderButtonTitle.id),
                details: t(placeholderButtonsMessages.addTextPlaceholderButtonDetails.id),
                imageAlt: t(placeholderButtonsMessages.addTextPlaceholderButtonImageAlt.id),
                imageSrc: ASSET_PREFIX + withPrefix("/teamsName/teamsTextPlaceholder.png"),
                placeholderKey: t(placeholderButtonsMessages.addTextPlaceholder.id),
                placeholderContent: t(placeholderButtonsMessages.addTextPlaceholder.id),
                placeholderType: "Text",
                trackingEvent: "click:teamsTextPlaceholder"
            }
        ],
        [t]
    );

    const selectedValues = useMemo(
        () =>
            placeholderButtonsConfig.reduce(
                (values, { placeholderType }) => ({
                    ...values,
                    [placeholderType]: false
                }),
                {}
            ),
        [placeholderButtonsConfig]
    );

    const handleSelectedValueChange = useCallback(
        (selectedValues: SelectedValues, event: ChangeEvent<HTMLInputElement>) => {
            // We need to remove the focus from the SelectionSetInput
            // so that the user can interact with the element on the canvas using the keyboard
            event.target.blur();

            const placeholderConfig = placeholderButtonsConfig.find(
                ({ placeholderType }) => selectedValues[placeholderType]
            );
            if (!placeholderConfig) {
                return;
            }

            const { placeholderKey, placeholderContent, placeholderType, trackingEvent } = placeholderConfig;
            addItem({ placeholderKey, placeholderContent, placeholderType });
            trackingClient.track(Events.DesignToolUsed, { eventDetail: trackingEvent });
        },
        [placeholderButtonsConfig, addItem, trackingClient]
    );

    return (
        <SelectionSet
            mt={"5"}
            mb={"2"}
            skin="tiles-mini"
            variant="multi-select"
            selectedValues={selectedValues}
            onSelectedValuesChange={handleSelectedValueChange}
        >
            {placeholderButtonsConfig.map(({ placeholderType, placeholderKey, title, details, imageAlt, imageSrc }) => (
                <SelectionSetInput key={placeholderType} value={placeholderType}>
                    <SelectionSetLabel className={styles.label} mr={0} aria-label={placeholderKey}>
                        <SelectionSetTileImageContainer className={styles.imageContainer}>
                            <FluidImage src={imageSrc} alt={imageAlt} />
                        </SelectionSetTileImageContainer>
                        <SelectionSetTileContents compactMode>
                            <SelectionSetTileName>{title}</SelectionSetTileName>
                            <SelectionSetTilePrice fontSkin="body-small">{details}</SelectionSetTilePrice>
                        </SelectionSetTileContents>
                    </SelectionSetLabel>
                </SelectionSetInput>
            ))}
        </SelectionSet>
    );
});

TeamsNamePlaceholderButtons.displayName = "TeamsNamePlaceholderButtons";
