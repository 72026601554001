import React from "react";
import { Button, FlexBox, Typography, Box, SkeletonText, SkeletonTextLine, SkeletonTextHeading } from "@vp/swan";
import { Status } from "./Status";
import { SideScroller } from "@internal/ui-studio-chrome";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ElementsRowArrowButton } from "./ElementsRowArrowButton";
import styles from "./ElementsRow.module.scss";

const skeletonLetterWidth = 7;

const messages = defineMessages({
    scrollRight: {
        id: "studio.features.elements.scrollRight",
        defaultMessage: "Scroll right",
        description: {
            note: "Aria label for button to scroll right on row of elements"
        }
    },
    scrollLeft: {
        id: "studio.features.elements.scrollLeft",
        defaultMessage: "Scroll left",
        description: {
            note: "Aria label for button to scroll left on row of elements"
        }
    },
    noResults: {
        id: "studio.features.elements.noResults",
        defaultMessage: `Sorry, we couldn't find any matches for <bold>"[[term]]"</bold>.`,
        description: {
            note: "Message shown when no elements are found that match search term"
        }
    },
    noResultsDescription: {
        id: "studio.features.elements.noResultsDescription",
        defaultMessage: `Please check your spelling or try searching with a different word.`,
        description: {
            note: "Description shown when no elements are found that match search term"
        }
    },
    showMore: {
        id: "studio.features.elements.showMore",
        defaultMessage: "Show more",
        description: {
            note: "Text that will show on a button to show more elements"
        }
    },
    error: {
        id: "studio.features.elements.error",
        defaultMessage: `We couldn't load these elements - please try again.`,
        description: {
            note: "Message shown when there is an error loading elements"
        }
    }
});

type ElementsRowProps = {
    status: Status;
    viewAllAriaLabel?: string;
    rowTitle: string;
    showViewAll: boolean;
    dataTestId?: string;
    handleViewAllClick: () => void;
};

export const ElementsRow = (props: React.PropsWithChildren<ElementsRowProps>) => {
    const { t } = useTranslationSSR();
    const { status, viewAllAriaLabel, rowTitle, children, showViewAll, handleViewAllClick, dataTestId } = props;

    // don't show the row if there are no search results for this element type
    if (status === Status.Idle && !children) {
        return null;
    }

    return (
        <Box mb={{ md: "5", lg: "5" }} data-testid={dataTestId}>
            <FlexBox justifyContent="space-between" alignItems="center" mb={"4"} className={styles.elementsTitle}>
                <Typography fontWeight="bold" fontSize="standard" className={styles.elementsRowTitle}>
                    {rowTitle}
                </Typography>
                {status !== Status.Error && showViewAll && (
                    <Button
                        skin="link"
                        className={styles.elementsRowButton}
                        onClick={handleViewAllClick}
                        aria-label={viewAllAriaLabel}
                        disabled={status === Status.Loading}
                    >
                        <>
                            {status === Status.Loading && (
                                <SkeletonText style={{ width: t(messages.showMore.id).length * skeletonLetterWidth }}>
                                    <SkeletonTextLine />
                                </SkeletonText>
                            )}
                            {status !== Status.Loading && t(messages.showMore.id)}
                        </>
                    </Button>
                )}
            </FlexBox>
            <SideScroller
                className={styles.elementsRowItems}
                ariaLabelScrollLeft={t(messages.scrollLeft.id)}
                ariaLabelScrollRight={t(messages.scrollRight.id)}
                dataTestId="pattern-side-scroller"
            >
                {status === Status.Idle && children}
                {status === Status.Idle && showViewAll && (
                    <ElementsRowArrowButton onClick={handleViewAllClick} aria-label={viewAllAriaLabel} />
                )}
                {status === Status.Loading && (
                    <SkeletonText>
                        <SkeletonTextHeading />
                    </SkeletonText>
                )}
                {status === Status.Error && (
                    <Typography textAlign="center" fontSize="small" paddingTop={"4"} paddingX={"7"}>
                        {t(messages.error.id)}
                    </Typography>
                )}
            </SideScroller>
        </Box>
    );
};

ElementsRow.displayName = "ElementsRow";
