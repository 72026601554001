import { InteractiveDesignEngine, ItemProcessingExtension } from "@design-stack-vista/interactive-design-engine-core";
import { IReactionDisposer, reaction } from "mobx";
import { getOptionalExtension } from "@design-stack-vista/core-features";
import { ImageInstantUploadExtension } from "../UploadsAndAssets/ImageInstantUploadExtension";

const WAIT_FOR_PROCESSING_TIMEOUT = 30000;

export class ProcessingTimeoutError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "ProcessingTimeoutError";
    }
}

export enum ProcessingType {
    Item = "ITEM",
    Upload = "UPLOAD"
}

export const ALL_PROCESSING_TYPES = Object.values(ProcessingType);

export function isEngineProcessing(
    designEngine: InteractiveDesignEngine,
    types: ProcessingType[] = [...ALL_PROCESSING_TYPES]
): ProcessingType | false {
    for (const panel of designEngine.cimDocStore.panels) {
        for (const item of panel.items) {
            if (
                types.includes(ProcessingType.Item) &&
                getOptionalExtension(designEngine, item, ItemProcessingExtension)?.isProcessing
            ) {
                return ProcessingType.Item;
            }

            if (
                types.includes(ProcessingType.Upload) &&
                getOptionalExtension(designEngine, item, ImageInstantUploadExtension)?.isUploading
            ) {
                return ProcessingType.Upload;
            }
        }
    }

    return false;
}

/**
 * waits for the specified type of processing to complete
 * @param designEngine
 * @param types
 * @param msUntilTimeout
 * @returns time taken for processing in ms
 */
export function waitForEngineProcessingCompletion(
    designEngine: InteractiveDesignEngine,
    types: ProcessingType[] = [...ALL_PROCESSING_TYPES],
    msUntilTimeout: number = WAIT_FOR_PROCESSING_TIMEOUT
): Promise<number> {
    return new Promise((resolve, reject) => {
        let processingReactionDisposer: IReactionDisposer;
        let processingTimeout: number;
        const startTime = performance.now();

        const cleanup = () => {
            if (processingReactionDisposer) {
                processingReactionDisposer();
            }

            if (processingTimeout) {
                window.clearTimeout(processingTimeout);
            }
        };

        processingReactionDisposer = reaction(
            () => isEngineProcessing(designEngine, types),
            processing => {
                if (!processing) {
                    cleanup();
                    resolve(performance.now() - startTime);
                }
            }
        );

        processingTimeout = window.setTimeout(() => {
            cleanup();
            const processingType = isEngineProcessing(designEngine, types);
            reject(new ProcessingTimeoutError(`Waiting for processing timed out: ${processingType}`));
        }, msUntilTimeout);
    });
}
