import { Hex } from "@design-stack-vista/utility-core";

export interface LegacyQRCodeItemReferenceData {
    url: string;
    processedUrl: string;
    altId: string;
    color?: string;
    locale: string;
}

export enum DotPattern {
    Curved = "curved",
    Square = "square",
    Dots = "dots",
    ClassyBlocks = "classy-blocks",
    ConnectHorizontal = "connect-horizontal"
}

export enum EyePattern {
    Square = "square",
    Curved = "curved",
    LeftLeaf = "leftLeaf",
    RightLeaf = "rightLeaf",
    Rounded = "rounded"
}

export interface Dots {
    pattern?: DotPattern;
    color?: Hex;
}

export interface Corners {
    eyePattern?: EyePattern;
    eyeColor?: Hex;
    eyeBorderColor?: Hex;
}

export interface QRImage {
    previewUrl: string;
    printUrl: string;
    aspectRatio: number;
}

export interface QRIcon {
    name: string;
    color: Hex;
}

export interface QRCodeItemReferenceData {
    url: string;
    transparentBackground?: boolean;
    backgroundColor: Hex;
    processedUrl: string;
    altId: string;
    locale: string;
    image?: QRImage;
    icon?: QRIcon;
    dotsOptions: Dots;
    eyeOptions: Corners;
}

export enum QRShapeType {
    Corners = "corners",
    Dots = "dots"
}

export interface QRAsset {
    name: string;
    imgUrl: string;
    previewUrl: string;
    value: DotPattern | EyePattern | string;
}

export enum QRCodeURLPanelMode {
    Add = "add",
    Edit = "edit",
    AddMore = "addMore"
}

export interface QRAssetCollection {
    corners: QRAsset[];
    dots: QRAsset[];
    logos: QRAsset[];
    misc: Record<string, string>;
}

/**
 * @description The current tab to display in the QR content panel
 */
export enum QRCodeTabType {
    Url = "URL",
    Shape = "Shape",
    Color = "Color",
    ExtraImages = "Extra Images"
}

export interface FileTypeMapping {
    /**
     * Type of file this is: eg "image/jpeg", "image/bmp", "application/pdf" etc.
     */
    fileType: string;

    /**
     * List of extensions for the file type: eg ["jpg", jpeg"], ["bmp"], ["pdf"], etc
     */
    extensions: string[];
}
