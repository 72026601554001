import React from "react";
import type { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";
import { QRCodeIcon } from "@internal/ui-studio-chrome";

export const QRCodesContentDiscoveryButton: ContentDiscoveryZoneButton = ({ screenSize, children }) => {
    return children({
        icon: <QRCodeIcon aria-hidden="true" />,
        label: "QR Codes"
    });
};
