import { Dimensions } from "@design-stack-vista/cdif-types";
import { InteractiveDesignEngine } from "@design-stack-vista/interactive-design-engine-core";
import { Measurement } from "@design-stack-vista/utility-core";

export const getLayoutDimensions = (designEngine: InteractiveDesignEngine, dimensions: Dimensions) => {
    const { zoom } = designEngine.layoutStore;
    const { width: cdifWidth, height: cdifHeight } = dimensions;
    const width = new Measurement(cdifWidth).mm;
    const height = new Measurement(cdifHeight).mm;

    return {
        width: width * zoom,
        height: height * zoom
    };
};
