import React from "react";

export const CustomCutlineIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle
            cx={16}
            cy={16}
            r={15}
            stroke="black"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="5 5"
        />
        <path
            d="M19.5 19c-2.759 1.73-4.23 1.67-7 0"
            stroke="black"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle cx={16} cy={16} r={10} stroke="black" strokeWidth={1.5} />
        <circle cx={13} cy={14} r={1} fill="black" />
        <circle cx={19} cy={14} r={1} fill="black" />
    </svg>
);
