import { z } from "zod";
import type { SimInterface } from "@internal/sim-framework";
import defaultTranslations from "./i18n/en-US.json";

const api = z.object({
    allowDeleteImage: z.boolean()
});

const imageSim = {
    name: "@internal/advanced-editor-sim-image",
    core: {
        defaultTranslations,
        extensions: []
    },
    api
} satisfies SimInterface;

export default imageSim;
