import {
    useDesignEngine,
    useLargestMaskPathBounds,
    getActivePanel,
    getOptionalExtension
} from "@design-stack-vista/core-features";
import { getRandomPositionWithinArea } from "@design-stack-vista/utility-core";
import { PanelSectionExtension } from "@internal/feature-panel-sections";
import { useCallback } from "react";
import { parsePanelDimensionsToMm } from "@design-stack-vista/cimdoc-state-manager";

export function useGetRandomPosition() {
    const designEngine = useDesignEngine();
    const activePanel = getActivePanel(designEngine);
    const targetPanel = activePanel ?? designEngine.layoutStore.visiblePanels[0];
    const safeAreaBounds = useLargestMaskPathBounds(targetPanel, "SAFE");
    const panelSectionExtension = getOptionalExtension(designEngine, targetPanel, PanelSectionExtension);

    return useCallback(
        ({ height, width }: { height?: number; width?: number }) => {
            const unzoomedPanelDimensions = parsePanelDimensionsToMm(targetPanel);
            let safeAreaInteriorBounds = safeAreaBounds.interior ?? { x: 0, y: 0, ...unzoomedPanelDimensions };

            if (panelSectionExtension?.activeSectionBoundingBox) {
                safeAreaInteriorBounds = panelSectionExtension.activeSectionBoundingBox;
            }

            let { x, y } = getRandomPositionWithinArea(safeAreaInteriorBounds, { width, height });
            x += safeAreaInteriorBounds.x; // getRandomPositionWithinArea does not look at the area's X and Y position, so we need to manually fix that
            y += safeAreaInteriorBounds.y;

            return { x, y };
        },
        [targetPanel, panelSectionExtension?.activeSectionBoundingBox, safeAreaBounds]
    );
}
