import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { newRelicWrapper } from "@internal/utils-newrelic";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useIsFlagEnabled } from "@internal/utils-flags";
import { useErrors } from "@internal/utils-errors";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { observer } from "mobx-react-lite";
import { useTrackingClient } from "@internal/utils-tracking";
import type {
    ProductLoadingProviderType,
    UseProductLoadingContext,
    ProductLoadingContext,
    SimplifiedEaselConfig,
    UpdateProductParams
} from "@internal/utils-product-loading-provider-core";
import { useInitializeStudioConfigurationManager } from "../StudioConfigurationManager/useInitializeStudioConfigurationManager";
import { initializeStudioConfig } from "./initializeStudioConfig";
import { resolveCimDocAndViews } from "./resolveCimDocAndViews";

const productLoadingContext = React.createContext<ProductLoadingContext>({
    easelConfig: undefined,
    updateProduct: /* istanbul ignore next */ async () => false
});

export const useProductLoadingContext: UseProductLoadingContext = () => {
    return useContext(productLoadingContext);
};

export const ProductLoadingProvider: ProductLoadingProviderType = observer(
    ({ children, showWarnings, showAdjustmentAlert, setRemainingDefaultState }) => {
        const [easelConfig, setEaselConfig] = useState<SimplifiedEaselConfig>();
        const { isIdentityInitialized, identity, auth } = useIdentityContext();
        const [callingCalcifer, setCallingCalcifer] = useState(false);
        const { isSmall } = useStudioLayout();
        const productAndProjectStateManager = useProductAndProjectStateManager();
        const { locale, productDataLoadAttempted } = productAndProjectStateManager.data;
        const allowDynamicUnderlayScene = useIsFlagEnabled("allowDynamicUnderlayScene");
        const { handleError } = useErrors();
        const trackingClient = useTrackingClient();

        // This hook is called when (and only when) studio starts
        useEffect(() => {
            // calcifer requires auth to be initialized
            if (!isIdentityInitialized) {
                return;
            }
            async function initProductDataLoad() {
                newRelicWrapper.setDecorationTechnology();
                const studioConfig = await initializeStudioConfig({
                    identity,
                    auth,
                    locale,
                    allowDynamicUnderlayScene,
                    handleError,
                    fireGenericTrackingEvent: trackingClient.track
                });
                if (studioConfig) {
                    productAndProjectStateManager.initializeConfiguration(studioConfig.initialState);
                    setRemainingDefaultState(studioConfig.scenesConfiguration, studioConfig.hasDesigns);
                    showWarnings(studioConfig.warnings);
                    newRelicWrapper.updateCustomAttributes(studioConfig.initialState);
                    if (studioConfig.initialState.productDataLoadSuccessful && studioConfig.designSpecification) {
                        const authToken = auth.getToken();
                        const { cimDoc, designSpecification } = await resolveCimDocAndViews({
                            designSpecification: studioConfig.designSpecification,
                            existingDesignDocument: studioConfig.existingDesignDocument,
                            productKey: studioConfig.initialState.productKey!,
                            productVersion: studioConfig.initialState.productVersion!,
                            studioSelectedProductOptions: studioConfig.initialState.studioSelectedProductOptions!,
                            isFullBleed: studioConfig.initialState.isFullBleed!,
                            locale,
                            template: studioConfig.initialState.template ?? null,
                            documentRevisionUrl: studioConfig.documentRevisionUrl!,
                            authToken,
                            useDraggablePlaceholders: !isSmall,
                            designExperienceManagementState: studioConfig.designExperienceManagementState,
                            handleError,
                            showAdjustmentAlert
                        });
                        setEaselConfig({
                            designViews: designSpecification.views,
                            panelSections: studioConfig.panelSections || /* istanbul ignore next */ undefined,
                            cimDoc,
                            easelScenesConfiguration: studioConfig.scenesConfiguration,
                            shouldShowTeams: studioConfig.isTeamsProduct,
                            studioConfiguration: studioConfig.designExperienceManagementState,
                            productGroupConfiguration: studioConfig.productGroupConfiguration,
                            decorationTechnology: studioConfig.decorationTechnology,
                            isDesignAssistantTabEnabled: studioConfig.isDesignAssistantTabEnabled
                        });
                        cimDoc.document.panels.length > 0 &&
                            newRelicWrapper.setDecorationTechnology(cimDoc.document.panels[0].decorationTechnology);
                    }
                }
            }

            if (!productDataLoadAttempted && !callingCalcifer) {
                setCallingCalcifer(true);
                initProductDataLoad();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this running on initialization so a few dependencies have been ignored
        }, [
            isIdentityInitialized,
            auth,
            locale,
            productDataLoadAttempted,
            allowDynamicUnderlayScene,
            handleError,
            showWarnings,
            showAdjustmentAlert,
            setRemainingDefaultState
        ]);

        const updateProduct = useCallback(
            async (params: UpdateProductParams) => {
                newRelicWrapper.setDecorationTechnology();
                const studioConfig = await initializeStudioConfig({
                    project: params.newWork,
                    queryParamOverrides: params.queryParamOverrides,
                    identity,
                    auth,
                    locale,
                    allowDynamicUnderlayScene,
                    handleError,
                    fireGenericTrackingEvent: trackingClient.track
                });
                if (studioConfig) {
                    productAndProjectStateManager.initializeConfiguration(studioConfig.initialState);
                    setRemainingDefaultState(studioConfig.scenesConfiguration, studioConfig.hasDesigns);
                    showWarnings(studioConfig.warnings);
                    newRelicWrapper.updateCustomAttributes(studioConfig.initialState);
                    if (studioConfig.initialState.productDataLoadSuccessful && studioConfig.designSpecification) {
                        const authToken = auth.getToken();
                        if (params.newCimDoc) {
                            studioConfig.existingDesignDocument = params.newCimDoc;
                        }
                        const { cimDoc, designSpecification } = await resolveCimDocAndViews({
                            designSpecification: studioConfig.designSpecification,
                            existingDesignDocument: studioConfig.existingDesignDocument,
                            productKey: studioConfig.initialState.productKey!,
                            productVersion: studioConfig.initialState.productVersion!,
                            studioSelectedProductOptions: studioConfig.initialState.studioSelectedProductOptions!,
                            isFullBleed: studioConfig.initialState.isFullBleed!,
                            locale,
                            template: studioConfig.initialState.template ?? null,
                            documentRevisionUrl: studioConfig.documentRevisionUrl!,
                            authToken,
                            useDraggablePlaceholders: !isSmall,
                            handleError,
                            showAdjustmentAlert
                        });
                        // Make sure the active panel id still exists and that panel is editable.
                        // Otherwise discard it and let designEngine decide what to do (uses first panel)
                        // This is parity with studio5 afaik
                        let activePanelId = params.activeDesignPanelId;
                        if (activePanelId) {
                            const matchingPanel = cimDoc.document.panels.find(panel => panel.id === activePanelId);
                            /* istanbul ignore else --@preserve */
                            if (!matchingPanel || /* istanbul ignore next */ matchingPanel.colorMode === "blank") {
                                activePanelId = undefined;
                            }
                        }

                        setEaselConfig({
                            designViews: designSpecification.views,
                            panelSections: studioConfig.panelSections || /* istanbul ignore next */ undefined,
                            cimDoc,
                            easelScenesConfiguration: studioConfig.scenesConfiguration,
                            activeDesignPanelId: activePanelId,
                            shouldShowTeams: studioConfig.isTeamsProduct,
                            studioConfiguration: studioConfig.designExperienceManagementState,
                            productGroupConfiguration: studioConfig.productGroupConfiguration,
                            decorationTechnology: studioConfig.decorationTechnology,
                            isDesignAssistantTabEnabled: studioConfig.isDesignAssistantTabEnabled
                        });
                        cimDoc.document.panels.length > 0 &&
                            newRelicWrapper.setDecorationTechnology(cimDoc.document.panels[0].decorationTechnology);

                        return true;
                    }
                }
                return false;
            },
            [
                identity,
                auth,
                locale,
                allowDynamicUnderlayScene,
                handleError,
                productAndProjectStateManager,
                trackingClient.track,
                setRemainingDefaultState,
                showWarnings,
                isSmall,
                showAdjustmentAlert
            ]
        );

        const contextObject = useMemo(() => {
            return {
                easelConfig,
                updateProduct
            };
        }, [easelConfig, updateProduct]);

        useInitializeStudioConfigurationManager(
            easelConfig
                ? {
                      designExperienceManagementState: easelConfig.studioConfiguration,
                      productGroupConfigurationState: easelConfig.productGroupConfiguration!,
                      isTeamsProduct: easelConfig.shouldShowTeams,
                      decorationTechnology: easelConfig.decorationTechnology,
                      locale,
                      isDesignAssistantTabEnabled: easelConfig.isDesignAssistantTabEnabled
                  }
                : undefined
        );

        return <productLoadingContext.Provider value={contextObject}>{children}</productLoadingContext.Provider>;
    }
);

ProductLoadingProvider.displayName = "ProductLoadingProvider";
