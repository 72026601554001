import { backgroundRemoveUrl } from "../clients/backgroundRemoveClient";
import type { GetBackgroundRemovedUrls } from "../types";

const urlCache: Record<string, { print: string; preview: string }> = {};

export const getBackgroundRemovedUrls: GetBackgroundRemovedUrls = async getBackgroundRemovedUrlsConfig => {
    const { originalUrl, accessToken, vistaAssetStore } = getBackgroundRemovedUrlsConfig;

    if (!urlCache[originalUrl]) {
        const backgroundRemovedUrl = await backgroundRemoveUrl({
            imageUrl: originalUrl,
            accessToken
        });
        const backgroundRemovedAsset = await vistaAssetStore.upload({
            uri: backgroundRemovedUrl,
            expires: "never",
            info: {
                asset: {
                    hidden: true
                }
            }
        });

        await backgroundRemovedAsset.presign();

        urlCache[originalUrl] = {
            print: backgroundRemovedAsset.print.getUrl(),
            preview: backgroundRemovedAsset.webPreview.getUrl()
        };
    }

    return urlCache[originalUrl];
};
