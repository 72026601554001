import React from "react";
import { SkeletonText, SkeletonTextLine } from "@vp/swan";
import styles from "./PatternListSkeleton.module.scss";

export const PatternListSkeleton = () => {
    return (
        <SkeletonText className={styles.patternListSkeleton}>
            <SkeletonTextLine className={styles.patternListSkeletonText} m={0} />
        </SkeletonText>
    );
};
