import type { DSS } from "@vp/types-ddif";
import type { CimDoc } from "@design-stack-vista/cdif-types";
import type { ErrorHandler } from "@internal/utils-errors";
import type { DesignSpecification } from "@internal/data-access-calcifer";
import type { DesignExperienceManagementState } from "@internal/utils-studio-configuration-core";
import { upgradeCimDoc } from "@design-stack-vista/cimdoc-state-manager";
import { hydratePlaceholdersIntoCimdoc } from "@design-stack-vista/studio-document-metadata-management";
// `structuredClone` is not yet supported in Safari 15.0; see https://vistaprint.atlassian.net/wiki/spaces/NTEO/pages/60132385/Vistaprint+browser+support+
import cloneDeep from "lodash/cloneDeep";
import {
    importItemReferenceArrows,
    fixFillColorUndefined,
    updateCimdocForRaisedFoilColor,
    replaceChooseForMeInstructions,
    fixBackgroundFromTemplate,
    updateCimDocMinMaxFontSizes,
    removeImagesWithoutSignatures,
    updateCimdocToRemoveAlphaFromCMYKAColors,
    removeClipsFromCimDoc,
    removeTextAreasWithoutContent,
    pushMissingMetadataToPatternItems,
    fixDocumentSources,
    initializeItemMetadata,
    updateCimdocToNormalizeFontFamily,
    updateFontRepositoryUrl,
    updatesForCustomCutlineDocuments,
    upgradeDDIFDocument,
    removeUnsupportedCDIFKeys,
    checkForPanelMetadataDrift,
    updateForDimensionsMismatch
} from "@internal/utils-product-loading-provider-core";

interface PrepareStudioCimDocParams {
    cimDoc: DSS.DesignDocument;
    designSpecification: DesignSpecification;
    productKey: string;
    productVersion: number;
    studioSelectedProductOptions: Record<string, string>;
    locale: string;
    useDraggablePlaceholders: boolean;
    designExperienceManagementState?: DesignExperienceManagementState;
    handleError: ErrorHandler;
    showAdjustmentAlert: (changes: string) => void;
}

export async function prepareStudioCimDoc({
    cimDoc,
    designSpecification,
    productKey,
    productVersion,
    studioSelectedProductOptions,
    locale,
    useDraggablePlaceholders,
    designExperienceManagementState,
    handleError,
    showAdjustmentAlert
}: PrepareStudioCimDocParams): Promise<CimDoc> {
    let updatedCimDoc = cloneDeep(cimDoc);

    removeUnsupportedCDIFKeys(updatedCimDoc);

    if (designExperienceManagementState?.enableCutlineGenerationExperience) {
        updatedCimDoc = updatesForCustomCutlineDocuments(updatedCimDoc, handleError);
    }

    updatedCimDoc = checkForPanelMetadataDrift(updatedCimDoc, designSpecification);

    updatedCimDoc = await upgradeDDIFDocument(updatedCimDoc);

    updatedCimDoc = await updateForDimensionsMismatch(
        updatedCimDoc,
        designSpecification,
        productKey,
        productVersion,
        studioSelectedProductOptions,
        locale,
        showAdjustmentAlert
    );

    importItemReferenceArrows(updatedCimDoc);

    removeTextAreasWithoutContent(updatedCimDoc);

    updateCimdocForRaisedFoilColor(updatedCimDoc);

    fixFillColorUndefined(updatedCimDoc);

    initializeItemMetadata(updatedCimDoc);

    replaceChooseForMeInstructions(updatedCimDoc);

    updateFontRepositoryUrl(updatedCimDoc);

    updateCimdocToNormalizeFontFamily(updatedCimDoc);

    fixDocumentSources(updatedCimDoc);

    updateCimdocToRemoveAlphaFromCMYKAColors(updatedCimDoc);

    removeClipsFromCimDoc(updatedCimDoc);

    hydratePlaceholdersIntoCimdoc(updatedCimDoc, useDraggablePlaceholders);

    // hydrate above uses immer which freezes the cimdoc
    updatedCimDoc = cloneDeep(updatedCimDoc);

    fixBackgroundFromTemplate(updatedCimDoc);

    pushMissingMetadataToPatternItems(updatedCimDoc);

    const upgradedCimdoc = cloneDeep(upgradeCimDoc(updatedCimDoc));

    updateCimDocMinMaxFontSizes(upgradedCimdoc, designExperienceManagementState?.minFontSize ?? undefined);

    removeImagesWithoutSignatures(upgradedCimdoc);

    return upgradedCimdoc;
}
