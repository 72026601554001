import { apiConfig } from "./clients";
import { DotPattern, EyePattern } from "./types";
import { QRAssetCollection } from "./types/qrCodeItemRef";

const QR_ASSET_API_HOST = apiConfig.qrCodeAsset.baseUrl;

export const qrCodeAssets: QRAssetCollection = {
    corners: [
        {
            name: "square",
            imgUrl: `${QR_ASSET_API_HOST}/square-corner.svg`,
            previewUrl: `${QR_ASSET_API_HOST}/version1_HOVER.svg`,
            value: EyePattern.Square
        },
        {
            name: "curved",
            imgUrl: `${QR_ASSET_API_HOST}/rounded-corner.svg`,
            previewUrl: `${QR_ASSET_API_HOST}/version11_HOVER.svg`,
            value: EyePattern.Curved
        },
        {
            name: "leftLeaf",
            imgUrl: `${QR_ASSET_API_HOST}/points1-corner.svg`,
            previewUrl: `${QR_ASSET_API_HOST}/version3_HOVER.svg`,
            value: EyePattern.LeftLeaf
        },
        {
            name: "rightLeaf",
            imgUrl: `${QR_ASSET_API_HOST}/points2-corner.svg`,
            previewUrl: `${QR_ASSET_API_HOST}/version4_HOVER.svg`,
            value: EyePattern.RightLeaf
        }
    ],
    dots: [
        {
            name: "classyBlocks",
            imgUrl: `${QR_ASSET_API_HOST}/default.svg`,
            previewUrl: `${QR_ASSET_API_HOST}/default_HOVER.svg`,
            value: DotPattern.ClassyBlocks
        },
        {
            name: "curved",
            imgUrl: `${QR_ASSET_API_HOST}/rounded1.svg`,
            previewUrl: `${QR_ASSET_API_HOST}/rounded-1_HOVER.svg`,
            value: DotPattern.Curved
        },
        {
            name: "dots",
            imgUrl: `${QR_ASSET_API_HOST}/dots.svg`,
            previewUrl: `${QR_ASSET_API_HOST}/dots_HOVER.svg`,
            value: DotPattern.Dots
        },
        {
            name: "square",
            imgUrl: `${QR_ASSET_API_HOST}/squareDot.svg`,
            previewUrl: `${QR_ASSET_API_HOST}/square_HOVER.svg`,
            value: DotPattern.Square
        },
        {
            name: "connectHorizontal",
            imgUrl: `${QR_ASSET_API_HOST}/connectHorizontal.svg`,
            previewUrl: `${QR_ASSET_API_HOST}/connect-horizontal_HOVER.svg`,
            value: DotPattern.ConnectHorizontal
        }
    ],
    logos: [
        {
            name: "none",
            imgUrl: `${QR_ASSET_API_HOST}/none.svg`,
            previewUrl: "",
            value: "none"
        },
        {
            name: "scanme",
            imgUrl: `${QR_ASSET_API_HOST}/ScanMe.svg`,
            previewUrl: `${QR_ASSET_API_HOST}/ScanMe_HOVER.svg`,
            value: "scanme"
        },
        {
            name: "review",
            imgUrl: `${QR_ASSET_API_HOST}/Review.svg`,
            previewUrl: `${QR_ASSET_API_HOST}/Review_HOVER.svg`,
            value: "review"
        },
        {
            name: "watch",
            imgUrl: `${QR_ASSET_API_HOST}/Watch.svg`,
            previewUrl: `${QR_ASSET_API_HOST}/Watch_HOVER.svg`,
            value: "watch"
        }
    ],
    misc: {
        images: "https://qr-code-generator.digital.vpsvc.com/defaultMetadataImage.png",
        imageUploaderGif: `https://qr-code-generator.digital.vpsvc.com/loader2.gif`
    }
};
