import type { CimDoc, ItemReference } from "@design-stack-vista/cdif-types";
import { getNewIdAndNextZIndex, initializeMetadata, PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
import { teamsNameNumberValidations, teamsNameTextValidations } from "../utils/teamsNameValidators";
import type { TeamsNamePlaceholderType } from "../@types";

interface AddTeamsNameItemProps {
    panel: PanelState;
    fontFamily: string;
    color: string;
    width: number;
    height: number;
    x: number;
    y: number;
    placeholderData: { content: string; placeholderKey: string; placeholderType: TeamsNamePlaceholderType };
}

export function addTeamsNameItem(
    cimDoc: CimDoc,
    { panel, fontFamily, color, placeholderData, x, y, width, height }: AddTeamsNameItemProps
) {
    const position = {
        x: `${x}mm`,
        y: `${y}mm`,
        width: `${width}mm`,
        height: `${height}mm`
    };
    const { zIndex, id } = getNewIdAndNextZIndex(cimDoc, panel.id);

    const itemReferenceItem: ItemReference = {
        id,
        zIndex,
        position,
        type: ItemReferenceTypes.TEAMS_NAME,
        url: "https://udsinterop.document.vpsvc.com/api/itemref/wordart",
        data: {
            fontStyle: "Normal,Normal",
            fontFamily,
            color,
            ...placeholderData,
            focus: "center",
            curve: {
                radius: 0,
                height: 0,
                angle: 0
            }
        }
    };

    const panelIndex = cimDoc.document.panels.findIndex(p => p.id === panel.id);
    const currentPanel = cimDoc.document.panels[panelIndex];
    if (currentPanel.itemReferences) {
        currentPanel.itemReferences.push(itemReferenceItem);
    } else {
        currentPanel.itemReferences = [itemReferenceItem];
    }

    initializeMetadata(cimDoc, { initializeTemplate: true });
    cimDoc.metadata?.template?.push({
        id: itemReferenceItem.id,
        locks: { edit: false, transform: false, zIndex: false }
    });
    if (cimDoc.metadata && !cimDoc.metadata.teamsPlaceholders) {
        cimDoc.metadata.teamsPlaceholders = [];
    }
    cimDoc.metadata?.teamsPlaceholders.push({
        key: id,
        itemReferenceType: ItemReferenceTypes.TEAMS_NAME,
        name: placeholderData.placeholderKey,
        type: placeholderData.placeholderType,
        validations:
            placeholderData.placeholderType === "Number" ? teamsNameNumberValidations : teamsNameTextValidations
    });

    return { id: itemReferenceItem.id };
}
