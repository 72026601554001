import type { Item, ItemReference, Subpanel, ImageItem } from "@design-stack-vista/cdif-types";
import { ItemState, SubpanelState, isItemReference } from "@design-stack-vista/cimdoc-state-manager";
import { InteractiveDesignEngine, ItemTemplateExtension } from "@design-stack-vista/interactive-design-engine-core";
import { getRequiredExtension } from "@design-stack-vista/core-features";
import { QRCodeItemReferenceData } from "../types";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";

export const isQrCodeTypeReference = (
    item: ItemState | SubpanelState
): item is ItemState<ItemReference<QRCodeItemReferenceData>> => {
    return item.isItemReference() && item.model.type === ItemReferenceTypes.QR_CODE;
};

export function isQRCodeItem(item: Item | Subpanel): item is ItemReference<QRCodeItemReferenceData> {
    return isItemReference(item) && item.type === ItemReferenceTypes.QR_CODE;
}

/**
 * @description The intent of the QR code placeholder item.
 */
const QR_CODE_INTENT = "QR Code";

/**
 * @description Returns true if the item is a placeholder for a QR code.
 * @param item
 * @param designEngine
 * @returns
 */
export function isImageQRPlaceholder(item: ItemState<ImageItem>, designEngine: InteractiveDesignEngine) {
    const { intent } = getRequiredExtension(designEngine, item, ItemTemplateExtension);
    return intent === QR_CODE_INTENT;
}
