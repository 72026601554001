import React, { useCallback, useState, useEffect } from "react";
import Cookies from "js-cookie";
import {
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogFooter,
    ModalDialogNav
} from "@vp/swan";
import { DesktopExperience, MobileExperience, useStudioLayout } from "@internal/feature-responsive-design";
import { MobileCarousel } from "./components/MobileCarousel/MobileCarousel";
import { DesktopBody } from "./components/DesktopBody/DesktopBody";
import { ConfirmButton } from "./components/ConfirmButton";
import { HelpButton } from "./components/HelpButton";
import { Header } from "./components/Header";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { useTranslationSSR } from "@vp/i18n-helper";
import { messages } from "./common";

const COOKIE_FLAG_NAME = "howToDialogShowed";

export const HowToDialog = () => {
    const [isOpen, setIsOpen] = useState(false);
    const trackingClient = useTrackingClient();
    const { isSmall } = useStudioLayout();
    const { t } = useTranslationSSR();

    // we add this aria-label additionally for Percy "scope"
    const dialogTitle = t(messages.dialogTitle.id);
    const closeBtnLabel = t(messages.closeAccessibilityText.id);

    const handleOpen = useCallback(() => {
        trackingClient.track(Events.DesignToolUsed, { eventDetail: "show:teamsExperienceGuideModal" });
        setIsOpen(true);
    }, [trackingClient]);

    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (!Cookies.get(COOKIE_FLAG_NAME)) {
            handleOpen();
            Cookies.set(COOKIE_FLAG_NAME, "true");
        }
    }, [handleOpen]);

    return (
        <>
            <HelpButton onClick={handleOpen} />
            <ModalDialog
                bodyWidth="grow"
                isOpen={isOpen}
                takeOver={isSmall}
                variant={isSmall ? "panel-bottom" : undefined}
                onRequestDismiss={handleClose}
                onlyRenderWhenOpen
            >
                <DesktopExperience>
                    <ModalDialogContent px={7} pb={7} aria-label={dialogTitle}>
                        <ModalDialogCloseButton visuallyHiddenLabel={closeBtnLabel} />
                        <Header />
                        <DesktopBody />
                        <ModalDialogFooter>
                            <ConfirmButton onClick={handleClose} />
                        </ModalDialogFooter>
                    </ModalDialogContent>
                </DesktopExperience>
                <MobileExperience>
                    <ModalDialogContent aria-label={dialogTitle}>
                        <ModalDialogNav>
                            <ModalDialogCloseButton visuallyHiddenLabel={closeBtnLabel} />
                        </ModalDialogNav>
                        <Header />
                        <ModalDialogBody my="auto" mx={0}>
                            <MobileCarousel />
                        </ModalDialogBody>
                        <ModalDialogFooter pinned>
                            <ConfirmButton onClick={handleClose} mb="0" />
                        </ModalDialogFooter>
                    </ModalDialogContent>
                </MobileExperience>
            </ModalDialog>
        </>
    );
};
