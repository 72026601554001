import { useCallback } from "react";
import { CimDoc, CurveItemV2, FigureItem, Stroke } from "@design-stack-vista/cdif-types";
import type { CutlineShape as CutlineShapeAsType, UnitlessBoundingBox } from "@prepress/custom-cutline-utility";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import {
    Measurement,
    MeasurementUnit,
    getBoundingBox,
    type UnitlessDimensions
} from "@design-stack-vista/utility-core";
import { getRequiredExtension, PanelChromesExtension, useDesignEngine } from "@design-stack-vista/core-features";
import type { Mask, Path } from "@design-stack-vista/interactive-design-engine-core";
import { PanelState, parsePanelDimensionsToMm } from "@design-stack-vista/cimdoc-state-manager";
import { convertFigureItemToPath } from "./convertFigureItemToPath";
import { CutlineShapeType, CutlinePrecisionType } from "./CutlineConfigurationManager";
import { useCutlineConfiguration } from "./CutlineConfigurationProvider";
import { useTranslationSSR } from "@vp/i18n-helper";
import type { DispatchToast } from "@internal/utils-detail-zone";
import { useTrackingClient, Events } from "@internal/utils-tracking";
interface CutlineData {
    cutlineItem: CurveItemV2 | FigureItem;
    adjustedContentBounds: UnitlessBoundingBox;
    cutlineBoundingBox: UnitlessBoundingBox;
    safetyDistanceInMm: number;
    cutlinePath: Path;
    cutlinePrecision: CutlinePrecisionType;
}

export interface SafetyDistanceConfig {
    minSafetyDistance: number;
    mediumSafetyDistance: number;
    maxSafetyDistance: number;
}

const CUTLINE_SAFETY_DISTANCE_MM = 3;
const CORNER_RADIUS_PERCENTAGE = 0.05;
const SAFETY_DISTANCE_INCREMENTAL_PERCENTAGE = 1;

const getCutlineStroke = (panelDimensions: UnitlessDimensions): Stroke => {
    const STROKE_THICKNESS_SCALE_FACTOR = 0.003;
    const { width, height } = panelDimensions;

    const smallestPanelDimension = Math.min(width, height);
    const thicknessInMm = smallestPanelDimension * STROKE_THICKNESS_SCALE_FACTOR;

    const thickness = new Measurement(thicknessInMm, MeasurementUnit.MM).measurement;
    const dashSegmentLength = new Measurement(thicknessInMm * 3, MeasurementUnit.MM).measurement;

    return {
        color: "rgb(#000000)",
        thickness,
        lineCap: "round",
        lineJoin: "round",
        dashPattern: {
            segments: [
                {
                    length: dashSegmentLength
                }
            ]
        }
    };
};

export const getCutlineBoundingBoxFromTrim = (
    panelDimensions: UnitlessDimensions,
    trimMask?: Mask
): UnitlessBoundingBox => {
    // create bounding box within the trim mask if one exists
    if (trimMask) {
        const points = trimMask.paths.flatMap(path => [path.anchor, ...path.points]);
        return getBoundingBox(points);
    }

    // if no trim mask, return bounding box the same size as the entire panel
    return {
        x: 0,
        y: 0,
        ...panelDimensions
    };
};

function getDefaultSafetyDistanceConfig(
    { width, height }: UnitlessDimensions,
    percentage: number
): SafetyDistanceConfig {
    const incrementalDistance = Math.min((Math.min(width, height) * percentage) / 100, CUTLINE_SAFETY_DISTANCE_MM);

    return {
        minSafetyDistance: CUTLINE_SAFETY_DISTANCE_MM,
        mediumSafetyDistance: CUTLINE_SAFETY_DISTANCE_MM + incrementalDistance,
        maxSafetyDistance: CUTLINE_SAFETY_DISTANCE_MM + 2 * incrementalDistance
    };
}

const getCutlineId = (shape: string, index: number) => `${shape}-cutline-${index}`;

export const useGenerateCutline = ({ dispatchToast }: { dispatchToast: DispatchToast }) => {
    const { auth } = useIdentityContext();
    const designEngine = useDesignEngine();
    const cutlineManager = useCutlineConfiguration();
    const { t } = useTranslationSSR();
    const cutlineMessages = cutlineManager.getCutlineMessages();
    const trackingClient = useTrackingClient();

    const getBasicCutlineData = useCallback(
        async (
            cimDoc: CimDoc,
            panelDimensions: UnitlessDimensions,
            cutlineBoundingBox: UnitlessBoundingBox,
            cutlineShape: CutlineShapeType,
            basicCutlineShape: CutlineShapeAsType.Circle | CutlineShapeAsType.Rectangle
        ): Promise<CutlineData> => {
            const { width, height } = panelDimensions;
            const defaultCornerRadiusInMm = Math.min(width, height) * CORNER_RADIUS_PERCENTAGE;

            try {
                const { generateBasicCutline } = await import("@prepress/custom-cutline-utility");

                const { cutlineItems, adjustedContentBounds } = await generateBasicCutline({
                    basicCutlineShape,
                    // @ts-expect-error - type mismatch, resolve after @prepress/custom-cutline-utility is updated
                    cimDoc,
                    cutlineBoundingBox,
                    safetyDistanceInMm: CUTLINE_SAFETY_DISTANCE_MM,
                    cornerRadiusInMm: cutlineShape === "roundedRectangle" ? defaultCornerRadiusInMm : undefined
                });

                const cutlineItem = cutlineItems.map((item, index) => ({
                    ...item,
                    id: getCutlineId(cutlineShape, index),
                    stroke: getCutlineStroke(panelDimensions)
                }))[0];

                // @ts-expect-error - cdif-types requires zIndex and cimdoc-types-v2 does not
                const cutlinePath = convertFigureItemToPath(cutlineItem);

                return {
                    adjustedContentBounds,
                    // @ts-expect-error - cdif-types requires zIndex and cimdoc-types-v2 does not
                    cutlineItem,
                    cutlinePath,
                    cutlineBoundingBox,
                    safetyDistanceInMm: CUTLINE_SAFETY_DISTANCE_MM,
                    cutlinePrecision: "tight"
                };
            } catch (err) {
                dispatchToast({
                    uniqueKey: cutlineMessages.basicCutlineError.id,
                    skin: "standard",
                    message: t(cutlineMessages.basicCutlineError.id),
                    autoClose: true
                });
                throw err;
            }
        },
        [dispatchToast, t, cutlineMessages]
    );

    const getCustomCutlineData = useCallback(
        async (
            cimDoc: CimDoc,
            panelDimensions: UnitlessDimensions,
            cutlineBoundingBox: UnitlessBoundingBox,
            cutlineShape: CutlineShapeType,
            cutlineSafetyDistanceConfig: SafetyDistanceConfig,
            currentSafetyDistance: number,
            noCache?: boolean
        ): Promise<CutlineData> => {
            // eslint-disable-next-line compat/compat -- we don't support Opera-mini
            const startTime = performance.now();

            try {
                const previewUrl = cimDoc.document.panels[0].images?.[0].previewUrl;

                const cachedItems = previewUrl ? cutlineManager.getCachedCutline(previewUrl) : undefined;

                if (cachedItems && !noCache) {
                    // @ts-expect-error - cdif-types requires color and cimdoc-types-v2 does not
                    return cachedItems;
                }

                const { generateCustomCutline } = await import("@prepress/custom-cutline-utility");

                const { cutlineItems, adjustedContentBounds, bezierPaths } = await generateCustomCutline({
                    // @ts-expect-error - type mismatch, resolve after @prepress/custom-cutline-utility is updated
                    cimDoc,
                    cutlineBoundingBox,
                    accessToken: auth.getToken(),
                    safetyDistanceInMm: currentSafetyDistance
                });

                // If there are multiple cutlines, recursively call getCustomCutlineData with looser safety distances
                //  to hopefully have the different cutlines merge into one line
                // The function is first called by generateCutline() with the minSafetyDistance, then falls back to the
                //  mediumSafetyDistance, lastly maxSafetyDistance. If they all fail to resolve a single cutline, then throw
                //  an error and use a basic cutline
                if (cutlineItems.length !== 1 || bezierPaths.length !== 1) {
                    if (currentSafetyDistance === cutlineSafetyDistanceConfig.minSafetyDistance) {
                        return getCustomCutlineData(
                            cimDoc,
                            panelDimensions,
                            cutlineBoundingBox,
                            cutlineShape,
                            cutlineSafetyDistanceConfig,
                            cutlineSafetyDistanceConfig.mediumSafetyDistance,
                            noCache
                        );
                    }
                    if (currentSafetyDistance === cutlineSafetyDistanceConfig.mediumSafetyDistance) {
                        return getCustomCutlineData(
                            cimDoc,
                            panelDimensions,
                            cutlineBoundingBox,
                            cutlineShape,
                            cutlineSafetyDistanceConfig,
                            cutlineSafetyDistanceConfig.maxSafetyDistance,
                            noCache
                        );
                    }
                    throw new Error("Cannot apply multiple cutlines.");
                }

                const cutlineItem = cutlineItems.map((item, index) => ({
                    ...item,
                    id: getCutlineId(cutlineShape, index),
                    stroke: getCutlineStroke(panelDimensions)
                }))[0];

                let cutlinePrecision: CutlinePrecisionType = "tight";
                if (currentSafetyDistance === cutlineSafetyDistanceConfig.mediumSafetyDistance) {
                    cutlinePrecision = "medium";
                }
                if (currentSafetyDistance === cutlineSafetyDistanceConfig.maxSafetyDistance) {
                    cutlinePrecision = "loose";
                }

                const cutlineData: CutlineData = {
                    adjustedContentBounds,
                    // @ts-expect-error - cdif-types requires position and cimdoc-types-v2 does not
                    cutlineItem,
                    cutlineBoundingBox,
                    cutlinePath: bezierPaths[0],
                    safetyDistanceInMm: currentSafetyDistance,
                    cutlinePrecision
                };

                if (!noCache) {
                    // @ts-expect-error - cdif-types and cimdoc-types-v2 differ on type of closeBehavior
                    cutlineManager.updateCutlineCache(previewUrl ?? "", cutlineData);
                }

                trackingClient.track(Events.StudioDiagnostic, {
                    label: "Cutline generation complete",
                    eventDetail: "Cutline Generation Completed",
                    extraData: () => ({
                        // eslint-disable-next-line compat/compat -- we don't support Opera-mini
                        timeToGenerateCutline: performance.now() - startTime
                    })
                });

                return cutlineData;
            } catch (err: unknown) {
                const errorMessage = err instanceof Error ? err.message : "Unknown error reason";
                trackingClient.track(Events.StudioDiagnostic, {
                    label: "Cutline generation failed",
                    eventDetail: "Cutline Generation failed",
                    extraData: () => ({
                        // eslint-disable-next-line compat/compat -- we don't support Opera-mini
                        timeToGenerateCutline: performance.now() - startTime,
                        cutlineFailure: errorMessage,
                        imageUrl: cimDoc.document.panels[0].images?.[0].previewUrl
                    })
                });

                dispatchToast({
                    uniqueKey: cutlineMessages.customCutlineError.id,
                    skin: "standard",
                    message: t(cutlineMessages.customCutlineError.id),
                    autoClose: true
                });

                const { CutlineShape } = await import("@prepress/custom-cutline-utility");

                return getBasicCutlineData(
                    cimDoc,
                    panelDimensions,
                    cutlineBoundingBox,
                    cutlineShape,
                    CutlineShape.Rectangle
                );
            }
        },
        [auth, getBasicCutlineData, dispatchToast, t, cutlineManager, cutlineMessages, trackingClient]
    );

    const generateCutline = useCallback(
        async (
            cimDoc: CimDoc,
            panelState: PanelState,
            cutlineShape: CutlineShapeType,
            altCutlineBoundingBox?: UnitlessBoundingBox
        ): Promise<CutlineData> => {
            const { width, height } = parsePanelDimensionsToMm(cimDoc.document.panels[0]);
            let panelDimensions = { width, height };
            let cutlineBoundingBox = altCutlineBoundingBox;

            // working with document in design engine, get inputs from there
            if (!cutlineBoundingBox) {
                const { masks } = getRequiredExtension(designEngine, panelState, PanelChromesExtension);
                const trim = masks.find(mask => mask.type === "TRIM");
                cutlineBoundingBox = getCutlineBoundingBoxFromTrim(panelDimensions, trim);
                panelDimensions = parsePanelDimensionsToMm(panelState);
            }

            const cutlineSafetyDistanceConfig = getDefaultSafetyDistanceConfig(
                panelDimensions,
                SAFETY_DISTANCE_INCREMENTAL_PERCENTAGE
            );

            const { CutlineShape } = await import("@prepress/custom-cutline-utility");

            switch (cutlineShape) {
                case "circle":
                    return getBasicCutlineData(
                        cimDoc,
                        panelDimensions,
                        cutlineBoundingBox,
                        cutlineShape,
                        CutlineShape.Circle
                    );
                case "rectangle":
                case "roundedRectangle":
                    return getBasicCutlineData(
                        cimDoc,
                        panelDimensions,
                        cutlineBoundingBox,
                        cutlineShape,
                        CutlineShape.Rectangle
                    );
                case "custom":
                default:
                    return getCustomCutlineData(
                        cimDoc,
                        panelDimensions,
                        cutlineBoundingBox,
                        cutlineShape,
                        cutlineSafetyDistanceConfig,
                        cutlineSafetyDistanceConfig.minSafetyDistance,
                        !!altCutlineBoundingBox
                    );
            }
        },
        [designEngine, getBasicCutlineData, getCustomCutlineData]
    );

    return {
        generateCutline
    };
};
