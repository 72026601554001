import { convertToAuthHeaders } from "@design-stack-vista/utility-core";
import { v4 as uuidv4 } from "uuid";
import { apiConfig } from "./apiConfig";
// eslint-disable-next-line no-restricted-imports -- FIXME use scopedNewrelicWrapper
import { newRelicWrapper } from "@internal/utils-newrelic";

const websiteMetadata = `${apiConfig.qrCodeGenerator.baseUrl}/api/v1/fetch-site-metadata`;
export const fetchQRMetadata = async (url: string, authToken: string): Promise<any> => {
    try {
        const response = await fetch(`${websiteMetadata}/?siteUrl=${url}`, {
            method: "get",
            headers: {
                ...convertToAuthHeaders({ value: authToken, authType: "bearer" }),
                "x-request-id": uuidv4()
            }
        });
        return response.json();
    } catch (error) {
        newRelicWrapper.noticeError(new Error("Fetch metadata failed"), {
            method: "fetchQRMetadata",
            message: (error as Error).message,
            url: url
        });
    }
};
