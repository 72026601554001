import { crispify } from "../clients";
import type { GetSharpenedUrls } from "../types";

const urlCache: Record<string, { print: string; preview: string }> = {};

export const getSharpenedUrls: GetSharpenedUrls = async getSharpenedUrlsConfig => {
    const { originalUrl, accessToken, vistaAssetStore } = getSharpenedUrlsConfig;

    if (!urlCache[originalUrl]) {
        const sharpenedUrl = await crispify({ imageUrl: originalUrl, accessToken });
        const sharpenedAsset = await vistaAssetStore.upload({
            uri: sharpenedUrl,
            expires: "never",
            info: {
                asset: {
                    hidden: true
                }
            }
        });

        await sharpenedAsset.presign();

        urlCache[originalUrl] = {
            print: sharpenedAsset.getUrl(),
            preview: sharpenedAsset.webPreview.getUrl()
        };
    }

    return urlCache[originalUrl];
};
