export const teamsNameTextValidations = [
    {
        message: "Maximum 20 characters",
        type: "regex",
        value: "^.{0,20}$"
    }
];

export const teamsNameNumberValidations = [
    {
        message: "Maximum 20 numeric characters",
        type: "regex",
        value: "^[0-9]{0,20}$"
    }
];
