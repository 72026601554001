import React from "react";

export const RectangleCutlineIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M.75.75h28.5v28.5H.75V.75Z"
            stroke={"black"}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
