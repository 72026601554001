import { action, computed, makeObservable, observable, when } from "mobx";
import { applyPatches } from "immer";
import {
    BaseExtension,
    HistoryStore,
    HISTORY_STORE_TOKEN,
    DesignExtensionSystem,
    DESIGN_EXTENSION_SYSTEM_TOKEN,
    ItemProcessingExtension,
    CIMDOC_STORE_TOKEN,
    ItemMetadataExtension,
    ItemTemplateExtension,
    ItemLocksExtension
} from "@design-stack-vista/interactive-design-engine-core";
import { validateImageResolution } from "@design-stack-vista/vista-validations";
import { Measurement, MeasurementUnit } from "@design-stack-vista/utility-core";
import { ImageCroppingExtension, ItemLayoutExtension, ItemPreviewExtension } from "@design-stack-vista/core-features";
import type { ImageItem, ImageOverlay } from "@design-stack-vista/cdif-types";
import {
    type ItemState,
    type DesignState,
    isEmbroidery,
    getRootPanel,
    CimDocStore
} from "@design-stack-vista/cimdoc-state-manager";
import { VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { AppDispatch, setAlerts } from "@shared/redux";
import {
    type InstantUploadData,
    type ImageResolutionValidatorItemConfig,
    isAssetMultiPage,
    cleanupCimdocAfterFailure,
    updateItemWithUpload,
    isAssetProcessed,
    validateAssetAndRetrieveDimensions,
    onUploadErrorEvent,
    getModifiedAsset,
    isImageModificationAllowed,
    type ModificationType
} from "@internal/utils-assets";
import { StudioLocalizationProvider } from "@six/features/Translation";
import {
    getImageResolutionStatus,
    getItemPixelDimensions
} from "@six/features/editorUI/validations/components/imageResolutionValidation/helper";
import { ImageResolutionStatus } from "@six/features/editorUI/validations/components/types";
import { isSingleColor } from "@internal/utils-deco-tech";
import { uploadsAndAssetsMessages } from "./messages";
import { InstantUploadStore } from "./InstantUploadStore/InstantUploadStore";

/**
 * This extension exists to enable the automatic handling of an "instant upload":
 * an imageItem that has had a temporary URL assigned to it such that it can be displayed immediately while the asset is still uploading.
 */
export class ImageInstantUploadExtension<T extends ImageItem> extends BaseExtension {
    declare designState: ItemState<T>;

    /**
     * This property exposes whether there are any ongoing uploads that might still affect this item in the future
     */
    // Note: this is intentionally tracked separately from `ItemProcessingExtension.isProcessing` to avoid displaying spinners on an item
    @observable isUploading = false;

    private stopProcessing: undefined | (() => void);

    // Used to prevent race conditions when an instant upload image is replaced before a previous upload completes
    private controller: AbortController;

    static supports(state: DesignState): boolean {
        return state.isItemState() && state.isImageItem();
    }

    static override inject = [
        HISTORY_STORE_TOKEN,
        DESIGN_EXTENSION_SYSTEM_TOKEN,
        CIMDOC_STORE_TOKEN,
        "dispatch",
        "localizationProvider",
        "instantUploadStore"
    ];

    constructor(
        designState: DesignState,
        private historyStore: HistoryStore,
        private designExtensionSystem: DesignExtensionSystem,
        private cimDocStore: CimDocStore,
        private dispatch: AppDispatch,
        private localizationProvider: StudioLocalizationProvider,
        private instantUploadStore: InstantUploadStore
    ) {
        super(designState);
        makeObservable(this);

        const existingInstantUpload = this.instantUploadStore.currentUploadsMap.get(this.designState.id);
        if (existingInstantUpload) {
            this.setUpload(existingInstantUpload);
        }
        // If I'm instantiated and there is no other item handling the current upload I need to track it myself
        else if (this.designState.model.previewUrl && this.designState.model.previewUrl.startsWith("blob:")) {
            const assetPromise = this.instantUploadStore.instantUploadMap.get(this.designState.model.previewUrl);
            if (assetPromise) {
                this.setUpload({ assetPromise, temporaryUrl: this.designState.model.previewUrl });
            }
            // if I don't have an asset then one of two things has occurred
            // this is a new item and I haven't recorded the upload in the store yet
            // something went wrong and the upload is missing from the store and now I'm stranded
        }
    }

    @computed
    get renderingStatus() {
        const itemPreview = this.designExtensionSystem.getExtension(this.designState.iid, ItemPreviewExtension);
        if (!itemPreview) {
            throw Error(`Could not find an ItemPreviewExtension for iid: ${this.designState.iid}`);
        }
        return itemPreview.renderingStatus;
    }

    /**
     * Starts tracking the status of a new upload. Once the upload promise resolves, related items and any history steps
     * referencing them will be automatically updated with the new asset's information, or deleted if the asset failed to upload
     *
     * @param assetPromise A promise for the asset that should be used to update any items that have the `temporaryURL` defined in the second argument
     * @param data Data related to the upload that is needed for resolving history, see: {@link InstantUploadData}
     */
    @action.bound
    setUpload(data: InstantUploadData) {
        if (this.controller) {
            this.controller.abort();
        }
        this.controller = new AbortController();
        const { signal } = this.controller;

        this.isUploading = true;
        if (data.showProcessing) {
            this.stopProcessing = this.designExtensionSystem
                .getExtension(this.designState.iid, ItemProcessingExtension)
                ?.startProcessing();
        }
        data.assetPromise
            .then(asset => {
                if (asset) {
                    when(
                        () => isAssetProcessed(asset),
                        async () => {
                            if (!signal.aborted) {
                                try {
                                    await this.swap(asset, data);
                                } catch (error) {
                                    this.rollback(data);
                                    onUploadErrorEvent({
                                        asset,
                                        error: error.message,
                                        source: "ImageInstantUploadExtension"
                                    });
                                }
                            }
                        }
                    );
                } else if (!signal.aborted) {
                    this.rollback(data);
                }
            })
            .catch(() => {
                if (!signal.aborted) {
                    this.rollback(data);
                }
            });
    }

    private async swap(asset: VistaAsset, data: InstantUploadData) {
        const { eventId, imageProperties } = data;
        const { decorationTechnology } = getRootPanel(this.designState).panelProperties;
        const isEmbroideryPanel = isEmbroidery(decorationTechnology);
        const isSingleColorPanel = isSingleColor(decorationTechnology);
        // Ensure the asset is in a state in which it is usable on a design
        await validateAssetAndRetrieveDimensions(asset);

        // Get print & preview urls for fetched asset so we can swap them into relevant items in the document history
        const { printUrl, previewUrl, appliedProcesses } = await this.applyModifications(asset, data);
        let originalSourceUrl = asset.getUrl();
        const overlays: ImageOverlay[] = [];
        if (isSingleColorPanel && data.originalSourceUrl) {
            /**
             * Since we rely on monochrome asset generation `asset.getUrl` will give monochrome asset url. We need to store the
             * originalSourceUrl of the original (multicolor) asset which has been passed through `InstantUploadData`
             */
            ({ originalSourceUrl } = data);
            if (!imageProperties?.color) {
                /**
                 * If by some reason the color property remains undefined, the monochromized image will probably pick the first color
                 * as default which can be an invalid single color document or it might result in unwanted behavior.
                 */
                this.rollback(data);
                return;
            }
            overlays.push({
                color: imageProperties.color,
                previewUrl,
                printUrl
            });
        }

        // We need to rewrite history to use the new asset URL from the step that the item was first created, otherwise
        // undos & redos could potentially restore the temporaryUrl instead of the finished asset's URL.

        // `historyStore.rewriteFrom` enables this by accepting a callback used to iterate through history, which is passed a
        // CimDoc representing a snapshot from that point in history, which we can freely modify to update the temporary URL
        // See https://interactive-design-engine-core.ddt.cimpress.io/HistoryStore/ for more information

        // if we persisted this from a previous upload then our event may not exist, we gotta start from the beginning of the store
        const eventIdExists = eventId && this.historyStore.changes.some(event => event.id === eventId);
        const newEventId = eventIdExists ? eventId : this.historyStore.changes[0]?.id;

        if (!eventIdExists) {
            this.cimDocStore.executeCommand(updateItemWithUpload, {
                id: this.designState.id,
                data,
                asset,
                originalSourceUrl,
                previewUrl,
                printUrl,
                additionalImageProperties: {
                    overlays,
                    threshold: imageProperties?.threshold,
                    inverted: imageProperties?.inverted
                },
                isEmbroideryPanel,
                isSingleColorPanel,
                appliedProcesses
            });
        }

        if (newEventId) {
            // They are written inline here, but eventually we would like to export commands from cimdoc-state-manager purpose-built for helping with these cleanup cases
            // TODO: (DIYCP-24) Replace this callback with a relevant command once one is available
            this.historyStore.rewriteFrom(newEventId, (cimDoc, { itemIds }) => {
                itemIds
                    .filter(itemId => itemId === this.designState.id)
                    .forEach(id => {
                        updateItemWithUpload(cimDoc, {
                            id,
                            data,
                            asset,
                            originalSourceUrl,
                            previewUrl,
                            printUrl,
                            additionalImageProperties: {
                                overlays,
                                threshold: imageProperties?.threshold,
                                inverted: imageProperties?.inverted
                            },
                            isEmbroideryPanel,
                            isSingleColorPanel,
                            appliedProcesses
                        });
                    });
            });
        }

        this.isUploading = false;
        this.instantUploadStore.removeCurrentUpload(this.designState.id);
        const itemPreviewExtension = this.designExtensionSystem.getExtension(
            this.designState.iid,
            ItemPreviewExtension
        );
        when(
            () => itemPreviewExtension?.renderingStatus !== "IN_PROGRESS",
            () => this.stopProcessing?.()
        );
    }

    private rollback(data: InstantUploadData) {
        // if we persisted this from a previous upload then our event may not exist, we gotta start from the beginning of the store
        const eventIdExists = data.eventId && this.historyStore.changes.some(event => event.id === data.eventId);
        const newEventId = eventIdExists ? data.eventId : this.historyStore.changes[0]?.id;

        if (!eventIdExists) {
            // if the id does not exist then we can't (easily) revert the change
            this.cimDocStore.executeCommand(cleanupCimdocAfterFailure, { id: this.designState.id, data });
        }

        if (newEventId) {
            // If the upload fails we need to rewrite history to remove/revert any item that contains the potentially unsafe temporaryUrl
            this.historyStore.rewriteFrom(newEventId, (cimDoc, { event }) => {
                // If this was an image replacement event, we want to simply roll the image back to its initial state before the replacement
                if (data.replacedImageId && data.eventId === event.id) {
                    // eslint-disable-next-line no-param-reassign
                    cimDoc = applyPatches(cimDoc, event.reverse);
                    return;
                }

                // TODO: itemIds will be returned after resolving https://vistaprint.atlassian.net/browse/DIYCP-406
                cleanupCimdocAfterFailure(cimDoc, { id: this.designState.id, data });
            });
        }

        this.dispatch(
            setAlerts({
                alerts: [
                    {
                        key: `UploadFailed`,
                        skin: "error",
                        message: this.localizationProvider(uploadsAndAssetsMessages.uploadFailedGeneral.id)
                    }
                ]
            })
        );

        this.isUploading = false;
        this.instantUploadStore.removeCurrentUpload(this.designState.id);
        this.stopProcessing?.();
    }

    private async applyModifications(asset: VistaAsset, data: InstantUploadData) {
        const { autoRemoveBackground, assetStore, authToken, imageResolutionConfig, pageNumber } = data;

        const shouldRemoveBackground =
            autoRemoveBackground &&
            assetStore &&
            authToken &&
            (await this.isModificationAllowed(asset, "backgroundRemove"));
        if (shouldRemoveBackground) {
            const appliedProcesses = ["backgroundRemove"];
            const shouldSharpen = await this.isModificationAllowed(asset, "sharpen", imageResolutionConfig);
            if (shouldSharpen) {
                appliedProcesses.push("sharpen");
            }

            try {
                const { printUrl, previewUrl } = await getModifiedAsset(
                    appliedProcesses,
                    assetStore,
                    authToken,
                    asset.getUrl(),
                    asset
                );

                if (printUrl && previewUrl) {
                    return { printUrl, previewUrl, appliedProcesses };
                }
            } catch (error) {
                // In case the automatic removal of the background fails
                // the previewUrl and printUrl of the current asset will be returned
            }
        }

        const { decorationTechnology } = getRootPanel(this.designState).panelProperties;
        const isEmbroideryPanel = isEmbroidery(decorationTechnology);
        const getUrlParams = { pageNum: (isAssetMultiPage(asset) && pageNumber) || undefined };

        return {
            printUrl: isEmbroideryPanel ? asset.embroidery.getUrl(getUrlParams) : asset.print.getUrl(),
            previewUrl: asset.webPreview.getUrl(getUrlParams),
            appliedProcesses: []
        };
    }

    private async isModificationAllowed(
        asset: VistaAsset,
        modificationType: ModificationType,
        imageResolutionConfig?: ImageResolutionValidatorItemConfig
    ) {
        const metadataExtension = this.designExtensionSystem.getExtension(this.designState.iid, ItemMetadataExtension);
        const templateExtension = this.designExtensionSystem.getExtension(this.designState.iid, ItemTemplateExtension);
        const locksExtension = this.designExtensionSystem.getExtension(this.designState.iid, ItemLocksExtension);

        if (!metadataExtension || !templateExtension || !locksExtension) {
            return false;
        }

        if (modificationType === "sharpen" && imageResolutionConfig) {
            const itemLayoutExtension = this.designExtensionSystem.getExtension(
                this.designState.iid,
                ItemLayoutExtension
            );
            const imageCroppingExtension = this.designExtensionSystem.getExtension(
                this.designState.iid,
                ImageCroppingExtension
            );
            const dimensions = itemLayoutExtension?.interactiveBoundingBox;
            const { width, height } =
                getItemPixelDimensions(asset, imageCroppingExtension?.currentCropping, false) || {};

            if (!dimensions || !width || !height) {
                return false;
            }

            const { isValid, payload } = await validateImageResolution({
                pixelDimensions: {
                    width,
                    height
                },
                containerDimensions: {
                    width: new Measurement(dimensions.width, MeasurementUnit.MM).measurement,
                    height: new Measurement(dimensions.height, MeasurementUnit.MM).measurement
                },
                minimumPpi: imageResolutionConfig.minimumPpi
            });

            const resolutionStatus = payload?.ppi && getImageResolutionStatus(payload.ppi, imageResolutionConfig);
            if (isValid || resolutionStatus === ImageResolutionStatus.OK) {
                return false;
            }
        }

        return isImageModificationAllowed(
            modificationType,
            this.designState,
            metadataExtension,
            templateExtension,
            locksExtension,
            true,
            asset
        );
    }

    /**
     * This doesn't have a cleanup method to dispose anything because even if an image is deleted,
     * we still want the processing to finish to update history in case the user undoes the deletion
     */
}
