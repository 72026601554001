import { RGBA, rgb2hex } from "@design-stack-vista/utility-core";
import { i18nColorName } from "@design-stack-vista/i18n-color-name";
import { formatPantoneColor } from "@internal/utils-color";
import { DEFAULT_ENGRAVING_SELECTABLE_COLOR, isEngraved } from "@internal/utils-deco-tech";
// eslint-disable-next-line no-restricted-imports -- FIXME use scopedNewrelicWrapper
import { newRelicWrapper } from "@internal/utils-newrelic";
import { convertToRGBA } from "@mcp-artwork/color-converter";
import { ColorPaletteEntry } from "../types";

/**
 * We took these colors from studio5 standard palette to maintain parity.
 * As of now we don't have a function to convert hex values to Pantone colors so we cant use studio6's standardPaletteColorStrings.
 *
 * TODO: replace this mapping to have standardPaletteColorStrings (converted to pantone colors) as a fallback if a product doesn't
 * have availablePantoneColors
 */
const defaultPantoneColors = [
    // grey
    "PANTONE White",
    "PANTONE Cool Gray 4 C",
    "PANTONE Cool Gray 9 C",
    "PANTONE Black 7 C",
    "PANTONE Black 6 C",
    // blue
    "PANTONE 2717 C",
    "PANTONE 2995 C",
    "PANTONE 2925 C",
    "PANTONE 2728 C",
    "PANTONE Blue 072 C",
    // green
    "PANTONE 2254 C",
    "PANTONE 802 C",
    "PANTONE 2269 C",
    "PANTONE 2272 C",
    "PANTONE 2259 C",
    // yellow
    "PANTONE Yellow 0131 C",
    "PANTONE Yellow C",
    "PANTONE 7408 C",
    // orange
    "PANTONE Bright Orange C",
    "PANTONE 732 C",
    "PANTONE 2339 C",
    // red
    "PANTONE 2028 C",
    "PANTONE Bright Red C",
    "PANTONE 1805 C",
    "PANTONE 1815 C",
    // purple
    "PANTONE 250 C",
    "PANTONE 2375 C",
    "PANTONE 225 C",
    "PANTONE 7649 C",
    "PANTONE 2355 C"
];

export const getSingleColorColorPalette = (
    locale: string,
    hasColorsPreFetched: boolean,
    availablePantoneColors?: string[],
    decorationTechnology?: string,
    engravingColor?: string
): ColorPaletteEntry[] => {
    // Even though this color won't be visible in the palette in case of laser engraving products,
    // we need to add it because some items rely on palette to get their color
    if (isEngraved(decorationTechnology)) {
        const hexValue = engravingColor || DEFAULT_ENGRAVING_SELECTABLE_COLOR.cssBackground;
        return [
            {
                cdifValue: DEFAULT_ENGRAVING_SELECTABLE_COLOR.value,
                cssBackground: hexValue,
                name: i18nColorName(hexValue, {
                    culture: locale,
                    colorPalette: "gallery"
                })
            }
        ];
    }

    if (!hasColorsPreFetched) {
        // return empty array if colors are not loaded
        return [];
    }

    const colorsSet = availablePantoneColors?.length ? availablePantoneColors : defaultPantoneColors;

    return colorsSet
        .map(pantoneColor => {
            const pantoneValue = formatPantoneColor(pantoneColor);
            let rgbaValue: RGBA | undefined;
            try {
                rgbaValue = convertToRGBA(pantoneValue);
            } catch (error) {
                // Log error in case of MissingPantoneColorsError or InvalidPantoneColorError
                newRelicWrapper.noticeError(error as Error, {
                    color: pantoneColor,
                    value: pantoneValue
                });
                // Skip the entry to handle errors
                return null;
            }
            if (!rgbaValue) {
                newRelicWrapper.noticeError(new Error(`No color is provide or it is undefined`), {
                    color: pantoneColor,
                    value: pantoneValue
                });
                // Skip the entry to handle undefined
                return null;
            }
            const hexValue = rgb2hex(rgbaValue);

            return {
                cdifValue: pantoneValue,
                cssBackground: hexValue,
                name: i18nColorName(hexValue, { culture: locale, colorPalette: "gallery" })
            };
        })
        .filter(entry => entry !== null) as ColorPaletteEntry[]; // Filter out entries that failed conversion
};
