import { useCallback } from "react";
import { useGetTeamsNameItems } from "./useGetTeamsNameItems";

export function useGetTeamsNameTextPlaceholderSuffix(): () => number {
    const getTeamsNameItems = useGetTeamsNameItems();
    return useCallback(() => {
        const indices = getTeamsNameItems()
            .filter(item => item.model.data.placeholderType === "Text")
            .map(item => {
                const suffix = item.model.data.content.split(" ").pop();
                return parseInt(suffix as string);
            });

        const index = indices.sort((a, b) => a - b).findIndex((value, index) => value !== index + 1);

        return (index !== -1 ? index : indices.length) + 1;
    }, [getTeamsNameItems]);
}
