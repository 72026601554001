import { useStudioLayout } from "@internal/feature-responsive-design";
import { patternMessage } from "@pattern-sim/shared";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Typography } from "@vp/swan";
import React from "react";
import styles from "./PatternTextDescription.module.scss";

export const PatternTextDescription = () => {
    const { isSmall } = useStudioLayout();
    const { t } = useTranslationSSR();

    return (
        <>
            <Typography fontSize={"large"} fontWeight="bold">
                {t(patternMessage.patternTitle.id)}
            </Typography>
            <Typography fontSize={isSmall ? "standard" : "small"} className={styles.subtitle} textColor="subtle">
                {t(patternMessage.patternDescription.id)}
            </Typography>
        </>
    );
};

PatternTextDescription.displayName = "PatternTextDescription";
