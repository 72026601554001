import { i18nColorName } from "@design-stack-vista/i18n-color-name";
import { ColorGroup } from "@design-stack-vista/pattern-sdk";
import { ColorOption } from "@design-stack-vista/pattern-sdk/dist/utility/themeHelpers/types";

export const getColorGroup = (colors: string[]): ColorGroup => {
    return colors.reduce<ColorGroup>((acc, color, index) => {
        acc[`pathgroup${index + 1}`] = color;
        return acc;
    }, {});
};

export const getColorName = (randomColors: ColorOption[][], locale: any) => {
    return randomColors.map(colorSet =>
        colorSet.map(({ value, cssBackground }: any) => ({
            value: value,
            cssBackground: cssBackground,
            name: i18nColorName(cssBackground, { culture: locale, colorPalette: "gallery" })
        }))
    );
};
