import { computed, makeObservable } from "mobx";
import type { DesignState, ItemState } from "@design-stack-vista/cimdoc-state-manager";
import { BaseExtension } from "@design-stack-vista/interactive-design-engine-core";
import { ItemReference } from "@design-stack-vista/cdif-types";
import { QRCodeItemReferenceData } from "../shared/types";
import { Corners, Dots } from "../shared/types/qrCodeItemRef";
import { DEFAULT_BASE_COLOR } from "../shared/utils/upgradeLegacyQRCode";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";

/**
 * Extension that provides the QR code item data.
 */
export class QRCodeExtension extends BaseExtension {
    protected declare designState: ItemState<ItemReference<QRCodeItemReferenceData>>;

    static override supports(designState: DesignState): boolean {
        return designState.isItemReference() && designState.model.type === ItemReferenceTypes.QR_CODE;
    }

    constructor(designState: DesignState) {
        super(designState);
        makeObservable(this);
    }

    @computed
    get id(): string {
        return this.designState.model.id;
    }

    @computed
    get QRData(): QRCodeItemReferenceData {
        return this.designState.model.data;
    }

    @computed
    get url(): string {
        return this.designState.model.data.url;
    }

    @computed
    get corners(): Corners {
        return this.designState.model.data.eyeOptions;
    }

    @computed
    get dots(): Dots {
        return this.designState.model.data.dotsOptions;
    }

    @computed
    get isBackgroundInvertActive(): boolean {
        return this.designState.model.data.backgroundColor !== DEFAULT_BASE_COLOR;
    }
}
