/* istanbul ignore file -- @preserve */
// eslint-disable-next-line no-restricted-imports -- qs replacement happens in another Marvel ticket
import qs from "qs";
import { getDocument } from "@internal/data-access-document-storage";
import type { DSS } from "@vp/types-ddif";
import { ERROR_CODES, StudioError } from "@internal/utils-errors";
import { newRelicWrapper } from "@internal/utils-newrelic";
import { isStaging, isLocalHost, isBranch } from "@internal/utils-browser";
import { convertDocumentSourceType, DocumentSourceType } from "@internal/data-access-design-specifications-service";
import {
    CalciferSceneConfiguration,
    DesignSpecification,
    StudioConfig,
    getStudioConfigFromCalciferV2
} from "@internal/data-access-calcifer";
import { getDomainForLocale } from "@internal/data-access-live-site";
import { type WorkEntity } from "@internal/data-access-work-entity-service";
import { type ErrorHandler } from "@internal/utils-errors";
import type { ProductAndProjectState } from "@internal/utils-product-and-project-state";
import type {
    DesignExperienceManagementState,
    ProductGroupConfiguration
} from "@internal/utils-studio-configuration-core";
import { DecorationTechnology, toDecorationTechnology } from "@internal/utils-deco-tech";
import { PanelSectionData } from "@internal/feature-panel-sections";
import type { useIdentityContext } from "@design-stack-vista/identity-provider";
import { BaseTrackingClient } from "@internal/utils-tracking";
import {
    documentDimensionsFailure,
    ENTITY_CODE,
    InitializationQueryParamOverrides,
    resolveInitializationData
} from "@internal/utils-product-loading-provider-core";

type Identity = ReturnType<typeof useIdentityContext>["identity"];

async function shouldUserLogIn(error: Error, identity: Identity) {
    const workPermissionsError = new RegExp(`20-403`);
    return !identity.isSignedIn && error.message.toString().match(workPermissionsError);
}

interface InitializeStudioConfigV2Input {
    identity: Identity;
    auth: any;
    locale: string;
    project?: WorkEntity;
    queryParamOverrides?: InitializationQueryParamOverrides;
    allowDynamicUnderlayScene?: boolean;
    handleError: ErrorHandler;
    fireGenericTrackingEvent: BaseTrackingClient["track"];
}

interface InitializeStudioConfigV2Output {
    initialState: ProductAndProjectState;
    hasDesigns: boolean;
    panelSections: PanelSectionData[] | null;
    isTeamsProduct: boolean;
    decorationTechnology: DecorationTechnology;
    warnings: string[];
    designExperienceManagementState: DesignExperienceManagementState;
    productGroupConfiguration?: ProductGroupConfiguration;
    existingDesignDocument?: DSS.DesignDocument;
    designSpecification?: DesignSpecification;
    scenesConfiguration: CalciferSceneConfiguration;
    documentRevisionUrl?: string;
    isDesignAssistantTabEnabled: boolean;
}

export const initializeStudioConfig = async (
    {
        identity,
        auth,
        locale,
        project,
        queryParamOverrides,
        allowDynamicUnderlayScene,
        handleError,
        fireGenericTrackingEvent
    }: InitializeStudioConfigV2Input,
    throwError = false
): Promise<InitializeStudioConfigV2Output | null> => {
    let designExperienceManagementState = {} as StudioConfig["studioConfiguration"];
    let mcpSku = "";
    let mcpVersion;
    let designs = 0;
    let panelSections: PanelSectionData[] | null = null;
    let isTeamsProduct = false;
    let isDesignAssistantTabEnabled = false;
    let productGroupConfiguration: ProductGroupConfiguration | undefined;
    let warnings: string[] = [];
    let existingDesignDocument: DSS.DesignDocument | undefined;
    let views;
    let designDocument;
    let workTemplateToken: string | undefined;
    let isFullBleedDocument = false;
    let productDataLoadSuccessful = false;
    let scenesConfiguration: CalciferSceneConfiguration = {
        underlay: [],
        overlay: [],
        review: [],
        transient: [],
        hqTransient: []
    };
    let initialState: ProductAndProjectState;
    let productName: string | undefined;
    let productCategory: string | undefined;

    let mpvId: string | undefined;
    let workId: string | undefined;
    let productVersion: number | undefined;
    let customerSelectedProductOptions: Record<string, string> | undefined;
    let studioSelectedProductOptions: Record<string, string> | undefined;
    let documentUrl: string | undefined;
    let template: string | undefined;
    let isFullBleed: boolean = false;
    let documentRevisionUrl: string | undefined;
    let quantity: number | undefined;
    let quantityPerSize: string | undefined;
    let productKey: string | undefined;
    let workRevisionId: string | undefined;
    let workName: string | undefined;
    let workLastSaved: string | undefined;
    let owner: string | undefined;

    try {
        const resolvedInitializationData = await resolveInitializationData({
            workEntity: project,
            queryParamOverrides,
            identity,
            locale,
            fireGenericTrackingEvent,
            auth
        });

        if (!resolvedInitializationData) {
            return null;
        }

        if (resolvedInitializationData?.resolvedProductData) {
            ({
                mpvId,
                workId,
                productVersion,
                mpvId,
                customerSelectedProductOptions,
                studioSelectedProductOptions,
                quantity,
                quantityPerSize,
                documentUrl,
                template,
                isFullBleed,
                documentRevisionUrl,
                productKey,
                workRevisionId,
                workName,
                workLastSaved,
                owner
            } = resolvedInitializationData.resolvedProductData);
        }

        if (!productKey && !mpvId && !resolvedInitializationData?.workEntity) {
            throw new Error(
                JSON.stringify({
                    errorMessage: "No Product Data Provided In URL",
                    errorCodeStack: `${ENTITY_CODE}-${ERROR_CODES.NO_PRODUCT_DATA}`
                })
            );
        }

        const workEntity = resolvedInitializationData?.workEntity;

        const existingDesignDocumentPromise = documentRevisionUrl ? getDocument(documentRevisionUrl, auth) : undefined;

        let initialCalciferError;
        let calciferStudioConfig: StudioConfig | undefined;

        try {
            calciferStudioConfig = await getStudioConfigFromCalciferV2(
                productKey,
                mpvId,
                productVersion,
                customerSelectedProductOptions,
                studioSelectedProductOptions,
                quantity,
                locale,
                workEntity && !mpvId ? workEntity.merchandising.mpvUrl : null,
                isFullBleed,
                template,
                documentRevisionUrl,
                allowDynamicUnderlayScene
            );
        } catch (e) {
            initialCalciferError = e;
        }
        existingDesignDocument = await existingDesignDocumentPromise;

        // failing to receive complete set of options from RANCH or use the current set of options
        // due to incorrect encoding in the product options
        // try calling after replacing + in product options with a space
        if (initialCalciferError && customerSelectedProductOptions) {
            let hasIncorrectProductOptions;
            const fixedCustomerSelectedProductOptions = Object.fromEntries(
                Object.entries(customerSelectedProductOptions).map(([key, value]) => {
                    let fixedKey = key;
                    let fixedValue = value;
                    if (key.includes("+")) {
                        hasIncorrectProductOptions = true;
                        fixedKey = key.split("+").join(" ");
                    }
                    if ((value as string).includes("+")) {
                        hasIncorrectProductOptions = true;
                        fixedValue = (value as string).split("+").join(" ");
                    }
                    return [fixedKey, fixedValue];
                })
            ) as Record<"string", "string">;
            if (hasIncorrectProductOptions) {
                newRelicWrapper.addPageAction("studio-loading-incorrect-product-options", {
                    customerSelectedProductOptions,
                    productKey,
                    productVersion
                });
                try {
                    const anotherCalciferConfig = await getStudioConfigFromCalciferV2(
                        productKey,
                        mpvId,
                        productVersion,
                        fixedCustomerSelectedProductOptions,
                        studioSelectedProductOptions,
                        quantity,
                        locale,
                        workEntity && !mpvId ? workEntity.merchandising.mpvUrl : null,
                        isFullBleed,
                        template,
                        documentRevisionUrl
                    );

                    calciferStudioConfig = anotherCalciferConfig;
                } catch (e) {
                    // the fallback didn't work.  swallow this exception, we'll do one last check for locale mismatch after this
                }
            }
        }

        // if we received an error from calcifer
        // or if the views differ greatly from the design document
        // then try running calcifer without providing a product key, falling back to the MPV.
        // in some cases product keys in the work may be invalid
        // Basically in this case, instead of showing the user an error, lets try one more thing to get this session to load
        if (
            mpvId &&
            (initialCalciferError ||
                (existingDesignDocument &&
                    calciferStudioConfig &&
                    documentDimensionsFailure(existingDesignDocument, calciferStudioConfig.designViews.designViews)))
        ) {
            try {
                const anotherCalciferConfig = await getStudioConfigFromCalciferV2(
                    undefined,
                    mpvId,
                    undefined,
                    customerSelectedProductOptions,
                    studioSelectedProductOptions,
                    quantity,
                    locale,
                    workEntity && !mpvId ? workEntity.merchandising.mpvUrl : null,
                    isFullBleed,
                    template,
                    documentRevisionUrl
                );
                calciferStudioConfig = anotherCalciferConfig;
            } catch (e) {
                // the fallback didn't work.  swallow this exception, we'll do one last check for locale mismatch after this
            }
        }

        // if we received an error or a warning when loading calcifer
        // either could be due to pricing issues
        // we skip this check if we're loading with overrides because we're in the middle of a design session, no reason to redirect
        // since presumably we loaded successfully before
        if (!queryParamOverrides && (initialCalciferError || (calciferStudioConfig?.warnings?.length ?? 0) > 0)) {
            // assuming the locale is in the path, not the query string
            const workLocale =
                workEntity?.merchandising?.mpvUrl &&
                workEntity.merchandising.mpvUrl
                    .toLowerCase()
                    .split("/")
                    .find(section => /^[a-z]{2}-[a-z]{2}$/.test(section));
            // if the locale from the work does not match the current locale, assume the pricing issues were due to locale mismatch
            if (
                workEntity &&
                workLocale &&
                workLocale.toLowerCase() !== locale.toLowerCase() &&
                // don't get into weird redirect loops
                !document?.referrer?.includes(window.location.pathname)
            ) {
                const correctDomain = await getDomainForLocale(workLocale);
                newRelicWrapper.addPageAction("studio-loading-locale-redirect", {
                    newLocale: workLocale,
                    correctDomain,
                    editUrl: workEntity.design?.editUrl
                });
                if (correctDomain && workEntity.design?.editUrl) {
                    // get the current parameters so we don't lose them
                    const splitEditUrl = workEntity.design.editUrl.split("?");
                    const existingParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
                    const workEditParams = qs.parse(splitEditUrl.length > 1 ? splitEditUrl[1] : "", {
                        ignoreQueryPrefix: true
                    });
                    const params = qs.stringify({
                        ...existingParams,
                        ...workEditParams
                    });
                    const newUrl = `https://${correctDomain}${splitEditUrl[0]}?${params}`;
                    if (isStaging() || isLocalHost() || isBranch()) {
                        // eslint-disable-next-line no-console -- this console log is useful for debugging in non-prod
                        console.log(`If on prod, would have redirected to ${newUrl} because of locale mismatch`);
                    } else {
                        window.location.href = newUrl;
                        return null;
                    }
                }
            }
        }

        // If we dont have a calcifer config and we did not redirect to the correct locale throw the initial exception now
        if (initialCalciferError && !calciferStudioConfig) {
            throw initialCalciferError;
        }

        if (!calciferStudioConfig) {
            throw Error("Calcifer studio config not loaded");
        }
        const { showDesignAssistantTab } = workEntity?.design?.metadata || {};
        isDesignAssistantTabEnabled = showDesignAssistantTab ? JSON.parse(showDesignAssistantTab) : false;
        ({
            mpvId,
            productVersion,
            productName,
            productCategory,
            quantity,
            studioSelectedProductOptions,
            productKey,
            views,
            designDocument,
            customerSelectedProductOptions,
            designExperienceManagementState,
            productGroupConfiguration,
            mcpSku,
            mcpVersion,
            designs,
            panelSections,
            scenesConfiguration,
            isTeamsProduct,
            warnings
        } = {
            mpvId: calciferStudioConfig.mpvId || undefined,
            productVersion: calciferStudioConfig.productVersion,
            productName: calciferStudioConfig.productName,
            productCategory: calciferStudioConfig.productCategory || undefined,
            quantity: calciferStudioConfig.quantity,
            studioSelectedProductOptions: calciferStudioConfig.selectedOptions,
            productKey: calciferStudioConfig.productKey || undefined,
            views: calciferStudioConfig.designViews.designViews,
            designDocument: calciferStudioConfig.designDocument,
            customerSelectedProductOptions: calciferStudioConfig.customerSelectedOptions,
            designExperienceManagementState: calciferStudioConfig.studioConfiguration,
            productGroupConfiguration: {
                ...calciferStudioConfig.productGroupConfiguration,
                hasAiTemplatesEnabled:
                    isDesignAssistantTabEnabled || calciferStudioConfig.productGroupConfiguration.hasAiTemplatesEnabled
            },
            mcpSku: calciferStudioConfig.mcpSku,
            mcpVersion: calciferStudioConfig.mcpVersion,
            designs: calciferStudioConfig.designs,
            panelSections: calciferStudioConfig.panelSectionSets,
            scenesConfiguration: calciferStudioConfig.scenesConfiguration,
            isTeamsProduct: calciferStudioConfig.isTeamsProduct || false,
            warnings: calciferStudioConfig.warnings
        });

        if (existingDesignDocument) {
            const panelSources = existingDesignDocument.metadata?.documentSources?.panels as DSS.DocumentPanelSource[];
            const frontPanelId = existingDesignDocument.document.panels[0].id;
            const frontPanelSource = panelSources && panelSources.find(panelSource => panelSource.id === frontPanelId);

            workTemplateToken =
                frontPanelSource?.source === convertDocumentSourceType(DocumentSourceType.TEMPLATE_TOKEN)
                    ? frontPanelSource.data
                    : undefined;

            // determine whether or not the document in a work entity is a fullbleed document so we don't show the test
            // experience later in the flow
            isFullBleedDocument = frontPanelSource?.source === convertDocumentSourceType(DocumentSourceType.FULLBLEED);
        }

        productDataLoadSuccessful = true;
        newRelicWrapper.addPageAction("studio-loading-initstate-finish");
    } catch (err) {
        if (await shouldUserLogIn(err as Error, identity)) {
            newRelicWrapper.addPageAction("studio-loading-forcelogin-work");
            auth.signIn();
        } else if (throwError) {
            throw err;
        } else {
            handleError(err as StudioError, ERROR_CODES.START_STUDIO, ENTITY_CODE, true);
        }
    } finally {
        initialState = {
            mpvId,
            locale,
            productName: productName || "",
            productCategory: productCategory || "",
            quantity: quantity || 0,
            quantityPerSize,
            studioSelectedProductOptions: studioSelectedProductOptions || customerSelectedProductOptions || {},
            customerSelectedProductOptions: customerSelectedProductOptions || {},
            productKey: productKey || "",
            template: template || workTemplateToken,
            workId,
            workRevisionId,
            owner,
            documentUrl,
            productDataLoadAttempted: true,
            productDataLoadSuccessful,
            isFullBleed: isFullBleed || isFullBleedDocument,
            productVersion: productVersion || 0,
            workName,
            workLastSaved,
            mcpSku: mcpSku,
            mcpVersion
        };
    }

    return {
        initialState,
        designSpecification: views && designDocument ? { views, designDocument } : undefined,
        existingDesignDocument,
        scenesConfiguration,
        decorationTechnology: toDecorationTechnology(designDocument?.document.panels[0]?.decorationTechnology),
        productGroupConfiguration,
        designExperienceManagementState,
        hasDesigns: designs > 0,
        panelSections,
        isTeamsProduct,
        warnings,
        documentRevisionUrl,
        isDesignAssistantTabEnabled
    };
};
