import React from "react";
import { TextIcon } from "@internal/ui-studio-chrome";
import type { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";

export const ThingAContentDiscoveryZoneButton: ContentDiscoveryZoneButton = ({ screenSize, children }) => {
    return children({
        icon: <TextIcon aria-hidden="true" />,
        label: "Thing A"
    });
};

export const ThingBContentDiscoveryZoneButton: ContentDiscoveryZoneButton = ({ screenSize, children }) => {
    if (screenSize === "largeScreen") {
        return children({
            icon: <TextIcon aria-hidden="true" />,
            label: "Thing B"
        });
    }
    return null;
};
