import React from "react";
import { Button, ButtonProps, Link } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";

const messages = defineMessages({
    openDialogButtonText: {
        id: "sims.teamsName.contentDiscoveryZone.howToDialog.openDialogButtonText.text",
        defaultMessage: "How to use placeholders"
    }
});

export const HelpButton = ({ onClick }: ButtonProps) => {
    const { t } = useTranslationSSR();

    return (
        <Link
            skin="cta"
            render={p => (
                <Button skin="link" className={p.className} onClick={onClick}>
                    {p.children}
                </Button>
            )}
        >
            {t(messages.openDialogButtonText.id)}
        </Link>
    );
};
