import type { UseHandleDropTarget } from "@internal/sim-framework";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { DraggedVector } from "../@types";
import { useCallback } from "react";
import { useAddVector } from "../../hooks";
import { PAGE_ACTION_EVENTS, getScopedNewrelicWrapper } from "@internal/utils-newrelic";
import { NR_SCOPE } from "../../constants";

const newrelic = getScopedNewrelicWrapper(NR_SCOPE);

export const useHandleDropTarget: UseHandleDropTarget<DraggedVector> = () => {
    const onAddVector = useAddVector();
    const trackingClient = useTrackingClient();

    return useCallback(
        config => {
            const { height, width, subpanelUrl, colorValues } = config.dropped.item as DraggedVector;
            onAddVector(subpanelUrl, colorValues, { width, height }, config.position);

            trackingClient.track(Events.DesignToolUsed, {
                eventDetail: "drag:vector:add",
                label: "Drag clipart",
                extraData: () => ({
                    item: config.dropped.item
                })
            });

            newrelic.addPageAction(PAGE_ACTION_EVENTS.ContentDiscoveryZoneInteraction, {
                eventDetail: "drag:vector:add",
                panel: JSON.stringify(config.position),
                item: JSON.stringify(config.dropped.item)
            });
        },
        [onAddVector, trackingClient]
    );
};
