import { CimDoc } from "@design-stack-vista/cdif-types";
import { AfterDuplicatePanelCallback } from "@internal/sim-framework";

export const afterDuplicatePanelCallback: AfterDuplicatePanelCallback = () => (cimDoc: CimDoc, panelId: string) => {
    if (cimDoc.document.panels.find(panel => panel.id === panelId)) {
        const newDoc = structuredClone(cimDoc);
        const targetPanel = cimDoc.document.panels.find(panel => panel.id === panelId)!;
        if (!targetPanel.textAreas) {
            targetPanel.textAreas = [];
        }
        targetPanel.textAreas.push({
            id: "my-text-area",
            position: {
                x: "6mm",
                y: "6mm",
                height: "10mm",
                width: "50mm"
            },
            content: [
                {
                    content: "mock sim",
                    color: "rgb(#000000)",
                    fontFamily: "Arimo",
                    fontSize: "16pt",
                    fontStyle: "normal"
                }
            ],
            zIndex: 1
        });
        return { doc: newDoc, transformations: [{ id: "my-text-area", type: "new-field" }] };
    }
    return undefined;
};
