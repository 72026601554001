import { Item, ItemReference, Subpanel } from "@design-stack-vista/cdif-types";
import { ItemState, SubpanelState, isItemReference } from "@design-stack-vista/cimdoc-state-manager";
import { VectorItemReferenceData } from "../types";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";

export const isVectorUrl = (url: string): boolean => {
    return url.indexOf("items.documents.cimpress.io/v1/vector") > 0;
};

export const isVectorItemReference = (
    item: ItemState<any> | SubpanelState
): item is ItemState<ItemReference<VectorItemReferenceData>> => {
    return item.isItemReference() && item.model.type === ItemReferenceTypes.VECTOR_PANEL && isVectorUrl(item.model.url);
};

export function isVectorItem(item: Item | Subpanel): item is ItemReference<VectorItemReferenceData> {
    return isItemReference(item) && item.type === ItemReferenceTypes.VECTOR_PANEL && isVectorUrl(item.url);
}
