import { patternMessage } from "@pattern-sim/shared";
import { Listbox, ListboxButton, ListboxPopover, ListboxList, ListboxOption } from "@vp/swan";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { usePatternsContentManager } from "@design-stack-vista/pattern-sdk";
import { PatternListSkeleton } from "./PatternListSkeleton";
import styles from "./PatternList.module.scss";

const ALL_PATTERNS = "All patterns";

interface PatternListProps {
    handleSelectedCategory: (category: string) => void;
}

export const PatternList = observer(({ handleSelectedCategory }: PatternListProps) => {
    const { t } = useTranslationSSR();
    const { categories, patternsByCategory } = usePatternsContentManager();

    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const listBoxButtonRef = useRef<HTMLButtonElement>(null);
    const [loading, setLoading] = useState(true);

    const handleDropdownChange = (optionItem: string) => {
        if (optionItem !== ALL_PATTERNS) {
            setSelectedCategory(optionItem);
            handleSelectedCategory(optionItem);
        }
    };

    useEffect(() => {
        if (loading) {
            listBoxButtonRef.current?.focus();
        }
    }, [loading]);

    useEffect(() => {
        if (categories.length > 0) {
            setSelectedCategory(ALL_PATTERNS);
            setLoading(false);
        }
    }, [categories, categories.length]);

    return (
        <>
            {loading ? (
                <PatternListSkeleton />
            ) : (
                <Listbox
                    aria-label={t(patternMessage.selectPattern.id)}
                    fullWidth
                    onChange={handleDropdownChange}
                    value={selectedCategory}
                    defaultValue={ALL_PATTERNS}
                >
                    <ListboxButton
                        aria-label={t(patternMessage.selectPattern.id)}
                        ref={listBoxButtonRef}
                        className={styles.listboxButton}
                    />
                    <ListboxPopover>
                        <ListboxList className={styles.listboxList}>
                            <ListboxOption
                                aria-label={t(patternMessage.allPattern.id)}
                                key={ALL_PATTERNS}
                                className={styles.listOption}
                            >
                                {t(patternMessage.allPattern.id)}
                            </ListboxOption>
                            {
                                categories.map(category => {
                                    /**
                                     * There are certain categories in the list that
                                     * do not have patterns associated with them.
                                     * Showing only categories that have patterns associated with them.
                                     */
                                    const patterns = patternsByCategory[category.localizedValue];
                                    return (
                                        patterns && (
                                            <ListboxOption key={category.localizedValue} className={styles.listOption}>
                                                {category.localizedValue}
                                            </ListboxOption>
                                        )
                                        /**
                                         * Forcefully setting the returning array to any because TypeScript is not allowing
                                         * it to be Element[].
                                         * @see - https://gitlab.com/vistaprint-org/merchandising-technology/visage/swan/-/blob/main/src/react/components/listbox/basic-listbox.component.tsx?ref_type=heads#LC31
                                         */
                                    );
                                }) as unknown as any
                            }
                        </ListboxList>
                    </ListboxPopover>
                </Listbox>
            )}
        </>
    );
});
