import { buildContentDiscoveryZoneActiveDialogTypes } from "@internal/utils-active-dialog";
import type { PhotoProps } from "react-photo-gallery";
import { DSS } from "@vp/types-ddif";
export const AITemplatesContentPanelTypes = buildContentDiscoveryZoneActiveDialogTypes(["design"]);

export type AITemplatesUseCase = "promotionalProducts";
export type AITemplatesColorType = "singleColor" | "multiColor";

export type SimtagSearchInput = {
    input: {
        text: string;
        mode: string;
        storageId: string;
        topK: number;
        colorType: "singleColor" | "multiColor";
        hero: boolean;
        locale: string;
        targetLocale: string;
    };
};

export interface ImagemindReportingInput {
    input: {
        id?: string;
        feedback: {
            storageId: string;
            colorType: AITemplatesColorType;
            locale: string;
            hero: boolean;
            usecase: AITemplatesUseCase;
            productType?: string;
            searchedKeyword?: string;
            recentTemplate: boolean;
            productKey: string;
            pageId: string;
            userId: string;
            userActivity: {
                assetsClicked: string[];
                selectedAssetsForNextPage: string[];
                numberOfAssetsViewed: number;
                assetsRemoved: string[];
            };
        };
    };
}

export type AITemplate = {
    id: string;
    metadata: {
        document?: DSS.DesignDocument;
        previewUrl: string;
        cimDocUrl: string;
        documentReference: string;
    };
    confidence: number;
    decoTech: AITemplatesColorType;
    jobId: string;
    keyword: string;
    isRecentlyUsed?: boolean;
};

export interface ImageData {
    src: string;
    width: number;
    height: number;
    alt: string;
}
export type PhotoItem = PhotoProps<ImageData>;

export interface PlaceholderConfig {
    [key: string]: {
        label: string;
        purpose: string;
    };
}

interface InspireGenerateText {
    content: string;
    type: "primary" | "secondary";
    isPlaceholder: boolean;
}

interface InspireGeneratePayload {
    texts: InspireGenerateText[];
    surfaceSpecificationUrl: string;
    substrateColor: string;
    useImage: boolean;
    colorPalette?: {
        primary: string;
        secondary: string;
    };
    themeQuery?: string;
    generateOptions?: {
        iconCollections: string[];
    };
}

interface InspireGenerateInput {
    inspireGeneratePayload: InspireGeneratePayload;
    locale: string;
}

interface InspireGenerateCimDocMetadata extends DSS.Metadata {
    artworkGeneration: {
        assets: {
            layoutName: string;
            colorPalette: {
                primary: string;
                secondary: string;
            };
            iconUrl?: string;
        };
    };
}

export interface InspirationGenerateCimDoc extends DSS.DesignDocument {
    metadata: InspireGenerateCimDocMetadata;
}

type InspireGenerateDesignInfo = {
    id: string;
    metadata: {
        document: DSS.DesignDocument;
        previewUrl: string;
        consumerSegment: string;
        locale: string;
    };
    confidence: number;
};

export interface InspireGenerateOutput {
    results: InspireGenerateDesignInfo[];
    id?: string;
}

export type GenerateDesignsType = (data: InspireGenerateInput) => Promise<InspireGenerateOutput>;
