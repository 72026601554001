import { CimDoc, ItemReference, Position } from "@design-stack-vista/cdif-types";
import { getNewIdAndNextZIndex } from "@design-stack-vista/cimdoc-state-manager";
import { ColorOverrides } from "@internal/utils-color";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";

const VECTOR_URL = "https://items.documents.cimpress.io/v1/vector";

interface AddVectorItemProps {
    panelId: string;
    data: {
        subpanelUrl: string;
        colorOverrides: ColorOverrides;
    };
    position: Position;
}

export const addVectorItem = (cimDoc: CimDoc, { panelId, data, position }: AddVectorItemProps) => {
    const panel = cimDoc.document.panels.find(p => p.id === panelId);

    if (!panel) {
        throw new Error(`addVectorItem: no panel found with id: ${panelId}`);
    }

    const { zIndex, id } = getNewIdAndNextZIndex(cimDoc, panelId);
    const itemReferenceItem: ItemReference = {
        id,
        type: ItemReferenceTypes.VECTOR_PANEL,
        url: VECTOR_URL,
        data,
        zIndex,
        position
    };

    if (panel.itemReferences) {
        panel.itemReferences.push(itemReferenceItem);
    } else {
        panel.itemReferences = [itemReferenceItem];
    }

    return { id: itemReferenceItem.id };
};
