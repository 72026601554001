import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Icon } from "@vp/swan";
import { Tooltip } from "@internal/ui-tooltip";
import styles from "./ItemTeamsNameInfoTooltip.module.scss";

const messages = defineMessages({
    placeholderTooltipText: {
        id: "sims.teamsName.placeholderTooltipText",
        defaultMessage:
            "You will add each name in the next step. This is a placeholder for positioning and styling the name on the item.",
        description: {
            note: "Tooltip for text placeholder"
        }
    },
    placeholderTooltipTitle: {
        id: "sims.teamsName.placeholderTooltipTitle",
        defaultMessage: "Teams item tooltip"
    }
});

export const ItemTeamsNameInfoTooltip = observer(() => {
    const { t } = useTranslationSSR();

    const handlePointerDown = (event: React.PointerEvent) => {
        // stop other design layers from capturing the pointer
        event.stopPropagation();
    };

    const handlePointerUp = (event: React.PointerEvent) => {
        // stop other design layers from capturing the pointer
        event.stopPropagation();
    };

    return (
        <div className={classNames(styles.infoContainer)}>
            <Tooltip
                content={t(messages.placeholderTooltipText.id)}
                triggerAriaLabel={t(messages.placeholderTooltipTitle.id)}
                placement="top"
                tooltipContainerClassName={styles.infoTooltip}
                viewportPadding={5}
            >
                <button
                    className={classNames(styles.infoButton)}
                    onPointerDown={handlePointerDown}
                    onPointerUp={handlePointerUp}
                >
                    <Icon size="16p" iconType="info" />
                </button>
            </Tooltip>
        </div>
    );
});
