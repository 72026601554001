import { getItemById, isItemReference } from "@design-stack-vista/cimdoc-state-manager";
import type { CimDoc } from "@design-stack-vista/cdif-types";
import { ColorGroup, getPatternUrl } from "@design-stack-vista/pattern-sdk";

export interface ApplyPatternColorParameters {
    itemId: string;
    newColors: ColorGroup;
    url: string;
}

/**
 * Generate a new URL from the new colors and update item in the cimdoc
 *
 * @param cimDoc
 * @param ApplyPatternColorParameters
 */
export function applyPatternColor(cimDoc: CimDoc, { itemId, newColors, url }: ApplyPatternColorParameters) {
    const item = getItemById(cimDoc, itemId);

    const newPatternUrl = getPatternUrl(url, newColors);

    if (isItemReference(item)) {
        item.data.image.printUrl = newPatternUrl;
        item.data.image.previewUrl = newPatternUrl;
    }
}
