import produce from "immer";
import {
    getImageInfoByClassification,
    ImageInfo,
    isSherbertAssetUrl,
    parseAssetUrl
} from "@design-stack-ct/assets-sdk";
import { getImageAsPromise } from "@design-stack-vista/utility-core";
import { CimDoc, DclMetadataItem, ImageItem } from "@design-stack-vista/cdif-types";
import type { PartialImageItem } from "../editorUI/imageContentPanel/useAddImage/types";

/**
 * Returns true if the file type matches an image that can be previewed client-side with Fusion
 */
export function isFileFusionCompatible(file: File) {
    return ["image/jpeg", "image/png", "image/bmp"].includes(file.type);
}

export function getImageFileAsPromise(file: File) {
    const url = URL.createObjectURL(file);
    return getImageAsPromise(url);
}

/**
 * Given a File, generates a `PartialImageItem` with a client-side ObjectURL along with the imageInfo required for deciding a placementStrategy in some add image cases
 * @note Adding an item to the design with this URL will leave the document in an "unsafe" state until it gets replaced with a proper URL
 */
export async function buildLocalImage(file: File): Promise<{ partialImage: PartialImageItem; imageInfo?: ImageInfo }> {
    // `src` returned from this function is a "blob:" ObjectURL
    const { width, height, src } = await getImageFileAsPromise(file);

    // Need to include locally retrieved imageInfo for the case of replacing an image, as that flow relies on "isLogo" to decide a placementStrategy
    let imageInfo: ImageInfo | undefined;
    try {
        // Unfortunately this can only be done for supported raster types or assets-sdk will throw an error
        imageInfo = await getImageInfoByClassification(file);
    } catch (error) {
        imageInfo = undefined;
    }

    return {
        partialImage: {
            originalSourceUrl: src,
            previewUrl: src,
            dimensions: { width, height },
            pageNumber: 1
        },
        imageInfo
    };
}

/** Returns true if an image item is a client-side image (uses a "blob:" url) */
function isClientSideImage(image: ImageItem): boolean {
    return [image.originalSourceUrl, image.previewUrl, image.printUrl].some(url => url?.startsWith("blob:"));
}

/** Returns a deep copy of the provided CimDoc with any client-side images filtered out */
export function filterNonServerRenderableItems(cimDoc: CimDoc): CimDoc {
    return produce(cimDoc, draft => {
        draft.document.panels.forEach(panel => {
            // Remove any images from the document that are client-side images resulting from instant uploads
            // eslint-disable-next-line no-param-reassign
            panel.images = panel.images?.filter(image => !isClientSideImage(image)) ?? [];
        });
    });
}

function getAssetIdFromUrl(url: string): string | undefined {
    try {
        return parseAssetUrl(url).id;
    } catch {
        return undefined;
    }
}

export function getSherbertAssetId(imageItem: ImageItem, designMetadata?: DclMetadataItem): string | undefined {
    const metadataRequestId = designMetadata?.requestId as string | undefined;
    const assetIdFromUrl =
        !!imageItem.originalSourceUrl &&
        isSherbertAssetUrl(imageItem.originalSourceUrl) &&
        getAssetIdFromUrl(imageItem.originalSourceUrl);
    if (assetIdFromUrl) {
        return assetIdFromUrl;
    }
    const isSherbertUrl = !!imageItem.originalSourceUrl && isSherbertAssetUrl(imageItem.originalSourceUrl);
    const noOriginalSourceUrl = !imageItem.originalSourceUrl;

    // if we can't be sure it's a sherbert url return the request id just in case
    // also if it's a sherbert url but parsing failed, return the request id
    if (isSherbertUrl || noOriginalSourceUrl) {
        return metadataRequestId;
    }

    return undefined;
}
