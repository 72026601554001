/**
 * Reference - {@link https://gitlab.com/Cimpress-Technology/artwork-design/blue-team/interactive-design-engine-core/-/blob/v2.3.0/packages/interactive-design-engine-core/src/helpers/getItemStatePreviewUrl.ts}
 */

import { buildItemPreviewUrl } from "@design-stack-ct/preview-sdk";
import type { Item } from "@design-stack-vista/cdif-types";
import { getRenderingPixelValue } from "@design-stack-vista/utility-core";
import { ItemState, ReadOnlyCimDocProperties, getRootPanel } from "@design-stack-vista/cimdoc-state-manager";

export interface GetItemStatePreviewUrlParams<T extends Item> {
    itemState: ItemState<T>;
    tenant: string;
    /**
     * CDIF version and fontRepository URL of the document on which this item lives.
     */
    cimDocProperties: Pick<ReadOnlyCimDocProperties, "version" | "fontRepositoryUrl">;
    /**
     * The zoom multiplier used by the design experience
     */
    zoom: number;
    /**
     * A completely separate model to use instead of the one in `itemState.model`
     */
    previewModelOverride?: T;
    /**
     * Any overrides you might want to apply to the item's model
     */
    itemPropertyOverrides?: Partial<T>;
}

/**
 * We want identical items to always generate the same URL regardless of rendering-agnostic layout properties.
 * This function strips those properties from an item to ensure requests will hit any previously cached preview urls.
 */
export function prepareItemForCachedRequests(model: Item) {
    const item = { ...model, id: "", rotationAngle: "0", zIndex: 0 };

    if ("position" in item) {
        item.position = {
            ...item.position,
            x: "0mm",
            y: "0mm"
        };
    }

    return item;
}

/**
 * This function is used for building a transient previewUrl based on an item's current CDIF properties.
 * This can potentially return a URL exceeding the 7000 character limit even after compressing the CimDoc data,
 * and so may need to be shortened (this can be done with the getItemStateDocument helper function)
 */
export function getItemStatePreviewUrl<T extends Item>({
    itemState,
    tenant,
    cimDocProperties,
    zoom,
    previewModelOverride,
    itemPropertyOverrides
}: GetItemStatePreviewUrlParams<T>) {
    const { version, fontRepositoryUrl } = cimDocProperties;

    const { width, height, decorationTechnology } = getRootPanel(itemState).panelProperties;

    const cimDocInfo = {
        version,
        fontRepositoryUrl,
        width,
        height,
        decorationTechnology
    };

    /*
        This assumes the originating unit space is converted to pixel value by
        doing original unit space / target pixel size. Hence the target pixel size * zoom
        results in the original unit space. If we need to add a additional scale factor when
        converting, this will need to reflect that scale factor.
    */

    return buildItemPreviewUrl({
        item: prepareItemForCachedRequests(previewModelOverride || itemState.model),
        cimDocInfo,
        itemPropertyOverrides,
        renderSize: getRenderingPixelValue(zoom),
        tenant
    });
}
