import React from "react";
import { SkeletonText, SkeletonTextLine } from "@vp/swan";
import styles from "./CategorySkeletonLoader.module.scss";

interface Props {
    quantity: number;
}

export const CategorySkeletonLoader = ({ quantity }: Props) => {
    return (
        <>
            {[...Array(quantity)].map((el, index) => (
                <SkeletonText key={index} className={styles.skeletonLoaderContainer}>
                    <SkeletonTextLine className={styles.categoryNameSkeleton} width="standard" mb={"4"} />
                    <SkeletonTextLine className={styles.categoryPatternSkeleton} />
                </SkeletonText>
            ))}
        </>
    );
};
