import React from "react";
import type { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";
import { TeamsIcon } from "@internal/ui-studio-chrome";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";

const messages = defineMessages({
    teamsNameButton: {
        id: "sims.teamsName.contentDiscoveryZone.teamsNameButton",
        defaultMessage: "Names",
        description: {
            note: "Label for the groups and teams button"
        }
    }
});
export const TeamsNameContentDiscoveryZoneButton: ContentDiscoveryZoneButton = ({ children }) => {
    const { t } = useTranslationSSR();

    return children({
        icon: <TeamsIcon aria-hidden="true" />,
        label: t(messages.teamsNameButton.id)
    });
};
