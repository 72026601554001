import { action, computed, flow, flowResult, IReactionDisposer, makeObservable, observable, reaction } from "mobx";
import { DesignState, getPanelDimensionsInMmValue, ItemState } from "@design-stack-vista/cimdoc-state-manager";
import { BaseExtension } from "@design-stack-vista/interactive-design-engine-core";
import { Dimensions, ItemReference } from "@design-stack-vista/cdif-types";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
import {
    ColorGroup,
    getColorsFromPatternUrl,
    getDefaultPatternPosition,
    getPatternOffsetPercentage,
    getSvgDataWithColorOverride,
    urlIsSvg
} from "@design-stack-vista/pattern-sdk";
import { parseQueryParams } from "@design-stack-vista/utility-core";

type CancellablePromise<T> = Promise<T> & { cancel(): void };
function catchCancellation(promise: CancellablePromise<void>) {
    promise.catch(() => {});
    return promise;
}

/**
 * Extension that provides the Pattern data for selected pattern.
 */

export const defaultPatternToPanelDimensionRatio = 0.3;

export class PatternExtension extends BaseExtension {
    protected declare designState: ItemState<ItemReference>;

    @observable dimensionRatio = 0.3;
    @observable rotationAngle = 0;
    @observable xOffsetRatio = 0;
    @observable yOffsetRatio = 0;
    @observable isInEditingMode = false;

    @observable svgData?: string;
    @observable fileUrl?: string;
    @observable isUrlSvg = true;

    private pendingSvgDataRead: CancellablePromise<void>;

    private disposeImagePositionHeightReaction: IReactionDisposer;
    private disposeItemPositionXReaction: IReactionDisposer;
    private disposeItemPositionYReaction: IReactionDisposer;
    private disposeImageRotationReaction: IReactionDisposer;
    private disposeImageReplaceReaction: IReactionDisposer;

    static supports(designState: DesignState): boolean {
        return designState.isItemReference() && designState.model.type === ItemReferenceTypes.TILE_IMAGE;
    }

    constructor(designState: DesignState) {
        super(designState);
        makeObservable(this);

        this.disposeImageReplaceReaction = reaction(
            () => this.designState.model.data.image.previewUrl,
            () => {
                if (this.pendingSvgDataRead) {
                    this.pendingSvgDataRead.cancel();
                }
                this.pendingSvgDataRead = catchCancellation(flowResult(this.extractSvgData()));
                catchCancellation(flowResult(this.checkUrl()));
            }
        );

        this.disposeImagePositionHeightReaction = reaction(
            () => this.designState.model.data.image.position.height,
            () => {
                this.setDimensionRatio(this.patternToPanelDimensionRatio);
            }
        );
        this.disposeItemPositionXReaction = reaction(
            () => this.designState.model.position.x,
            () => {
                this.setXOffsetRatio(100 - this.patternXOffsetPercentage);
            }
        );
        this.disposeItemPositionYReaction = reaction(
            () => this.designState.model.position.y,
            () => {
                this.setYOffsetRatio(100 - this.patternYOffsetPercentage);
            }
        );
        this.disposeImageRotationReaction = reaction(
            () => this.designState.model.rotationAngle,
            () => {
                this.setRotationAngle(this.patternRotationAngle);
            }
        );
        this.pendingSvgDataRead = catchCancellation(flowResult(this.extractSvgData()));
        catchCancellation(flowResult(this.checkUrl()));

        this.setDimensionRatio(this.patternToPanelDimensionRatio);
        this.setXOffsetRatio(100 - this.patternXOffsetPercentage);
        this.setYOffsetRatio(100 - this.patternYOffsetPercentage);
        this.setRotationAngle(this.patternRotationAngle);
    }

    @flow
    private *extractSvgData() {
        try {
            const selectedPatternUrl = this.designState.model.data.image.previewUrl;
            const hasColorInUrl = !!parseQueryParams(selectedPatternUrl).colorGroup;

            let colorGroup: ColorGroup = {};
            if (hasColorInUrl) {
                colorGroup = getColorsFromPatternUrl(selectedPatternUrl);
            }

            const uploadFileUrl = parseQueryParams(selectedPatternUrl).fileUrl || selectedPatternUrl;
            this.fileUrl = uploadFileUrl;
            const svgData: string = yield getSvgDataWithColorOverride(uploadFileUrl, colorGroup);

            this.svgData = svgData;
        } catch {
            this.svgData = undefined;
        }
    }

    @flow
    private *checkUrl() {
        const url = this.designState.model.data.image.previewUrl;
        this.isUrlSvg = yield urlIsSvg(url);
    }

    @computed
    get patternToPanelDimensionRatio(): number {
        const designPanel = this.designState.parent;
        if (designPanel.isPanelState()) {
            const { height, width } = getPanelDimensionsInMmValue(designPanel.panelProperties);
            const ratio: number =
                (parseFloat(this.designState.model.data.image.position.height) * 100) / Math.min(height, width);
            return ratio / 100;
        }
        return defaultPatternToPanelDimensionRatio;
    }

    @computed
    get patternRotationAngle(): number {
        const { rotationAngle } = this.designState.model;
        if (rotationAngle) {
            return parseFloat(rotationAngle);
        }
        return 0;
    }
    @computed
    get defaultPatternPosition(): { x: number; y: number; dimension: number } {
        const designPanel = this.designState.parent;
        if (designPanel.isPanelState()) {
            return getDefaultPatternPosition(designPanel.panelProperties);
        }
        return {
            dimension: 0,
            x: 0,
            y: 0
        };
    }

    @computed
    get patternXOffsetPercentage(): number {
        const designPanel = this.designState.parent;
        if (designPanel.isPanelState()) {
            return getPatternOffsetPercentage(
                designPanel.panelProperties,
                this.designState.model.position.x,
                this.patternToPanelDimensionRatio,
                this.defaultPatternPosition.x
            );
        }
        return 0;
    }

    @computed
    get tileDimensions(): Dimensions {
        return this.designState.model.data.image.position;
    }

    @computed
    get patternYOffsetPercentage(): number {
        const designPanel = this.designState.parent;
        if (designPanel.isPanelState()) {
            return getPatternOffsetPercentage(
                designPanel.panelProperties,
                this.designState.model.position.y,
                this.patternToPanelDimensionRatio,
                this.defaultPatternPosition.y
            );
        }
        return 0;
    }

    @action.bound
    async setDimensionRatio(ratio: number) {
        this.dimensionRatio = ratio;
    }

    @action.bound
    async setXOffsetRatio(ratio: number) {
        this.xOffsetRatio = ratio;
    }

    @action.bound
    async setYOffsetRatio(ratio: number) {
        this.yOffsetRatio = ratio;
    }

    @action.bound
    async setRotationAngle(angle: number) {
        this.rotationAngle = angle;
    }

    @action.bound
    async setIsInEditingMode(isEditing: boolean) {
        this.isInEditingMode = isEditing;
    }

    dispose() {
        super.dispose();

        this.disposeImagePositionHeightReaction();
        this.disposeItemPositionXReaction();
        this.disposeItemPositionYReaction();
        this.disposeImageRotationReaction();
        this.disposeImageReplaceReaction();
    }
}
