import { useCallback } from "react";
import { DesignTileCallback } from "@shared/utils/DesignPanel";
import { documentSourceTypeMap } from "@internal/data-access-design-specifications-service";
import { useErrors } from "@internal/utils-errors";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useDesignDialog } from "../DesignDialogProvider";
import { applyDesignOption } from "../utils";
import { fireUserInteractionTrackingEvent } from "../../../utils/Tracking";
import { useGetPanelSourceExtension } from "./useGetPanelSourceExtension";

const generateFireInteractionTimedEvent = (dialogCanvasName: string) => {
    const startTime = performance.now();
    return () =>
        fireUserInteractionTrackingEvent({
            eventName: `Remove Design`,
            timing: performance.now() - startTime,
            extraData: {
                side: dialogCanvasName
            }
        });
};

export const useSelectBlankCallback = (deleting?: boolean) => {
    const { getDocumentWithNonServerRenderableItems, loadNewDesign, fireInteractionTimedEvent, dialogCanvasName } =
        useDesignDialog();
    const getPanelSourceExtension = useGetPanelSourceExtension();
    const eventToFire = deleting ? generateFireInteractionTimedEvent(dialogCanvasName) : fireInteractionTimedEvent;
    const { handleError } = useErrors();
    const productAndProjectState = useProductAndProjectStateManager();
    /**
     * This function is a callback that when called, will convert the panel with panelName to a blank canvas.
     * @param panelName - panel to be converted
     * @param resetting - are we resetting the panel (meaning are we going to ignore the saved panel) NOTE: For blank, this probably doesn't do nothing
     * @returns callback
     */
    return useCallback<DesignTileCallback>(
        ({ panelName, resetting }) => {
            return async (dispatch: any, getState: any) => {
                const { surfaceUpsells } = getState();
                const surfaceUpsell = surfaceUpsells[panelName];
                const panelSourceExtension = getPanelSourceExtension(panelName);
                if (!panelSourceExtension) {
                    return undefined;
                }
                return applyDesignOption({
                    dispatch,
                    productAndProjectState: productAndProjectState.data,
                    updateProductAndProjectStateConfiguration: productAndProjectState.updateConfiguration,
                    panelName,
                    resetting,
                    newDocumentSourceType: documentSourceTypeMap.NONE,
                    newDocumentSourceData: "",
                    newOptions: surfaceUpsell
                        ? {
                              [surfaceUpsell.optionName]: surfaceUpsell.blankOption
                          }
                        : undefined,
                    fireInteractionTimedEvent: eventToFire,
                    getDocument: getDocumentWithNonServerRenderableItems,
                    panelSourceExtension,
                    loadNewDesign,
                    handleError
                });
            };
        },
        [
            getPanelSourceExtension,
            productAndProjectState.data,
            productAndProjectState.updateConfiguration,
            eventToFire,
            getDocumentWithNonServerRenderableItems,
            loadNewDesign,
            handleError
        ]
    );
};
