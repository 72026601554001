import React, { PropsWithChildren } from "react";

interface Props {
    condition: boolean;
}

/**
 * This component is used to conditionally render its children.
 */
export const RenderWhen: React.FC<PropsWithChildren<Props>> = ({ condition, children }) => (
    <> {condition ? children : null} </>
);
