import type { SimInterface } from "@internal/sim-framework";
import { CutlineContentDiscoveryZoneButton } from "./contentDiscoveryZone/CutlineContentDiscoveryZoneButton";
import { name } from "./simName";
import defaultTranslations from "./i18n/en-US.json";
import { cutlineActiveDialogTypes } from "./cutlineGenerationContentSymbols";

const cutlineGenerationSim = {
    name,
    core: {
        defaultTranslations,
        extensions: [],
        contentDiscoveryZone: {
            Cutline: {
                activeDialogType: cutlineActiveDialogTypes["cutline-generation"],
                component: CutlineContentDiscoveryZoneButton
            }
        }
    },
    deferredLoader: () => import("./index.deferred")
} satisfies SimInterface<"ContentDiscoveryZone">;

export default cutlineGenerationSim;
