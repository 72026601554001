import { FlexBox } from "@vp/swan";
import React from "react";
import { observer } from "mobx-react-lite";
import { DeletePattern } from "./DeletePattern";
import { PatternCustomization } from "./PatternCustomization";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { useDesignEngine, useActivePanel, getOptionalExtension } from "@design-stack-vista/core-features";
import { PatternItemExtension } from "@pattern-sim/extensions";
import styles from "./PatternAction.module.scss";

export const PatternAction = observer(() => {
    const { isSmall } = useStudioLayout();
    const designEngine = useDesignEngine();
    const { activePanel } = useActivePanel();

    const patternItemExtension = activePanel && getOptionalExtension(designEngine, activePanel, PatternItemExtension);
    return (
        <>
            {isSmall && patternItemExtension?.patternItem && patternItemExtension?.patternItem.iid && (
                <FlexBox flexDirection="row" className={styles.patternAction} data-testid="pattern-action">
                    <PatternCustomization />
                    <DeletePattern />
                </FlexBox>
            )}
        </>
    );
});
