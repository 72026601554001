export interface LogoData {
    image?: {
        contents?: string;
        url?: string;
    };
}

export const useCreateDefaultLogoData = (config: { initialBookendsLogo: LogoData }) => {
    return config.initialBookendsLogo;
};
