import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useAbTestContext, ExperimentData } from "@internal/ab-test-framework";
import { Events, useTrackingClient } from "@internal/utils-tracking";

type ContextData = {
    isMobileImageUploadsABEnabled: boolean;
    trackImpressionForMobileImageUploads: () => void;
    trackExperimentInteraction: (eventDetail: string, experimentDetail: string | undefined) => void;
};

const context = createContext<ContextData | undefined>(undefined);

export function useMobileImageUploads() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context. This must be called within a MobileImageUploadsABProvider");
    }
    return result;
}

enum Variations {
    Control = "control",
    Test = "test"
}

export const mobileImageUploadsExperimentData: ExperimentData = {
    experimentKey: "qr_code_mobile_uploads_in_studio",
    experimentName: "QR Code Mobile Uploads in Studio",
    variations: Variations
};

export const MobileImageUploadsABProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { Provider } = context;
    const ABTest = useAbTestContext();
    const trackingClient = useTrackingClient();
    const { experimentKey } = mobileImageUploadsExperimentData;
    const [isEnabled, setIsEnabled] = useState(false);

    const trackImpressionForMobileImageUploads = useCallback(() => {
        if (!experimentKey || !ABTest) {
            return;
        }

        const trackImpressionIfInVariant = async (variation: Variations) => {
            const { isExperimentUsingVariation, trackImpression } = ABTest;
            const res = await isExperimentUsingVariation(experimentKey, variation);
            if (res) {
                trackImpression(experimentKey, variation);
                setIsEnabled(variation === Variations.Test);
            }
        };

        trackImpressionIfInVariant(Variations.Test);
        trackImpressionIfInVariant(Variations.Control);
    }, [ABTest, experimentKey]);

    const trackExperimentInteraction = useCallback(
        (eventDetail: string, experimentDetail = "") => {
            if (!experimentKey || !ABTest || !isEnabled || !trackingClient) {
                return;
            }

            trackingClient.track(Events.ExperimentClicked, {
                eventDetail,
                experimentDetail,
                experimentData: {
                    experimentId: experimentKey,
                    experimentName: experimentKey,
                    variationId: Variations.Test,
                    variationName: Variations.Test
                }
            });
        },
        [ABTest, experimentKey, isEnabled, trackingClient]
    );

    const contextObject = useMemo(
        () => ({
            isMobileImageUploadsABEnabled: isEnabled,
            trackImpressionForMobileImageUploads,
            trackExperimentInteraction
        }),
        [isEnabled, trackExperimentInteraction, trackImpressionForMobileImageUploads]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

MobileImageUploadsABProvider.displayName = "MobileImageUploadsABProvider";
