import { PatternPanelMode } from "@pattern-sim/shared";
import { action, makeObservable, observable } from "mobx";

// This store is used for keeping track of the original colors and url when the pattern applied the first time
// We can't retrieve these after changing the colors (which results in change in Url)
class PatternStore {
    @observable patternPanelMode: PatternPanelMode = PatternPanelMode.Add;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setPatternPanelMode(mode: PatternPanelMode) {
        this.patternPanelMode = mode;
    }
}

export const patternStore = new PatternStore();
