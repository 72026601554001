import type { ItemReference } from "@design-stack-vista/cdif-types";
import { ItemState, SubpanelState } from "@design-stack-vista/cimdoc-state-manager";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";

export const isPatternTypeReference = (item: ItemState | SubpanelState): item is ItemState<ItemReference> => {
    return item.isItemReference() && item.model.type === ItemReferenceTypes.TILE_IMAGE;
};

export enum PatternPanelMode {
    Add = "Add",
    Edit = "Edit"
}

export enum PatternEditTabsMode {
    Colors = "Colors",
    Theme = "Theme",
    Resize = "Resize"
}
