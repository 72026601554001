import { ColorOverrides } from "@internal/utils-color";

export function mergeColorOverrides(
    oldOverrides: ColorOverrides,
    newOverrides: ColorOverrides,
    keepOldValues: boolean = false
): ColorOverrides {
    const mergedOverrides = [];

    for (const oldOverride of oldOverrides) {
        const keys = Object.keys(oldOverride);
        if (keys.length === 1) {
            const oldKey = keys[0];
            const existingOverrideIndex = mergedOverrides.findIndex(
                mergedOverride => Object.keys(mergedOverride)[0] === oldKey
            );

            if (existingOverrideIndex !== -1) {
                if (keepOldValues) {
                    mergedOverrides[existingOverrideIndex][oldKey] = oldOverride[oldKey];
                }
            } else {
                mergedOverrides.push(oldOverride);
            }
        }
    }

    for (const newOverride of newOverrides) {
        const keys = Object.keys(newOverride);
        if (keys.length === 1) {
            const newKey = keys[0];
            const existingOverrideIndex = mergedOverrides.findIndex(
                mergedOverride => Object.keys(mergedOverride)[0] === newKey
            );

            if (existingOverrideIndex !== -1) {
                if (!keepOldValues) {
                    mergedOverrides[existingOverrideIndex][newKey] = newOverride[newKey];
                }
            } else {
                mergedOverrides.push(newOverride);
            }
        }
    }

    return mergedOverrides;
}
