import React from "react";

export const CircleCutlineIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M.75 16C.75 7.578 7.578.75 16 .75S31.25 7.578 31.25 16 24.422 31.25 16 31.25.75 24.422.75 16Z"
            stroke={"black"}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
