import React from "react";
import { SkeletonText, SkeletonTextLine } from "@vp/swan";
import styles from "./PatternDetailViewSkeleton.module.scss";

interface Props {
    quantity: number;
}

export const PatternDetailViewSkeleton = ({ quantity }: Props) => {
    return (
        <SkeletonText className={styles.detailViewPatternSkeleton}>
            {[...Array(quantity)].map((el, index) => (
                <SkeletonTextLine key={index} className={styles.detailViewPattern} width="standard" mb={"4"} />
            ))}
        </SkeletonText>
    );
};
