import { action, makeObservable, observable } from "mobx";
import { VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import type { InstantUploadData } from "@internal/utils-assets";

export class InstantUploadStore {
    /**
     * maps a client-side upload to it's asset
     * this is used in case an item somehow isn't represented or updated by the currentUploadsMap
     * it can still update to the completed upload via this method
     */
    @observable
    instantUploadMap: Map<string, Promise<VistaAsset | void>>;

    /** Maps an item id to instant upload data */
    @observable
    currentUploadsMap: Map<string, InstantUploadData>;

    constructor() {
        this.instantUploadMap = new Map();
        this.currentUploadsMap = new Map();

        makeObservable(this);
    }

    /** Register an item for instant uploads */
    @action.bound
    setCurrentUpload(itemID: string, instantUploadData: InstantUploadData) {
        this.currentUploadsMap.set(itemID, instantUploadData);
        this.instantUploadMap.set(instantUploadData.temporaryUrl, instantUploadData.assetPromise);
    }

    /** When an item has completed the instant upload process it can be removed */
    @action.bound
    removeCurrentUpload(itemID: string) {
        this.currentUploadsMap.delete(itemID);
    }
}
