import { useCallback } from "react";
import { useAvailableDesignEngine } from "@design-stack-vista/core-features";
import { isTeamsNameItemReference } from "../utils";

export function useGetTeamsNameItems() {
    const designEngine = useAvailableDesignEngine();
    return useCallback(() => {
        return designEngine?.cimDocStore.panels.flatMap(({ items }) => items).filter(isTeamsNameItemReference) || [];
    }, [designEngine]);
}
