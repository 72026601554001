import React, { useState } from "react";
import { TabContent, TabHeader, Tabs, FlexBox, TabsContents, TabsHeaders } from "@vp/swan";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useTranslationSSR } from "@vp/i18n-helper";
import { PatternEditTabsMode, PatternPanelMode } from "../../shared/patternsTypeGuard";
import { patternStore } from "../../extensions";
import { EditColors } from "../colors";
import { ThemesColorPanel } from "../theme";
import { patternMessage } from "@pattern-sim/shared";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { Resize } from "../resizePattern";
import styles from "./SheetTabs.module.scss";

export const SheetTabs = observer(() => {
    const { t } = useTranslationSSR();
    const [selectedTabId, setSelectedTabId] = useState(PatternEditTabsMode.Colors);
    const { setPatternPanelMode } = patternStore;
    const trackingClient = useTrackingClient();

    const handleTabChange = (tabId: string | null) => {
        setSelectedTabId(tabId as PatternEditTabsMode);
        setPatternPanelMode(PatternPanelMode.Edit);
        const selectedTabId = tabId?.toLowerCase();
        trackingClient.track(Events.DesignToolUsed, {
            eventDetail: `Pattern ${selectedTabId} clicked`
        });
    };

    const TabContents = {
        resize: {
            tabId: PatternEditTabsMode.Resize,
            tabTitle: t(patternMessage.resizeTab.id),
            tabContents: <Resize />
        },
        theme: {
            tabId: PatternEditTabsMode.Theme,
            tabTitle: t(patternMessage.themeTab.id),
            tabContents: <ThemesColorPanel />
        },
        colors: {
            tabId: PatternEditTabsMode.Colors,
            tabTitle: t(patternMessage.colorsTab.id),
            tabContents: <EditColors />
        }
    };

    const PatternEditTabsContent = Object.values(TabContents);

    return (
        <FlexBox flexDirection="column" paddingTop={0} paddingBottom={"5"} paddingLeft={"5"} paddingRight={"5"}>
            <Tabs
                selectedTabId={selectedTabId}
                defaultSelectedTabId={PatternEditTabsMode.Colors}
                onRequestTabChange={handleTabChange}
            >
                <TabsHeaders>
                    {PatternEditTabsContent.map(({ tabTitle, tabId }, index) => (
                        <TabHeader key={index} tabId={tabId} aria-label={tabTitle}>
                            {tabTitle}
                        </TabHeader>
                    ))}
                </TabsHeaders>
                <TabsContents pt={"4"}>
                    {PatternEditTabsContent.map(({ tabId, tabContents }, index) => (
                        <TabContent key={index} tabId={tabId}>
                            <div
                                className={classNames(styles.patternTabsContents, {
                                    [styles.themeTabsContents]: selectedTabId === PatternEditTabsMode.Theme
                                })}
                            >
                                {tabContents}
                            </div>
                        </TabContent>
                    ))}
                </TabsContents>
            </Tabs>
        </FlexBox>
    );
});
