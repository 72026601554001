import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { FlexBox, H1, SelectedValue, Typography, Visible } from "@vp/swan";
import {
    useCutlineConfiguration,
    CutlineShapeType,
    useGetCutlineMetadata,
    type ApplyCutlineItemsArgs,
    useApplyCutlineItems,
    useTransferUploadToSherbert
} from "@internal/feature-cutline-generation";
import { getActivePanel, useDesignEngine } from "@design-stack-vista/core-features";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ContentBox, ContentBoxButton, ContentBoxItem } from "@vp/diy-editor-ui";
import {
    CircleCutlineIcon,
    CustomCutlineIcon,
    RectangleCutlineIcon,
    RoundedRectangleCutlineIcon
} from "./CutlineIcons";
import type { DispatchToast } from "@internal/utils-detail-zone";
import styles from "./CutlineGenerationPanel.module.scss";

export const messages = defineMessages({
    panelTitle: {
        defaultMessage: "Cutline",
        id: "studio.sims.cutlineGeneration.cutlinePanel.title"
    },
    panelDescription: {
        defaultMessage: "Select the shape for your sticker.",
        id: "studio.sims.cutlineGeneration.cutlinePanel.description"
    },
    rectangle: {
        defaultMessage: "Rectangle",
        id: "studio.sims.cutlineGeneration.cutlinePanel.shapeSelector.shapes.rectangle"
    },
    circle: {
        defaultMessage: "Circle",
        id: "studio.sims.cutlineGeneration.cutlinePanel.shapeSelector.shapes.circle"
    },
    roundedRectangle: {
        defaultMessage: "Rounded Rectangle",
        id: "studio.sims.cutlineGeneration.cutlinePanel.shapeSelector.shapes.roundedRectangle"
    },
    custom: {
        defaultMessage: "Custom",
        id: "studio.sims.cutlineGeneration.cutlinePanel.shapeSelector.shapes.custom"
    }
});

export const CutlineGenerationPanel = observer(({ dispatchToast }: { dispatchToast: DispatchToast }) => {
    const designEngine = useDesignEngine();
    const { cutlineShape, setCutlineShape } = useCutlineConfiguration();
    const { applyCutlineItems } = useApplyCutlineItems({ dispatchToast });
    const { cutlineShape: metadataCutlineShape, imageVariantType, backgroundRemovedSuccess } = useGetCutlineMetadata();
    const { t } = useTranslationSSR();

    // images from Sticker DEX need to be reuploaded to Sherbert
    // and added back to the document for cutline shape and background composition panels to work
    useTransferUploadToSherbert({ dispatchToast });

    useEffect(() => {
        if (metadataCutlineShape !== cutlineShape) {
            setCutlineShape(metadataCutlineShape);
        }
    }, [metadataCutlineShape, setCutlineShape, cutlineShape]);

    const updateSelectedValue = (selectedValue: SelectedValue) => {
        if (selectedValue === cutlineShape) {
            return;
        }

        setCutlineShape(selectedValue as CutlineShapeType);
        const panelId = getActivePanel(designEngine)?.id;

        const applyCutlineItemsInput: ApplyCutlineItemsArgs = {
            panelId,
            cutlineShape: selectedValue as CutlineShapeType,
            imageData: {
                imageVariantType,
                cutlineBaseImageVariantType:
                    selectedValue === "custom" && backgroundRemovedSuccess && imageVariantType === "original"
                        ? "saliencyMask"
                        : imageVariantType
            }
        };

        applyCutlineItems(applyCutlineItemsInput);
    };

    return (
        <FlexBox
            flexDirection="column"
            gap={"4"}
            paddingX={{ xs: "6", sm: 0 }}
            paddingBottom={{ xs: "6", sm: 0 }}
            className={styles.cutlineGridWrapper}
        >
            <Visible sm md lg xl>
                <H1 fontSize="large" fontWeight="bold" my={0}>
                    {t(messages.panelTitle.id)}
                </H1>
            </Visible>
            <Typography fontSize={"small"} textColor="subtle">
                {t(messages.panelDescription.id)}
            </Typography>
            <ContentBox
                allowDelete={false}
                allowMultiSelect={false}
                selectedValue={cutlineShape}
                onSelectedValueChange={updateSelectedValue}
                legend={t(messages.panelDescription.id)}
                className={styles.cutlineSelectorGrid}
            >
                <ContentBoxItem value="rectangle">
                    <ContentBoxButton aria-label={t(messages.rectangle.id)} backgroundColor="standard">
                        <RectangleCutlineIcon className={styles.cutlineIcon} width="100%" height="100%" />
                    </ContentBoxButton>
                    <Typography
                        className={styles.cutlineLabel}
                        textAlign="center"
                        fontSize="xsmall"
                        marginTop={"2"}
                        aria-hidden="true"
                    >
                        {t(messages.rectangle.id)}
                    </Typography>
                </ContentBoxItem>
                <ContentBoxItem value="circle">
                    <ContentBoxButton aria-label={t(messages.circle.id)} backgroundColor="standard">
                        <CircleCutlineIcon className={styles.cutlineIcon} width="100%" height="100%" />
                    </ContentBoxButton>
                    <Typography
                        className={styles.cutlineLabel}
                        textAlign="center"
                        fontSize="xsmall"
                        marginTop={"2"}
                        aria-hidden="true"
                    >
                        {t(messages.circle.id)}
                    </Typography>
                </ContentBoxItem>
                <ContentBoxItem value="roundedRectangle">
                    <ContentBoxButton aria-label={t(messages.roundedRectangle.id)} backgroundColor="standard">
                        <RoundedRectangleCutlineIcon className={styles.cutlineIcon} width="100%" height="100%" />
                    </ContentBoxButton>
                    <Typography
                        className={styles.cutlineLabel}
                        textAlign="center"
                        fontSize="xsmall"
                        marginTop={"2"}
                        aria-hidden="true"
                    >
                        {t(messages.roundedRectangle.id)}
                    </Typography>
                </ContentBoxItem>
                <ContentBoxItem value="custom">
                    <ContentBoxButton aria-label={t(messages.custom.id)} backgroundColor="standard">
                        <CustomCutlineIcon className={styles.cutlineIcon} width="100%" height="100%" />
                    </ContentBoxButton>
                    <Typography
                        className={styles.cutlineLabel}
                        textAlign="center"
                        fontSize="xsmall"
                        marginTop={"2"}
                        aria-hidden="true"
                    >
                        {t(messages.custom.id)}
                    </Typography>
                </ContentBoxItem>
            </ContentBox>
        </FlexBox>
    );
});
