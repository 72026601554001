import { getOptionalExtension, useActivePanel, useDesignEngine } from "@design-stack-vista/core-features";
import {
    TileImageData,
    getDefaultPatternPosition,
    getDimension,
    getSvgSizeFromUrl,
    removeBackgroundPattern,
    setBackgroundPattern,
    editBackgroundPattern
} from "@design-stack-vista/pattern-sdk";
import type { Pattern } from "@design-stack-vista/pattern-sdk";
import { MeasurementDimensions, MeasurementUnit, UnitlessTransforms } from "@design-stack-vista/utility-core";
import { Position } from "@design-stack-vista/cdif-types";
import { calculateTileDimension, getPatternColorsFromSvgUrl } from "@pattern-sim/shared";
import { PatternItemExtension } from "@pattern-sim/extensions";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { useCallback } from "react";

type UnitlessBox = Omit<UnitlessTransforms, "rotation">;

export const DEFAULT_PATTERN_TO_PANEL_DIMENSION = 0.3;

export const useBackgroundPattern = () => {
    const designEngine = useDesignEngine();
    const trackingClient = useTrackingClient();

    const { activeDesignPanelId } = designEngine.idaStore;

    const { activePanel: activePanelState } = useActivePanel();
    const activePanel = activePanelState?.asJson;

    const patternItemExtension =
        activePanel && getOptionalExtension(designEngine, activePanelState, PatternItemExtension);

    // TODO; Not handled image as a pattern.
    const addPattern = useCallback(
        async (patternInfo: Pattern) => {
            try {
                if (activeDesignPanelId && activePanel && patternItemExtension) {
                    const { setInitialPatternColors, setInitialPatternUrl } = patternItemExtension;
                    const tileDimension = getDimension(activePanel, DEFAULT_PATTERN_TO_PANEL_DIMENSION);

                    const tileSize: UnitlessBox = { x: 0, y: 0, height: 0, width: 0 };
                    let imagePosition = null;
                    let svgDimension = await getSvgSizeFromUrl(patternInfo.fileUrl);
                    const svgColors = await getPatternColorsFromSvgUrl(patternInfo.fileUrl);
                    setInitialPatternColors([...(svgColors ?? [])]);
                    setInitialPatternUrl(patternInfo.fileUrl);
                    svgDimension = svgDimension || { width: tileDimension, height: tileDimension };
                    imagePosition = calculateTileDimension(tileDimension, svgDimension);

                    tileSize.x = imagePosition.x;
                    tileSize.y = imagePosition.y;
                    tileSize.width = imagePosition.width;
                    tileSize.height = imagePosition.height;

                    const defaultPatternPosition = getDefaultPatternPosition(activePanel);

                    if (patternInfo.fileUrl && defaultPatternPosition) {
                        // Here other image properties can also be passed like colorAdjustment, overrides etc.
                        const tilePosition: MeasurementDimensions = {
                            width: `${tileSize.width}${MeasurementUnit.MM}`,
                            height: `${tileSize.height}${MeasurementUnit.MM}`
                        };

                        const patternPosition: Position = {
                            x: `${defaultPatternPosition.x - tileSize.x}${MeasurementUnit.MM}`,
                            y: `${defaultPatternPosition.y - tileSize.y}${MeasurementUnit.MM}`,
                            width: `${defaultPatternPosition.dimension + tileSize.width}${MeasurementUnit.MM}`,
                            height: `${defaultPatternPosition.dimension + tileSize.height}${MeasurementUnit.MM}`
                        };

                        const patternTileData: TileImageData = {
                            image: {
                                printUrl: patternInfo.fileUrl,
                                previewUrl: patternInfo.fileUrl,
                                position: tilePosition,
                                pageNumber: 0
                            }
                        };
                        designEngine.executeCommand(setBackgroundPattern, {
                            panelId: activeDesignPanelId,
                            pattern: {
                                data: patternTileData,
                                position: patternPosition
                            }
                        });
                        trackingClient.track(Events.DesignToolUsed, {
                            eventDetail: "Pattern clicked"
                        });
                    }
                }
            } catch (error) {
                throw new Error("Unable to add pattern");
            }
        },
        [activeDesignPanelId, activePanel, designEngine, patternItemExtension, trackingClient]
    );

    const editPattern = useCallback(
        (editingAttributes: {
            dimensionRatio?: number;
            rotationAngle?: number;
            xOffsetRatio?: number;
            yOffsetRatio?: number;
        }) => {
            if (activePanel && activeDesignPanelId) {
                const { dimensionRatio, rotationAngle, xOffsetRatio, yOffsetRatio } = editingAttributes;
                const patternDimension = dimensionRatio && getDimension(activePanel, dimensionRatio);

                const attributes = {
                    ...(patternDimension !== undefined && {
                        size: patternDimension
                    }),
                    ...(rotationAngle !== undefined && { rotationAngle }),
                    ...(xOffsetRatio !== undefined && {
                        xOffsetRatio
                    }),
                    ...(yOffsetRatio !== undefined && {
                        yOffsetRatio
                    })
                };
                designEngine.executeCommand(editBackgroundPattern, {
                    panelId: activeDesignPanelId,
                    attributes
                });
            }
        },
        [activeDesignPanelId, activePanel, designEngine]
    );

    const removePattern = useCallback(() => {
        if (activeDesignPanelId) {
            designEngine.executeCommand(removeBackgroundPattern, {
                panelId: activeDesignPanelId
            });
        }
        trackingClient.track(Events.DesignToolUsed, {
            eventDetail: "Pattern delete clicked",
            label: "Pattern deleted"
        });
    }, [activeDesignPanelId, designEngine, trackingClient]);

    return {
        patternItem: patternItemExtension?.patternItem,
        addPattern,
        removePattern,
        editPattern
    };
};
