import { getBoundingBox, UnitlessDimensions } from "@design-stack-vista/utility-core";
import { PanelChromesExtension, getRequiredExtension } from "@design-stack-vista/core-features";
import { InteractiveDesignEngine } from "@design-stack-vista/interactive-design-engine-core";
import { parsePanelDimensionsToMm, PanelState } from "@design-stack-vista/cimdoc-state-manager";

export const SHAPE_SIZE_FACTOR = 0.21;

export interface BoundingBox {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface DefaultSizeParams {
    currentPanel: PanelState;
    designEngine: InteractiveDesignEngine;
    scaleX?: number;
    scaleY?: number;
    initialDimensions?: UnitlessDimensions;
}

export const getLargestSafeArea = (designEngine: InteractiveDesignEngine, panel: PanelState): BoundingBox => {
    const panelExtension = getRequiredExtension(designEngine, panel, PanelChromesExtension);
    if (!panelExtension.masks.length) {
        const { width, height } = parsePanelDimensionsToMm(panel);
        return { width, height, x: 0, y: 0 };
    }

    return panelExtension.masks
        .filter(mask => mask.type === "SAFE")
        .map(mask => getBoundingBox(mask.paths[0].points))
        .reduce(
            (largest, current) => {
                const currentArea = current.width * current.height;
                const largestArea = largest ? largest.width * largest.height : 0;
                return currentArea > largestArea ? current : largest;
            },
            { x: 0, y: 0, width: 0, height: 0 }
        );
};

export const getDefaultSizeForShape = ({
    currentPanel,
    designEngine,
    scaleX = 1,
    scaleY = 1,
    initialDimensions
}: DefaultSizeParams) => {
    const { width: safeAreaWidth, height: safeAreaHeight } = getLargestSafeArea(designEngine, currentPanel);

    const maxSize = Math.min(safeAreaWidth, safeAreaHeight) * SHAPE_SIZE_FACTOR;

    // Cap the item dimensions to maxSize, with aspect ratio intact
    const shapeWidth = initialDimensions ? initialDimensions.width : maxSize;
    const shapeHeight = initialDimensions ? initialDimensions.height : maxSize;
    const widthRatio = Math.max(shapeHeight / shapeWidth, 1);
    const heightRatio = Math.max(shapeWidth / shapeHeight, 1);

    // Calculate the new width and height.  We want to use the aspect ratios to maintain the original proprtions of the shape
    const itemWidth = (maxSize / widthRatio) * scaleX;
    const itemHeight = (maxSize / heightRatio) * scaleY;

    return {
        width: itemWidth,
        height: itemHeight
    };
};
