import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { ModalDialogHeader, ModalDialogTitle } from "@vp/swan";
import { messages } from "../common";

export const Header = () => {
    const { t } = useTranslationSSR();

    return (
        <ModalDialogHeader mb={0}>
            <ModalDialogTitle>{t(messages.dialogTitle.id)}</ModalDialogTitle>
        </ModalDialogHeader>
    );
};
