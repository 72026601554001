import { getCimDocNodeById, isImageItem, removeItems } from "@design-stack-vista/cimdoc-state-manager";
import { removeCustomMetadata } from "@internal/utils-custom-metadata";
import type { CimDoc } from "@design-stack-vista/cdif-types";
import type { InstantUploadData } from "../types";

interface Props {
    id: string;
    data: InstantUploadData;
}

export function cleanupCimdocAfterFailure(cimDoc: CimDoc, { id, data }: Props) {
    const item = getCimDocNodeById(cimDoc, id)?.element;

    if (!item || id === data.replacedImageId || !isImageItem(item) || item.previewUrl !== data.temporaryUrl) {
        return;
    }

    removeItems(cimDoc, { ids: [id] });
    removeCustomMetadata(cimDoc, { ids: [id] });
}
