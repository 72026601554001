import type { ShapeItem } from "@design-stack-vista/cdif-types/v2";
import type { ItemState } from "@design-stack-vista/cimdoc-state-manager";
import { observer } from "mobx-react-lite";
import { ItemPosition, type PreviewBox } from "@design-stack-vista/ida-framework";
import {
    DesignLayerItemVisibilityExtension,
    FUSION_ANTIALIAS_PADDING,
    useDesignEngine,
    ItemPreviewExtension,
    ItemLayoutExtension,
    applyZoom,
    MillimeterTransformBox
} from "@design-stack-vista/core-features";
import { CustomItemPreviewComponent } from "@internal/sim-framework";
import React, { useEffect, useRef } from "react";

function getCanvasStyle(previewBox?: PreviewBox) {
    if (!previewBox) {
        return { display: "none" };
    }

    // Padding is added around the rendered preview within the canvas in order to avoid a bug in Chrome (https://bugs.chromium.org/p/chromium/issues/detail?id=1340045)
    // That padding is offset here by changing the dimensions of the canvas and shifting it.
    // Once the above bug in Chromium is fixed, we can remove the associated workaround code.
    return {
        transform: `translate(${-FUSION_ANTIALIAS_PADDING / devicePixelRatio}px,
        ${-FUSION_ANTIALIAS_PADDING / devicePixelRatio}px)`,
        height: `calc(100% + ${(2 / devicePixelRatio) * FUSION_ANTIALIAS_PADDING}px)`,
        width: `calc(100% + ${(2 / devicePixelRatio) * FUSION_ANTIALIAS_PADDING}px)`
    };
}

/**
 * The ItemPreview is a connected observer component, linked to the current design engine, that renders a preview for an item.
 *
 * @remarks
 * This component depends on the ItemPreviewExtension to render the preview and the values from the ItemLayoutExtension to correctly position it.
 * You must register these extensions in order to use this component as intended.
 *
 * You can also selectively control whether an item's preview is rendered using the {@link DesignLayerItemVisibilityExtension} and the layer name "item-preview"
 */
export const ItemPreview: CustomItemPreviewComponent<ItemState<ShapeItem>> = observer(({ item }) => {
    const canvas = useRef<HTMLCanvasElement>(null);
    const { designExtensionSystem: extensions, layoutStore } = useDesignEngine();
    const preview = extensions.getExtension(item.iid, ItemPreviewExtension);
    const layout = extensions.getExtension(item.iid, ItemLayoutExtension);
    const visibility = extensions.getExtension(item.iid, DesignLayerItemVisibilityExtension);

    useEffect(() => {
        if (canvas.current && preview) {
            preview.setCanvas(canvas.current);
        }
    }, [canvas, preview]);

    if (!preview || !layout) {
        return null;
    }

    // If the preview box exists, use it. Otherwise fall back to default.
    // Preview box contains layout values supplied by the rendering results
    const defaultPreviewBox: MillimeterTransformBox = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        rotation: 0
    };
    const previewBox = applyZoom(layout.interactivePreviewBox, layoutStore.zoom) || defaultPreviewBox;
    const dimensions = previewBox;
    const position = previewBox;
    const { rotation } = previewBox;

    const { zIndex } = item.model;

    const style = getCanvasStyle(previewBox);

    const isPreviewVisible = visibility?.getLayerVisibility("item-preview") ?? true;

    return (
        <ItemPosition dimensions={dimensions} position={position} rotation={rotation} zIndex={zIndex}>
            <canvas
                hidden={!isPreviewVisible || !style}
                ref={canvas}
                style={{ ...style, opacity: 0.5, backgroundColor: "yellow" }}
            />
        </ItemPosition>
    );
});
