import { getQueryParams } from "@internal/utils-browser";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { ErrorIconTypes, ErrorIconColor } from "@internal/ui-errors";
import { newRelicWrapper } from "@internal/utils-newrelic";
import { getStudioUniqueSessionId } from "@internal/utils-tracking";
import { getWorkEntity } from "@internal/data-access-work-entity-service";
import { usePageContext } from "@shared/features/StudioConfiguration";
import { getNextStep, getAction, fireImpressionsForContexts } from "@internal/utils-siteflow";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { fireGenericTrackingEvent } from "@shared/utils/Tracking";
import { translatedErrors } from "./errorMessagesNew";
import { getMyProjectsUrl, getHomepageUrl } from "./staticUrlServiceClient";

const params = getQueryParams();
const studioUniqueSession = getStudioUniqueSessionId();
const errorMessages = translatedErrors;
const productEntity = "22";
const merchEntity = "25";
const completionEntity = "24";
const workEntityServiceEntity = "20";
// Match on product catalog service. A non-404, 4xx generally means faulty product data
// Also check for merch service 404s
const mpvNotFoundRegex = new RegExp(`${productEntity}-4\\d[0-3|5-9]|${merchEntity}-404`);
// And 4xx from pricing indicates something funny with the product selections
const compatibilityRegex = new RegExp(`${completionEntity}-4\\d{2}|${productEntity}-404`);
// Any service returning a 500
const criticalFailure500Regex = new RegExp(`\\d{2}-5\\d{2}`);
// Any service returning a 400 or 500, there's nothing customers can do. 22-404 is a product setup error probably
const criticalFailure400Regex = new RegExp(`400|${productEntity}-404|11-10[0-13-9]`);
// Easel error. Further check is required whether it could have been related to 400 or 500
const easelErrorRegex = new RegExp(`11-10[0-13-9]`);
// 20-403 from work entity service means no permission to access.
const loadWorkNotPermittedRegex = new RegExp(`.*${workEntityServiceEntity}-403|.*${workEntityServiceEntity}-401`);
// No surfaces defined error should contain this string in the error message
const noSurfacesDefinedRegex = new RegExp("No surfaces are defined for this product", "i");

export function useErrorMessageData(locale: string, errorCode: string | undefined, errorMessage: string | undefined) {
    const { careUrl } = usePageContext();
    const { identity, auth } = useIdentityContext();
    const productAndProjectStateManager = useProductAndProjectStateManager();
    const { productKey, productVersion, workId } = productAndProjectStateManager.data;
    const authToken = auth?.getToken();

    const navigationSteps = {
        home: "home",
        gallery: "browsedesigns",
        product: "browseproduct",
        customerCare: "customercare",
        tryAgain: "tryagain",
        myProjects: "myprojects",
        nextStep: "nextStep"
    };
    const errorTypes = {
        optionsInvalid: "optionsInvalid",
        mpvNotFound: "mpvNotFound",
        criticalFail400: "criticalFail400",
        criticalFail500: "criticalFail500",
        loadWorkNotPermitted: "loadWorkNotPermitted",
        noSurfacesDefined: "noSurfacesDefined",
        urlContainsNoParams: "urlContainsNoParams",
        urlContainsProjectId: "urlContainsProjectId",
        urlContainsAltDocId: "urlContainsAltDocId",
        documentSurfacesMismatch: "documentSurfacesMismatch"
    };

    // We are setting some default values so we don't trigger another error while we're already dealing with an error
    // These default values probably won't provide the best results
    async function navigate(step: string) {
        switch (step) {
            case navigationSteps.tryAgain: {
                window.location.reload();
                break;
            }
            case navigationSteps.customerCare: {
                // careUrl should always be set, but it comes from pageContext, so there's no guarantee
                window.location.href = careUrl ?? "";
                break;
            }
            case navigationSteps.myProjects: {
                const myProjectsUrl = await getMyProjectsUrl(locale);
                window.location.href = myProjectsUrl.url;
                break;
            }
            case navigationSteps.gallery: {
                const action = await getAction(
                    productKey,
                    productVersion ?? 0,
                    params.selectedOptions,
                    locale,
                    navigationSteps.gallery
                );
                await fireImpressionsForContexts(action.context);
                window.location.href = action.url;
                break;
            }
            case navigationSteps.product: {
                const action = await getAction(
                    productKey,
                    productVersion ?? 0,
                    params.selectedOptions,
                    locale,
                    navigationSteps.product
                );
                await fireImpressionsForContexts(action.context);
                window.location.href = action.url;
                break;
            }
            case navigationSteps.nextStep: {
                const work = await getWorkEntity(authToken, identity, workId ?? "", fireGenericTrackingEvent);
                const result = await getNextStep(
                    productKey,
                    productVersion ?? 0,
                    work?.merchandising?.merchandisingSelections ?? {},
                    locale
                );
                if (!result.url) {
                    newRelicWrapper.addPageAction("studio-no-next-step-url", { nextStep: JSON.stringify(result) });
                }
                const nextStepUrl = result.url;
                await fireImpressionsForContexts(result.context);
                window.location.href = nextStepUrl.replace("{workId}", work.workId);
                break;
            }
            case navigationSteps.home:
            default: {
                window.location.href = (await getHomepageUrl(locale)).url;
                break;
            }
        }
    }

    const defaultError = {
        errorHeader: errorMessages.unknownErrorHeader.id,
        errorSubHeader: errorMessages.unknownErrorSubHeader.id,
        errorButtonMessage: errorMessages.getHelpButtonText.id,
        onClick: () => {
            newRelicWrapper.addPageAction("studio-error-cta", {
                errorCode,
                errorMessage,
                errorType: "default",
                cta: "primary",
                action: navigationSteps.customerCare
            });
            navigate(navigationSteps.customerCare);
        },
        errorButtonMessageSecondary: errorMessages.returnToHomeButtonText.id,
        onClickSecondary: () => {
            newRelicWrapper.addPageAction("studio-error-cta", {
                errorCode,
                errorMessage,
                errorType: "default",
                cta: "secondary",
                action: navigationSteps.home
            });
            navigate(navigationSteps.home);
        },
        errorCode,
        studioUniqueSession,
        iconType: ErrorIconTypes.Default,
        iconColor: ErrorIconColor.DesignTechTribe
    };

    if (!errorCode) {
        return defaultError;
    }

    // A lot of this relies on magic strings unfortunately, as that's how we have entity codes set up
    // Integration with Error Registrar clean this up
    let errorType = "";
    if (errorCode === "10-10-10-19" && params.alt_doc_id) {
        errorType = errorTypes.urlContainsAltDocId;
    } else if (errorCode === "10-10-10-19" && params.project_id) {
        errorType = errorTypes.urlContainsProjectId;
    } else if (errorCode === "10-10-10-19") {
        errorType = errorTypes.urlContainsNoParams;
    }

    if (errorCode.match(criticalFailure500Regex) || (errorCode.match(easelErrorRegex) && !params.workId)) {
        errorType = errorTypes.criticalFail500;
    }
    if (errorCode.match(criticalFailure400Regex) || (errorCode.match(easelErrorRegex) && params.workId)) {
        errorType = errorTypes.criticalFail400;
    }
    if (errorCode.match(mpvNotFoundRegex)) {
        errorType = errorTypes.mpvNotFound;
    }
    if (!params.workId && errorCode.match(compatibilityRegex)) {
        errorType = errorTypes.optionsInvalid;
    }
    if (errorCode.match(loadWorkNotPermittedRegex)) {
        errorType = errorTypes.loadWorkNotPermitted;
    }
    if (errorMessage?.match(noSurfacesDefinedRegex)) {
        errorType = errorTypes.noSurfacesDefined;
    }
    // uncomment the part below when Infinite Loop has a solution that would help users resize their design
    // if (errorMessage.match(surfacesMismatchRegex)) {
    //     errorType = errorTypes.documentSurfacesMismatch;
    // }

    switch (errorType) {
        case errorTypes.optionsInvalid: {
            const error = {
                errorHeader: errorMessages.invalidOptionsErrorHeader.id,
                errorSubHeader: errorMessages.invalidOptionsErrorSubHeader.id,
                errorButtonMessage: errorMessages.chooseAnotherProductButtonText.id,
                onClick: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.optionsInvalid,
                        cta: "primary",
                        action: navigationSteps.product
                    });
                    navigate(navigationSteps.product);
                },
                errorButtonMessageSecondary: errorMessages.getHelpButtonText.id,
                onClickSecondary: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.optionsInvalid,
                        cta: "secondary",
                        action: navigationSteps.customerCare
                    });
                    navigate(navigationSteps.customerCare);
                },
                errorCode,
                studioUniqueSession,
                iconType: ErrorIconTypes.InvalidProductData,
                iconColor: ErrorIconColor.ProductSetupTeam
            };
            return error;
        }
        case errorTypes.mpvNotFound: {
            const error = {
                errorHeader: errorMessages.mpvNotFoundErrorHeader.id,
                errorSubHeader: errorMessages.mpvNotFoundErrorSubHeader.id,
                errorButtonMessage: errorMessages.returnToHomeButtonText.id,
                onClick: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.mpvNotFound,
                        cta: "primary",
                        action: navigationSteps.home
                    });
                    navigate(navigationSteps.home);
                },
                errorButtonMessageSecondary: errorMessages.getHelpButtonText.id,
                onClickSecondary: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.mpvNotFound,
                        cta: "secondary",
                        action: navigationSteps.customerCare
                    });
                    navigate(navigationSteps.customerCare);
                },
                errorCode,
                studioUniqueSession,
                iconType: ErrorIconTypes.InvalidMerchandisingData,
                iconColor: ErrorIconColor.MerchTechTribe
            };
            return error;
        }
        case errorTypes.criticalFail400: {
            const error = {
                errorHeader: errorMessages.criticalFailErrorHeader.id,
                errorSubHeader: errorMessages.criticalFailErrorSubHeader.id,
                errorButtonMessage: errorMessages.returnToHomeButtonText.id,
                onClick: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.criticalFail400,
                        cta: "primary",
                        action: navigationSteps.home
                    });
                    navigate(navigationSteps.home);
                },
                errorButtonMessageSecondary: errorMessages.getHelpButtonText.id,
                onClickSecondary: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.criticalFail400,
                        cta: "secondary",
                        action: navigationSteps.customerCare
                    });
                    navigate(navigationSteps.customerCare);
                },
                errorCode,
                studioUniqueSession,
                iconType: ErrorIconTypes.Critical400Failure,
                iconColor: ErrorIconColor.DesignTechTribe
            };
            // override errorIconColor if matches conditions
            if (errorCode.indexOf("-22-4") >= 0) {
                error.iconColor = ErrorIconColor.ProductSetupTeam;
            }
            return error;
        }
        case errorTypes.criticalFail500: {
            const error = {
                errorHeader: errorMessages.criticalFailErrorHeader.id,
                errorSubHeader: errorMessages.criticalFailErrorSubHeader.id,
                errorButtonMessage: errorMessages.tryAgainButtonText.id,
                onClick: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.criticalFail500,
                        cta: "primary",
                        action: navigationSteps.tryAgain
                    });
                    navigate(navigationSteps.tryAgain);
                },
                errorButtonMessageSecondary: errorMessages.getHelpButtonText.id,
                onClickSecondary: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.criticalFail500,
                        cta: "secondary",
                        action: navigationSteps.customerCare
                    });
                    navigate(navigationSteps.customerCare);
                },
                errorCode,
                studioUniqueSession,
                iconType: ErrorIconTypes.Critical500Failure,
                iconColor: ErrorIconColor.DesignTechTribe
            };
            // override errorIconColor if matches conditions
            if (
                errorCode.indexOf("-22-5") >= 0 ||
                errorCode.indexOf("-24-5") >= 0 ||
                (errorMessage &&
                    errorMessage.indexOf("surfaces.products") > 0 &&
                    (errorCode.endsWith("12-500") || errorCode.endsWith("12-503") || errorCode.endsWith("12-504")))
            ) {
                error.iconColor = ErrorIconColor.ProductTribe;
            } else if (errorCode.indexOf("-25-5") >= 0) {
                error.iconColor = ErrorIconColor.MerchTechTribe;
            }
            return error;
        }
        case errorTypes.loadWorkNotPermitted:
            return {
                errorHeader: errorMessages.loadWorkNotPermittedErrorHeader.id,
                errorSubHeader: errorMessages.loadWorkNotPermittedErrorSubHeader.id,
                errorButtonMessage: errorMessages.getHelpButtonText.id,
                onClick: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.loadWorkNotPermitted,
                        cta: "primary",
                        action: navigationSteps.customerCare
                    });
                    navigate(navigationSteps.customerCare);
                },
                errorButtonMessageSecondary: errorMessages.returnToHomeButtonText.id,
                onClickSecondary: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.loadWorkNotPermitted,
                        cta: "secondary",
                        action: navigationSteps.home
                    });
                    navigate(navigationSteps.home);
                },
                errorCode,
                studioUniqueSession,
                iconType: ErrorIconTypes.Default,
                iconColor: ErrorIconColor.DesignTechTribe
            };
        case errorTypes.noSurfacesDefined: {
            const error = {
                errorHeader: errorMessages.noSurfacesDefinedErrorHeader.id,
                errorSubHeader: errorMessages.noSurfacesDefinedErrorSubHeader.id,
                errorButtonMessage: errorMessages.returnToHomeButtonText.id,
                onClick: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.noSurfacesDefined,
                        cta: "primary",
                        action: navigationSteps.home
                    });
                    navigate(navigationSteps.home);
                },
                errorButtonMessageSecondary: errorMessages.getHelpButtonText.id,
                onClickSecondary: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.noSurfacesDefined,
                        cta: "secondary",
                        action: navigationSteps.customerCare
                    });
                    navigate(navigationSteps.customerCare);
                },
                errorCode,
                studioUniqueSession,
                iconType: ErrorIconTypes.SurfaceIssue,
                iconColor: ErrorIconColor.ProductSetupTeam
            };
            return error;
        }
        case errorTypes.documentSurfacesMismatch:
            return {
                errorHeader: errorMessages.docSurfaceMismatchErrorHeader.id,
                errorSubHeader: errorMessages.docSurfaceMismatchErrorSubHeader.id,
                errorButtonMessage: errorMessages.returnToMyProjectsButtonText.id,
                onClick: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.documentSurfacesMismatch,
                        cta: "primary",
                        action: navigationSteps.myProjects
                    });
                    navigate(navigationSteps.myProjects);
                },
                errorButtonMessageSecondary: errorMessages.getHelpButtonText.id,
                onClickSecondary: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.documentSurfacesMismatch,
                        cta: "secondary",
                        action: navigationSteps.customerCare
                    });
                    navigate(navigationSteps.customerCare);
                },
                errorCode,
                studioUniqueSession,
                iconType: ErrorIconTypes.Default,
                iconColor: ErrorIconColor.DesignTechTribe
            };
        case errorTypes.urlContainsAltDocId: {
            const error = {
                errorHeader: errorMessages.urlContainsAltDocIdErrorHeader.id,
                errorSubHeader: errorMessages.urlContainsAltDocIdErrorSubHeader.id,
                errorButtonMessage: errorMessages.returnToMyProjectsButtonText.id,
                onClick: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.urlContainsAltDocId,
                        cta: "primary",
                        action: navigationSteps.myProjects
                    });
                    navigate(navigationSteps.myProjects);
                },
                errorButtonMessageSecondary: errorMessages.getHelpButtonText.id,
                onClickSecondary: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.urlContainsAltDocId,
                        cta: "secondary",
                        action: navigationSteps.customerCare
                    });
                    navigate(navigationSteps.customerCare);
                },
                errorCode,
                studioUniqueSession,
                iconType: ErrorIconTypes.Default,
                iconColor: ErrorIconColor.DesignTechTribe
            };
            return error;
        }
        case errorTypes.urlContainsProjectId:
            return {
                errorHeader: errorMessages.urlContainsNoParamsErrorHeader.id,
                errorSubHeader: errorMessages.urlContainsProjectIdErrorSubHeader.id,
                errorButtonMessage: errorMessages.returnToHomeButtonText.id,
                onClick: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.urlContainsProjectId,
                        cta: "primary",
                        action: navigationSteps.home
                    });
                    navigate(navigationSteps.home);
                },
                errorButtonMessageSecondary: errorMessages.getHelpButtonText.id,
                onClickSecondary: () => {
                    newRelicWrapper.addPageAction("studio-error-cta", {
                        errorCode,
                        errorMessage,
                        errorType: errorTypes.urlContainsProjectId,
                        cta: "secondary",
                        action: navigationSteps.customerCare
                    });
                    navigate(navigationSteps.customerCare);
                },
                errorCode,
                studioUniqueSession,
                iconType: ErrorIconTypes.Default,
                iconColor: ErrorIconColor.DesignTechTribe
            };
        default:
            return defaultError;
    }
}
