import type { SimInterface } from "@internal/sim-framework";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
import { TeamsNameContentDiscoveryZoneButton } from "./contentDiscoveryZone";
import { name } from "./name";
import defaultTranslations from "./i18n/en-US.json";
import { dialogTypes } from "./dialogTypes";
import { TeamsNamePluginPreloaderPanelExtension } from "./extensions/TeamsNamePluginPreloaderPanelExtension";
import { teamsNameNumberValidations, teamsNameTextValidations } from "./utils/teamsNameValidators";
export { ItemTeamsNameInfoTooltip } from "./contextualTooltip";
export { SimTeamsNameProvider } from "./shared";

const teamsNameSim = {
    name,
    deferredLoader: () => import("./index.deferred"),
    core: {
        defaultTranslations,
        extensions: [TeamsNamePluginPreloaderPanelExtension],
        contentDiscoveryZone: {
            TeamsName: {
                activeDialogType: dialogTypes.groupsAndTeams,
                component: TeamsNameContentDiscoveryZoneButton
            }
        },
        afterDuplicatePanelCallback: configuration => (cimDoc, panelId) => {
            const panel = cimDoc.document.panels.find(panel => panel.id === panelId);
            const teamsNameItems = (panel?.itemReferences || []).filter(
                item => item?.type === ItemReferenceTypes.TEAMS_NAME
            );
            teamsNameItems.forEach(item => {
                if (!item) {
                    return;
                }
                cimDoc.metadata?.teamsPlaceholders.push({
                    key: item.id,
                    itemReferenceType: item.type,
                    name: item.data.placeholderKey,
                    type: item.data.placeholderType,
                    validations:
                        item.data.placeholderType === "Number" ? teamsNameNumberValidations : teamsNameTextValidations
                });
            });
            return undefined;
        }
    }
} satisfies SimInterface<"ContentDiscoveryZone">;

export { dialogTypes } from "./dialogTypes";
export { isTeamsNameItemReference, TeamsNameItemReference } from "./utils";
export { useHasTeamsNameItems, useGetTeamsNameItems } from "./hooks";

export default teamsNameSim;
