import type { SimInterface } from "@internal/sim-framework";
import { PanelChromesExtension } from "@design-stack-vista/core-features";
import { QRCodesContentDiscoveryButton, openContentPanelOnItemSelect } from "./contentDiscoveryZone";
import { name } from "./name";
import { QRCodeExtension } from "./extensions";
import defaultTranslations from "./i18n/en-US.json";
import { dialogTypes } from "./dialogTypes";
import { QRCodePluginPreloaderPanelExtension } from "extensions/QRCodePluginPreloaderPanelExtension";

const QR_CODE_SIM = {
    name,
    deferredLoader: () => import("./index.deferred"),
    core: {
        defaultTranslations,
        extensions: [PanelChromesExtension, QRCodePluginPreloaderPanelExtension, QRCodeExtension],
        contentDiscoveryZone: {
            QRCodes: {
                activeDialogType: dialogTypes.qrcode,
                component: QRCodesContentDiscoveryButton
            }
        },
        openContentPanelAfterItemSelectionCallback: openContentPanelOnItemSelect,
        selectedZone: {
            detailZoneIds: new Set([
                {
                    type: "ColorPicker",
                    activeDialogType: dialogTypes.qrcodeColor
                },
                {
                    type: "General",
                    activeDialogType: dialogTypes.qrcodeEdit
                }
            ])
        }
    }
} satisfies SimInterface<"ContentDiscoveryZone" | "SelectedZone">;

export default QR_CODE_SIM;
