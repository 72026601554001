import { useCallback } from "react";
import { DesignTileCallback } from "@shared/utils/DesignPanel";
import { documentSourceTypeMap } from "@internal/data-access-design-specifications-service";
import { useErrors } from "@internal/utils-errors";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useDesignDialog } from "../DesignDialogProvider";
import { applyDesignOption } from "../utils";
import { useGetPanelSourceExtension } from "./useGetPanelSourceExtension";

export const useSelectStartFromScratchCallback = () => {
    const { getDocumentWithNonServerRenderableItems, loadNewDesign, fireInteractionTimedEvent } = useDesignDialog();
    const getPanelSourceExtension = useGetPanelSourceExtension();
    const { handleError } = useErrors();
    const productAndProjectState = useProductAndProjectStateManager();
    /**
     * This function is a callback that when called, will convert the panel with panelName to an empty canvas so that the user can start from scratch.
     * @param panelName - panel to be converted
     * @param resetting - are we resetting the panel (meaning are we going to ignore the saved panel). The user would be able to start from scratch again
     * @returns callback
     */
    return useCallback<DesignTileCallback>(
        ({ panelName, resetting }) => {
            return async (dispatch: any, getState: any) => {
                const panelSourceExtension = getPanelSourceExtension(panelName);
                if (!panelSourceExtension) {
                    return undefined;
                }
                const { surfaceUpsells } = getState();
                const surfaceUpsell = surfaceUpsells[panelName];
                return applyDesignOption({
                    dispatch,
                    productAndProjectState: productAndProjectState.data,
                    updateProductAndProjectStateConfiguration: productAndProjectState.updateConfiguration,
                    panelName,
                    resetting,
                    newDocumentSourceType: documentSourceTypeMap.EMPTY,
                    newDocumentSourceData: "",
                    newOptions: surfaceUpsell
                        ? {
                              [surfaceUpsell.optionName]: surfaceUpsell.colorOption
                          }
                        : undefined,
                    fireInteractionTimedEvent,
                    getDocument: getDocumentWithNonServerRenderableItems,
                    panelSourceExtension,
                    loadNewDesign,
                    handleError
                });
            };
        },
        [
            getPanelSourceExtension,
            productAndProjectState.data,
            productAndProjectState.updateConfiguration,
            fireInteractionTimedEvent,
            getDocumentWithNonServerRenderableItems,
            loadNewDesign,
            handleError
        ]
    );
};
