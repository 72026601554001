import React, { PropsWithChildren, createContext, useMemo, useContext, useCallback } from "react";
import { mapDesignAttributeToProductOption } from "@internal/utils-studio-configuration-core";
import type { ProductOption } from "@internal/data-access-product";

interface SimTeamsNameProviderProps {
    useDesignAttributeMappings: () => ProductOption[];
}

interface SimTeamsNameContextData {
    getSubstrateColor: (studioSelectedProductOptions: Record<string, string>) => string;
}

const SimTeamsNameContext = createContext<SimTeamsNameContextData | undefined>(undefined);

const substrateColorAttributeKey = "Substrate Color";

export const SimTeamsNameProvider = ({
    children,
    useDesignAttributeMappings
}: PropsWithChildren<SimTeamsNameProviderProps>) => {
    const designAttributeMappings = useDesignAttributeMappings();
    const getSubstrateColor = useCallback(
        (studioSelectedProductOptions: Record<string, string>) => {
            const substrateColorDesignAttribute = mapDesignAttributeToProductOption(
                designAttributeMappings,
                substrateColorAttributeKey
            );
            return studioSelectedProductOptions[substrateColorDesignAttribute];
        },
        [designAttributeMappings]
    );
    const contextObject = useMemo(() => {
        return { getSubstrateColor };
    }, [getSubstrateColor]);
    return <SimTeamsNameContext.Provider value={contextObject}>{children}</SimTeamsNameContext.Provider>;
};

SimTeamsNameProvider.displayName = "SimTeamsNameProvider";

export const useSimTeamsName = () => {
    const result = useContext(SimTeamsNameContext);
    if (!result) {
        throw new Error("useSimTeamsName must be used as a child of <SimTeamsNameProvider />");
    }
    return result;
};
