import { useCallback, useMemo } from "react";
import type { CropFractions, ImageItem } from "@design-stack-vista/cdif-types/v2";
import { ItemState, parsePanelDimensionsToMm } from "@design-stack-vista/cimdoc-state-manager";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import {
    ImagePosition,
    UnitlessDimensions,
    Vector2,
    getImageAsPromise,
    type CalculatePosition,
    calculateCoverPosition,
    calculateCropCoverPosition,
    calculateScaleToFitPosition,
    calculateScaleToHalfPosition,
    Measurement,
    MeasurementUnit,
    convertCropNumbersToString
} from "@design-stack-vista/utility-core";
import { useDesignEngine, getActivePanel, getOptionalExtension } from "@design-stack-vista/core-features";
import { VistaAsset, VistaAssetStore } from "@design-stack-vista/vista-assets-sdk";
import { VariantType } from "@design-stack-vista/vista-assets-sdk/dist/type/variantsType";
import { useUploadManager } from "@design-stack-vista/upload-components";
import { UnitlessBox, getItemPositionFitOnRotation } from "@internal/utils-item-position";
import { useTrackingClient, Events } from "@internal/utils-tracking";
import { PanelSectionExtension } from "@internal/feature-panel-sections";
import type { DispatchToast, DispatchToastProps } from "@internal/utils-detail-zone";
import { CutlineBaseImageVariantType, ImageVariantType, useApplyCutlineItems } from "./useApplyCutlineItems";
import { useCutlineConfiguration } from "./CutlineConfigurationProvider";
import { InteractiveDesignEngine } from "@design-stack-vista/interactive-design-engine-core";
import { buildImageMetadataFromUpload } from "@internal/utils-assets";

// Helper functions and types that were dependencies of addUploadWithCutline
type ModificationType = "backgroundRemove" | "sharpen";

type ImageInfo = NonNullable<NonNullable<VistaAsset["data"]>["info"]["image"]>;

interface AddImageOptionalParams {
    panelId?: string;
    location?: Vector2;
    imageInfo?: ImageInfo;
    pageNumber?: number;
    /**
     * Optional ongoing upload to use as part of the instant uploads flow.
     * When not provided, `addImage` instead tries to fetch a previously cached asset (for icons/deposit photos) or creates a new (hidden) asset if one cannot be found
     */
    upload?: Promise<void | VistaAsset>;
    /** Must be passed as property to avoid race condition */
    itemForReplacing?: ItemState<ImageItem>;
}

type AddUploadWithCutlineType = (
    upload: VistaAsset,
    optional?: AddImageOptionalParams & {
        cutlineBaseImageVariantType?: CutlineBaseImageVariantType;
        imageVariantType?: ImageVariantType;
    }
) => Promise<void>;

enum ImagePlacementStrategyType {
    scaleToHalf = "scaleToHalf",
    scaleToFit = "scaleToFit",
    cover = "cover",
    coverCropped = "coverCropped"
}

interface GetImagePositionParams {
    dimensions: UnitlessDimensions;
    placementStrategy: ImagePlacementStrategyType;
    designEngine: InteractiveDesignEngine;
    panelId?: string;
    location?: Vector2;
    targetDimensions?: UnitlessDimensions;
    panelSectionExtension?: any;
}

type ImagePositionAndRotation = {
    position: ImagePosition;
    rotationAngle: number;
};

/**
 * @param scaleToHalf Position the image in the center of the target (or at explicitly specified coordinates)
 * at 50% of the width or height of the target
 * @param scaleToFit Position the source item such that it fits the target width or/and height,
 * scales the item while maintaining the aspect ratio
 * @param cover Position the item such that it covers the entire available target area, allowing it to overflow in the process
 * @param coverCropped Position the source item so that it covers all available space, cropping to keep the item within the bounds
 */
const imagePlacementStrategy: Record<ImagePlacementStrategyType, CalculatePosition> = {
    [ImagePlacementStrategyType.scaleToHalf]: calculateScaleToHalfPosition,
    [ImagePlacementStrategyType.scaleToFit]: calculateScaleToFitPosition,
    [ImagePlacementStrategyType.cover]: calculateCoverPosition,
    [ImagePlacementStrategyType.coverCropped]: calculateCropCoverPosition
};

function getImagePositionAndAngle({
    dimensions,
    placementStrategy,
    designEngine,
    panelId,
    targetDimensions,
    location,
    panelSectionExtension
}: GetImagePositionParams): ImagePositionAndRotation {
    const { cimDocStore, idaStore } = designEngine;
    const currentPanelId = panelId ?? idaStore.activeDesignPanelId ?? designEngine.cimDocStore.panels[0].id;
    const currentPanel = cimDocStore.panels.find(p => p.panelProperties.id === currentPanelId);

    if (!currentPanel) {
        throw new Error("Cannot compute position for an image without a panel");
    }

    let imagePlacementStrategyToUse = placementStrategy;
    let targetDimensionsToUse = targetDimensions;
    let imageLocation = location;
    let rotationAngle = 0;

    const panelDimensions = parsePanelDimensionsToMm(currentPanel);

    /**
     * When we have panel-section available for the product and image isn't drag&dropped
     * we will consider panel data
     *
     * In such case the image added will be confined to the section itself,
     * with 20% padding
     *
     * Image Placement strategy used will be Scale to Fit to the section
     */
    if (!location && panelSectionExtension) {
        const { activeSectionBoundingBox, sectionContentOrientation } = panelSectionExtension;
        if (activeSectionBoundingBox) {
            const imageAspectRatio = dimensions.width / dimensions.height;

            rotationAngle = sectionContentOrientation;
            const boundingArea = getItemPositionFitOnRotation(
                activeSectionBoundingBox,
                imageAspectRatio,
                rotationAngle,
                20
            );
            targetDimensionsToUse = boundingArea;
            imagePlacementStrategyToUse = ImagePlacementStrategyType.scaleToFit;
            imageLocation = {
                x: boundingArea.x,
                y: boundingArea.y
            };
        }
    }

    const position = imagePlacementStrategy[imagePlacementStrategyToUse](
        dimensions,
        targetDimensionsToUse ?? panelDimensions,
        imageLocation
    );

    // When placing inside specific target dimensions (i.e. not a panel) we need to treat the generated position as an offset from the given location
    // This is because the imagePlacementStrategy functions assume targetDimensions to be a panel and will return x,y as relative to 0,0
    if (targetDimensionsToUse && imageLocation) {
        position.x += imageLocation.x;
        position.y += imageLocation.y;
    }

    return { position, rotationAngle };
}

const getImageVariant = async (
    asset: VistaAsset,
    variantType: VariantType,
    assetStore: VistaAssetStore
): Promise<VistaAsset> => {
    const metadata = await asset.getVariantMetadata(asset.data!, variantType);
    const newAsset = await assetStore.fetchSingleAsset({ id: metadata.id });
    await newAsset.presign();
    return newAsset;
};

type BuildImageItemResult = Omit<ImageItem, "id" | "zIndex">;

interface BuildImageFromUploadResult extends BuildImageItemResult {
    previewUrl: string;
    printUrl?: string;
    originalSourceUrl: string;
    cropFractions: CropFractions;
}

function buildImageFromUpload(
    upload: VistaAsset,
    pageNumber: number,
    position: ImagePosition,
    rotationAngle?: string
): BuildImageFromUploadResult {
    const urlOptions = pageNumber !== 1 ? { pageNum: pageNumber } : undefined;
    return {
        printUrl: upload.print.getUrl(),
        previewUrl: upload.webPreview.getUrl(urlOptions),
        originalSourceUrl: upload.getUrl(),
        position: {
            x: new Measurement(position.x, MeasurementUnit.MM).measurement,
            y: new Measurement(position.y, MeasurementUnit.MM).measurement,
            width: new Measurement(position.width, MeasurementUnit.MM).measurement,
            height: new Measurement(position.height, MeasurementUnit.MM).measurement
        },
        cropFractions: convertCropNumbersToString(position.crop),
        pageNumber,
        rotationAngle
    };
}

interface AssetImageInfoEventDTO {
    id?: string;
    imageInfo?: ImageInfo;
}

interface FieldData {
    field: string;
    analysisField: string;
}

const getMimeType = (asset: VistaAsset | undefined, eventImageInfo?: AssetImageInfoEventDTO) =>
    asset?.data?.info?.storage?.contentType ||
    asset?.data?.info?.image?.format ||
    eventImageInfo?.imageInfo?.format ||
    "notAvailable";

function getBooleanDataFields(
    fields: FieldData[],
    imageInfo?: ImageInfo,
    properties?: Record<string, any>,
    dataInfo?: ImageInfo
): Record<string, boolean> {
    const getBooleanData = ({ field, analysisField }: FieldData) => {
        if (imageInfo) {
            // @ts-ignore FIXME: must handle implicit `any` type
            return !!imageInfo[field];
        }
        if (properties && (properties[analysisField] as string) !== "False") {
            return !!properties[analysisField];
        }
        // @ts-ignore FIXME: must handle implicit `any` type
        if (dataInfo && dataInfo[field]) {
            return true;
        }
        return false;
    };

    const fieldValues = fields.reduce<Record<string, boolean>>((acc, currentData) => {
        acc[currentData.field] = getBooleanData(currentData);
        return acc;
    }, {});

    return fieldValues;
}

function extractMetaData(asset?: VistaAsset, imageInfo?: ImageInfo) {
    try {
        if (!asset) {
            return {};
        }
        const { data } = asset;

        const timeToUpload = data?.totalUploadTimeMs;
        if (data?.info?.image?.format === "pdf" || data?.info?.image?.format === "unknown") {
            // Less data available for PDFs
            return {
                id: data?.id,
                fileType: getMimeType(asset),
                size: data?.info?.storage?.fileSizeBytes,
                clientInitiator: "studio",
                timeToUpload
            };
        }

        const fields = getBooleanDataFields(
            [
                { field: "isLogo", analysisField: "analysisIsLogo" },
                { field: "isPhoto", analysisField: "analysisIsPhoto" },
                { field: "isVector", analysisField: "analysisIsVector" }
            ],
            imageInfo,
            data?.properties,
            data?.info?.image
        );

        return {
            id: data?.id,
            fileType: getMimeType(asset),
            size: data?.info?.storage?.fileSizeBytes,
            ...fields,
            lineartness:
                (imageInfo && "lineartness" in imageInfo && imageInfo?.lineartness) ||
                (data?.properties?.analysisLineartness as string) ||
                (data?.info?.image && "lineartness" in data.info.image && `${data?.info?.image.lineartness}`),
            clientInitiator: "studio",
            timeToUpload
        };
    } catch (e) {
        return {};
    }
}

// This hook is only used for having Sticker DEX documents work properly in Studio
// Sticker DEX images are reuploaded to Sherbert and this function reapplies the image and cutline to the document
// For regular studio flow, use addUploadWithCutline in useAddImage.tsx
export const useAddReuploadedImageWithCutline = (props: { dispatchToast?: DispatchToast }) => {
    const { assetStore } = useUploadManager();
    const designEngine = useDesignEngine();
    const trackingClient = useTrackingClient();

    const dispatchToast = useMemo(
        () =>
            props.dispatchToast
                ? props.dispatchToast
                : (props: DispatchToastProps) => {
                      // eslint-disable-next-line no-console -- only emitted when there is no dispatchToast prop
                      console.warn(props.message);
                  },
        [props.dispatchToast]
    );

    const { applyCutlineItems } = useApplyCutlineItems({ dispatchToast });
    const { setIsReuploadingImage } = useCutlineConfiguration();
    const { auth } = useIdentityContext();
    const activePanel = getActivePanel(designEngine) ?? designEngine.layoutStore.visiblePanels[0];
    const panelSectionExtension = getOptionalExtension(designEngine, activePanel, PanelSectionExtension);
    const contentOrientation = panelSectionExtension?.sectionContentOrientation || 0;

    const addReuploadedImageWithCutline: AddUploadWithCutlineType = useCallback(
        async (image, options = {}) => {
            const startTime = performance.now();

            try {
                const { panelId, location, pageNumber = 1, imageVariantType, cutlineBaseImageVariantType } = options;

                const appliedProcesses: ModificationType[] = [];
                let cropBoundingBox: UnitlessBox | undefined;
                let imageHeight: number | undefined;
                let imageWidth: number | undefined;
                let inputImageHeight: number | undefined;
                let inputImageWidth: number | undefined;

                if (image.data?.info.image && "width" in image.data.info.image && "height" in image.data.info.image) {
                    inputImageHeight = image?.data?.info?.image?.height;
                    inputImageWidth = image?.data?.info?.image?.width;
                }

                const { naturalWidth, naturalHeight } = await getImageAsPromise(image.webPreview.getUrl());
                const imageDimensions = {
                    width: inputImageWidth || naturalWidth,
                    height: inputImageHeight || naturalHeight
                };

                const isPdfOrPpt =
                    image.data?.info.storage?.contentType === "application/pdf" ||
                    image.data?.info.storage?.contentType ===
                        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                    image.data?.info.storage?.contentType === "application/vnd.ms-powerpoint";

                // Non-raster files like PDF and Postscript need to be converted to webPreview before bgko
                const isNonSupportedMediaType =
                    isPdfOrPpt ||
                    image.data?.info.storage?.contentType === "application/postscript" ||
                    image.data?.info.storage?.contentType === "image/vnd.adobe.photoshop";

                const imageUrl = isNonSupportedMediaType
                    ? await image.getStackedVariantUrl({
                          variant1: "webPreview",
                          includeSignature: true,
                          pageNum: isPdfOrPpt ? pageNumber : undefined
                      })
                    : image.getUrl({ pageNum: pageNumber });
                const { position: originalImagePosition } = getImagePositionAndAngle({
                    dimensions: imageDimensions,
                    placementStrategy: ImagePlacementStrategyType.scaleToHalf,
                    designEngine,
                    panelId,
                    location,
                    panelSectionExtension
                });

                const originalImageAsset = image;

                // fallback is the original asset
                let processedImageAsset = image;

                // attempt to retrieve the backgroundless asset
                try {
                    const backgroundRemovedAssetPromise = getImageVariant(
                        image,
                        VariantType.BackgroundRemoved,
                        assetStore!
                    );

                    // TODO: Use Sherbert/assetSDK to get the cropBoundingBox https://vistaprint.atlassian.net/browse/QSP-363
                    const body = JSON.stringify({
                        input: {
                            backgroundDetectMode: "bestshot",
                            url: imageUrl,
                            cropThreshold: 20,
                            processCMYKasRGBA: true
                        }
                    });

                    const backgroundRemovePromise = fetch(
                        "https://imagemind.ipa.cimpress.io/v2/imagemind/backgroundremove",
                        {
                            method: "POST",
                            body,
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${auth.getToken()}`
                            }
                        }
                    );

                    const [backgroundRemovedAsset, backgroundRemoveResponse] = await Promise.all([
                        backgroundRemovedAssetPromise,
                        backgroundRemovePromise
                    ]);

                    const backgroundRemovedPrintUrl = backgroundRemovedAsset.print.getUrl();

                    imageVariantType === "backgroundRemoved" && appliedProcesses.push("backgroundRemove");

                    if (backgroundRemovedAsset) {
                        processedImageAsset = backgroundRemovedAsset;
                    } else if (backgroundRemovedPrintUrl) {
                        // make a new asset with printUrl
                        processedImageAsset = await assetStore!.upload({
                            uri: backgroundRemovedPrintUrl,
                            expires: "never",
                            info: {
                                asset: {
                                    hidden: true
                                }
                            }
                        });

                        await processedImageAsset.presign();
                    }

                    const result = await backgroundRemoveResponse.json();

                    if (!backgroundRemoveResponse.ok) {
                        throw new Error(`Failed to remove background: ${result.exception?.detail}`);
                    }
                    cropBoundingBox = result.output?.cropBoundingBox;
                    imageWidth = result.output?.info?.outputWidth;
                    imageHeight = result.output?.info?.outputHeight;
                    // END TODO
                } catch (e) {
                    const processImageEndTime = performance.now();
                    trackingClient.track(Events.StudioDiagnostic, {
                        label: "Process Image Failed",
                        eventDetail: "Image Processing Failed",
                        extraData: {
                            assetId: image.data?.id,
                            timeToProcessUpload: processImageEndTime - startTime
                        }
                    });

                    const originalImageItem = buildImageFromUpload(
                        originalImageAsset,
                        pageNumber,
                        originalImagePosition,
                        contentOrientation.toString()
                    );

                    const originalImageMetadata = buildImageMetadataFromUpload(image, originalImagePosition);

                    // if background removal fails apply original image with rectangular cutline
                    await applyCutlineItems({
                        panelId,
                        imageData: {
                            originalImageItem: originalImageItem as ImageItem,
                            originalImageDimensions: imageDimensions,
                            imageMetadata: originalImageMetadata,
                            imageVariantType: "original",
                            cutlineBaseImageVariantType: "original"
                        }
                    });

                    return;
                }

                // convert asset to image item
                const dimensions = cropBoundingBox
                    ? { width: cropBoundingBox.width, height: cropBoundingBox.height }
                    : imageDimensions;

                const { position: imagePosition } = getImagePositionAndAngle({
                    dimensions,
                    placementStrategy: ImagePlacementStrategyType.scaleToHalf,
                    designEngine,
                    panelId,
                    location,
                    panelSectionExtension
                });

                if (cropBoundingBox && imageHeight && imageWidth) {
                    imagePosition.crop = {
                        top: cropBoundingBox?.y / imageHeight,
                        left: cropBoundingBox?.x / imageWidth,
                        right: (imageWidth - cropBoundingBox?.width - cropBoundingBox.x) / imageWidth,
                        bottom: (imageHeight - cropBoundingBox?.height - cropBoundingBox?.y) / imageHeight
                    };
                }

                // Always pass page number 1 here:
                // Multi page PDFs are already depaginated so attempting to get their URL with respect
                // to a page number will fail
                const backgroundRemovedImageItem = buildImageFromUpload(
                    processedImageAsset,
                    1,
                    imagePosition,
                    contentOrientation.toString()
                );
                // Add the page number in manually so the image data is correct
                backgroundRemovedImageItem.pageNumber = pageNumber;
                // Use the original image url from the unprocessed variant to keep uploads drawer "used in design" accurate
                backgroundRemovedImageItem.originalSourceUrl = imageUrl;

                const imageMetadata = buildImageMetadataFromUpload(image, imagePosition);

                const originalImageItem = buildImageFromUpload(
                    originalImageAsset,
                    pageNumber,
                    originalImagePosition,
                    contentOrientation.toString()
                );

                setIsReuploadingImage(false);

                // Cutline precision does not get preserved from the Sticker DEX document
                // Instead we automatically use the tightest valid cutline
                await applyCutlineItems({
                    panelId,
                    imageData: {
                        backgroundRemovedImageItem: backgroundRemovedImageItem as ImageItem,
                        backgroundRemovedCropBoundingBox: cropBoundingBox,
                        originalImageItem: originalImageItem as ImageItem,
                        originalImageDimensions: imageDimensions,
                        imageMetadata: {
                            ...imageMetadata,
                            dclMetadata: {
                                ...imageMetadata.dclMetadata,
                                imageTransformations: { appliedProcesses }
                            }
                        },
                        imageVariantType: imageVariantType || "backgroundRemoved",
                        cutlineBaseImageVariantType: cutlineBaseImageVariantType || "backgroundRemoved"
                    }
                });
            } catch (e) {
                const errorMessage = e instanceof Error ? e.message : e;
                trackingClient.track(Events.StudioDiagnostic, {
                    label: "Image",
                    eventDetail: "Upload Failed: Overall",
                    extraData: () => {
                        return { ...extractMetaData(image), errorMessage };
                    }
                });

                throw new Error(
                    "The image was not uploaded because of an unknown error. Try again, or upload a different image."
                );
            } finally {
                setIsReuploadingImage(false);
            }
        },
        [
            setIsReuploadingImage,
            designEngine,
            panelSectionExtension,
            contentOrientation,
            applyCutlineItems,
            assetStore,
            auth,
            trackingClient
        ]
    );

    return {
        addReuploadedImageWithCutline
    };
};
