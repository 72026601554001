import type { BackgroundRemove } from "../types";

export const backgroundRemoveUrl: BackgroundRemove = async backgroundRemoveConfig => {
    const { imageUrl, accessToken } = backgroundRemoveConfig;

    const body = JSON.stringify({
        input: {
            backgroundDetectMode: "bestshot",
            url: imageUrl
        }
    });
    const backgroundRemoveResponse = await fetch("https://imagemind.ipa.cimpress.io/v2/imagemind/backgroundremove", {
        method: "POST",
        body,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    });

    const result = await backgroundRemoveResponse.json();

    if (!backgroundRemoveResponse.ok) {
        throw new Error(`Failed to remove background: ${result.exception?.detail}`);
    }

    return result.output?.url;
};
