import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { patternMessage } from "../../shared/patternMessage";
import { FlexBox, H1, Button } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { getColorName } from "@pattern-sim/shared";
import { ShuffleIcon } from "@internal/ui-studio-chrome";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { useSimLocale } from "@internal/sim-framework";
import { usePatternThemeColors } from "../../hooks";
import styles from "./ThemesColorPanel.module.scss";
import { getOptionalExtension, useActivePanel, useDesignEngine } from "@design-stack-vista/core-features";
import { PatternItemExtension } from "@pattern-sim/extensions";
import { useActiveDialogSetters } from "@internal/utils-active-dialog";
import { dialogTypes } from "dialogTypes";

export const ThemesColorPanel = observer(() => {
    const { t } = useTranslationSSR();
    const { isLarge, isMedium } = useStudioLayout();
    const locale = useSimLocale();
    const designEngine = useDesignEngine();
    const { activePanel } = useActivePanel();
    const patternItemExtension = activePanel && getOptionalExtension(designEngine, activePanel, PatternItemExtension);
    const { setCurrentActiveDialog } = useActiveDialogSetters();
    const { isSmall } = useStudioLayout();

    const { onThemeSelect, selectedThemeIndex, randomColorsSet, setSelectedThemeIndex } = usePatternThemeColors();

    useEffect(() => {
        if (!patternItemExtension || !patternItemExtension.patternItem) {
            setCurrentActiveDialog(dialogTypes.pattern);
        }
    }, [patternItemExtension, setCurrentActiveDialog, patternItemExtension?.patternItem]);

    // list of color names for accessibility aria-label
    const selectedThemeColorsName = useMemo(() => {
        const themeColorPalette = getColorName(randomColorsSet, locale);
        return themeColorPalette.map(colorSet =>
            colorSet
                .map(color => {
                    return color.name;
                })
                .toString()
        );
    }, [locale, randomColorsSet]);

    return (
        <FlexBox flexDirection="column" data-testid="pattern-theme-panel">
            {(isLarge || isMedium) && (
                <H1 fontSize="large" fontWeight="bold" mb={"5"} px={"6"} mt={"6"}>
                    {t(patternMessage.themePanelTitle.id)}
                </H1>
            )}
            <FlexBox
                className={styles.colorThemeContainerStyle}
                flexDirection="column"
                data-testid="pattern-theme-container"
                px={isSmall ? 0 : "6"}
            >
                {randomColorsSet.map((colorSet: any[], index: number) => (
                    <Button
                        key={index}
                        onFocus={() => {
                            if (index !== -1) {
                                setSelectedThemeIndex(index);
                            }
                        }}
                        aria-label={`${t(patternMessage.themeButtonAriaLabel.id, {
                            colorNames: selectedThemeColorsName[selectedThemeIndex]
                        })}`}
                        className={classNames(styles.buttonContainerStyle, {
                            [styles.colorGroupBorderVisible]: selectedThemeIndex === index
                        })}
                        onClick={() => onThemeSelect(index)}
                        skin="unstyled"
                        aria-pressed="false"
                    >
                        <FlexBox
                            alignItems="center"
                            justifyContent="center"
                            className={styles.colorsContainer}
                            style={{
                                background: `linear-gradient(to right, ${colorSet
                                    .map((color, index) => `${color.value} ${index * 20}% ${index * 20 + 20}%`)
                                    .join(",")})`
                            }}
                        >
                            <ShuffleIcon
                                className={classNames(styles.shuffleIconStyle, {
                                    [styles.shuffleIconVisible]: selectedThemeIndex === index
                                })}
                                aria-label={t(patternMessage.shuffleThemeColors.id)}
                            />
                        </FlexBox>
                    </Button>
                ))}
            </FlexBox>
        </FlexBox>
    );
});
