import { useIsFlagEnabled } from "@internal/utils-flags";
import { type UseOverrideEnableState } from "@internal/sim-framework";
import { name } from "./simName";

/**
 * Prototype overrides
 */
export const usePrototypeSim: UseOverrideEnableState = () => {
    const enableMockSim = useIsFlagEnabled("mockSim");
    return {
        [name]: enableMockSim
    };
};
