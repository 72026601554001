import type { SimInterface } from "@internal/sim-framework";
import { openContentPanelOnItemSelect, PatternContentDiscoveryZoneButton } from "./contentDiscoveryZone";
import { name } from "./name";
import { PatternItemExtension } from "./extensions";
import defaultTranslations from "./i18n/en-US.json";
import { usePatternSimOverride } from "./usePatternSimOverride";
import { dialogTypes } from "./dialogTypes";
import { PatternExtension } from "./extensions/PatternExtension";
import { PatternItemPreviewWrapper } from "features/patternItemPreview/PatternItemPreviewWrapper";
import type { PatternItemReferenceData } from "shared/PatternItemReferenceTypes";

const patternSim = {
    name,
    deferredLoader: () => import("./index.deferred"),
    core: {
        defaultTranslations,
        extensions: [PatternItemExtension, PatternExtension],
        contentDiscoveryZone: {
            Pattern: {
                activeDialogType: dialogTypes.pattern,
                component: PatternContentDiscoveryZoneButton
            }
        },
        selectedZone: {
            detailZoneIds: new Set([
                {
                    type: "ColorPicker",
                    activeDialogType: dialogTypes.patternColors
                },
                {
                    type: "ColorPicker",
                    activeDialogType: dialogTypes.patternTheme
                },
                {
                    type: "General",
                    activeDialogType: dialogTypes.patternResize
                }
            ])
        },
        openContentPanelAfterItemSelectionCallback: openContentPanelOnItemSelect,
        useOverrideEnableState: usePatternSimOverride,
        itemPreview: {
            matchSelection: "itemReference/panel",
            ItemPreview: PatternItemPreviewWrapper
        }
    }
} satisfies SimInterface<
    | { feature: "CustomItemPreview"; matchSelection: "itemReference/panel"; data: PatternItemReferenceData }
    | "ContentDiscoveryZone"
    | "SelectedZone"
>;

export { dialogTypes } from "./dialogTypes";

export default patternSim;
