import React, { useCallback, useEffect } from "react";
import type { DetailZoneComponent } from "@internal/utils-detail-zone";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useDesignEngine, useActivePanel, getOptionalExtension } from "@design-stack-vista/core-features";
import { observer } from "mobx-react-lite";
import { DialogCoreValues, useActiveDialog, useActiveDialogSetters } from "@internal/utils-active-dialog";
import { PatternContainer, SheetTabs } from "@pattern-sim/features";
import { Sheet, SheetPanel } from "@internal/ui-studio-chrome";
import { dialogTypes } from "../dialogTypes";
import { PatternPanelMode, patternMessage as messages } from "../shared";
import { PatternItemExtension, patternStore } from "../extensions";
import styles from "./mobilestyles.module.scss";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { useSims } from "@internal/sim-framework";

export const SmallScreenContentPanel: DetailZoneComponent = observer(() => {
    const { t } = useTranslationSSR();
    const { currentActiveDialog } = useActiveDialog();
    const { setCurrentActiveDialog } = useActiveDialogSetters();
    const designEngine = useDesignEngine();
    const { shouldShowPatterns } = useSims().studioConfigurationManagerDelegate.data;
    const trackingClient = useTrackingClient();

    const { patternPanelMode, setPatternPanelMode } = patternStore;
    const { activePanel } = useActivePanel();
    const patternItemExtension = activePanel && getOptionalExtension(designEngine, activePanel, PatternItemExtension);
    const patternItem = patternItemExtension && patternItemExtension?.patternItem;

    useEffect(() => {
        if (shouldShowPatterns) {
            trackingClient.track(Events.DesignToolUsed, {
                eventDetail: "Patterns panel clicked"
            });
        }
    }, [shouldShowPatterns, trackingClient]);

    if (!patternItem) {
        setPatternPanelMode(PatternPanelMode.Add);
    }

    const handleCloseClick = useCallback(() => {
        setCurrentActiveDialog(DialogCoreValues.None);
    }, [setCurrentActiveDialog]);

    const onBackClick = () => {
        if (patternPanelMode === PatternPanelMode.Edit) {
            setCurrentActiveDialog(dialogTypes.pattern);
            setPatternPanelMode(PatternPanelMode.Add);
        }
    };

    return (
        <Sheet ariaLabel={t(messages.patternSheet.id)} role="pattern-sheet">
            {patternItem && patternPanelMode === PatternPanelMode.Edit ? (
                <SheetPanel
                    isSelected={currentActiveDialog === dialogTypes.pattern}
                    backButtonAriaLabel={t(messages.backButtonAccessibilityLabel.id)}
                    closeButtonAriaLabel={t(messages.closeButtonAccessibilityLabel.id)}
                    onBackClick={onBackClick}
                    onCloseClick={handleCloseClick}
                    sheetTitle={t(messages.sheetEditPanelLabel.id)}
                >
                    <SheetTabs />
                </SheetPanel>
            ) : (
                <SheetPanel
                    className={styles.patternsSheetStyle}
                    isSelected={currentActiveDialog === dialogTypes.pattern}
                    onCloseClick={handleCloseClick}
                    backButtonAriaLabel={t(messages.backButtonAccessibilityLabel.id)}
                    closeButtonAriaLabel={t(messages.closeButtonAccessibilityLabel.id)}
                >
                    <PatternContainer />
                </SheetPanel>
            )}
        </Sheet>
    );
});
