import type { CimDoc, ImageOverlay, PlaceholderImage } from "@design-stack-vista/cdif-types";
import type { VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { getCimDocNodeById, isImageItem } from "@design-stack-vista/cimdoc-state-manager";
import { metadataItemsForImageId } from "@internal/utils-custom-metadata";
import { EMBROIDERY_IMAGE_LOCK_METADATA, RESET_EMBROIDERY_IMAGE_LOCK_METADATA } from "../constants";
import { buildImageMetadataFromUpload } from "../utils";
import type { InstantUploadData } from "../types";

interface AdditionalImageProperties {
    overlays: ImageOverlay[];
    threshold: number;
    inverted: boolean;
}

interface UpdateItemWithUploadParameters {
    id: string;
    data: InstantUploadData;
    asset: VistaAsset;
    originalSourceUrl: string;
    previewUrl: string;
    printUrl: string;
    additionalImageProperties?: Partial<AdditionalImageProperties>;
    isEmbroideryPanel: boolean;
    isSingleColorPanel: boolean;
    appliedProcesses: string[];
}

export function updateItemWithUpload(
    cimDoc: CimDoc,
    {
        id,
        data,
        asset,
        originalSourceUrl,
        previewUrl,
        printUrl,
        additionalImageProperties,
        isEmbroideryPanel,
        isSingleColorPanel,
        appliedProcesses
    }: UpdateItemWithUploadParameters
) {
    const node = getCimDocNodeById(cimDoc, id);
    const item = node?.element;

    if (item && isImageItem(item) && item.previewUrl === data.temporaryUrl) {
        Object.assign(item, { originalSourceUrl, previewUrl, printUrl });

        if (!data.keepOriginalMetadata) {
            const { dclMetadataItem, studioMetadataItem } = metadataItemsForImageId(cimDoc, id);
            // Also need to update the image metadata as most of it requires a finished upload to populate
            const imageMetadata = buildImageMetadataFromUpload(asset, dclMetadataItem.initialSize!);
            Object.assign(
                dclMetadataItem,
                imageMetadata.dclMetadata,
                appliedProcesses.length > 0 && { imageTransformations: { appliedProcesses } }
            );
            Object.assign(studioMetadataItem, imageMetadata.studioMetadata);
        }

        if (isEmbroideryPanel) {
            // Unlock any temporary locks used while embroidery was processing
            const embroideryTemplateLocks = cimDoc.metadata?.template?.find(metadataItem => metadataItem.id === id);

            if (
                embroideryTemplateLocks &&
                (embroideryTemplateLocks as PlaceholderImage).intent === EMBROIDERY_IMAGE_LOCK_METADATA.intent
            ) {
                Object.assign(embroideryTemplateLocks, RESET_EMBROIDERY_IMAGE_LOCK_METADATA);
            }
        }

        if (isSingleColorPanel && additionalImageProperties) {
            const { overlays, inverted, threshold } = additionalImageProperties;
            Object.assign(item, {
                originalSourceUrl,
                previewUrl: undefined,
                printUrl: undefined,
                overlays
            });
            const { dclMetadataItem } = metadataItemsForImageId(cimDoc, id);
            const imageMetadata = buildImageMetadataFromUpload(asset, dclMetadataItem.initialSize!);
            Object.assign(dclMetadataItem, {
                threshold,
                inverted,
                ...imageMetadata.dclMetadata
            });
        }
    }
}
