import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button, ButtonProps } from "@vp/swan";

const messages = defineMessages({
    getStartedButtonText: {
        id: "sims.teamsName.contentDiscoveryZone.howToDialog.getStartedButtonText.text",
        defaultMessage: "Get started"
    }
});

export const ConfirmButton = (props: ButtonProps) => {
    const { t } = useTranslationSSR();

    return (
        <Button skin="primary" size="mini" width="full-width" {...props}>
            {t(messages.getStartedButtonText.id)}
        </Button>
    );
};
