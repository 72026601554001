import { Pattern, usePatternsContentManager } from "@design-stack-vista/pattern-sdk";
import { useBackgroundPattern } from "@pattern-sim/hooks";
import { Box, Breadcrumb, Breadcrumbs, Button, Icon, Img, Link, type SelectedValue } from "@vp/swan";
import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslationSSR } from "@vp/i18n-helper";
import { patternMessage } from "@pattern-sim/shared";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { useActivePanel, getOptionalExtension, useDesignEngine } from "@design-stack-vista/core-features";
import { PatternItemExtension } from "@pattern-sim/extensions";
import { PatternDetailViewSkeleton } from "./PatternDetailViewSkeleton";
import { ContentBox, ContentBoxButton, ContentBoxItem } from "@vp/diy-editor-ui";
// eslint-disable-next-line no-restricted-imports -- FIXME use scopedNewrelicWrapper
import { newRelicWrapper } from "@internal/utils-newrelic";
import styles from "./PatternDetailView.module.scss";

interface PatternDetailViewProps {
    selectedCategory: string;
    returnCallback: () => void;
}

export const PatternDetailView = observer(({ selectedCategory, returnCallback }: PatternDetailViewProps) => {
    const { patternsByCategory } = usePatternsContentManager();
    const { addPattern } = useBackgroundPattern();
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();
    const designEngine = useDesignEngine();
    const { activePanel } = useActivePanel();
    const patternItemExtension = activePanel && getOptionalExtension(designEngine, activePanel, PatternItemExtension);

    const [patterns, setPatterns] = useState<Pattern[]>([]);
    const [loading, setLoading] = useState(true);
    const backButtonRef = useRef<HTMLButtonElement>(null);

    const handlePatternAdd = (patternID: SelectedValue) => {
        if (!patternID) {
            return;
        }
        const pattern = patterns.find(({ id }) => id === patternID);
        if (!pattern) {
            return;
        }
        try {
            addPattern(pattern);
        } catch (error) {
            newRelicWrapper.noticeError(error as Error, {
                patternID
            });
        }
    };

    useEffect(() => {
        if (patternsByCategory[selectedCategory]) {
            setLoading(false);
            setPatterns(patternsByCategory[selectedCategory]);
        }
        backButtonRef.current?.focus();
    }, [patternsByCategory, selectedCategory]);

    const selectedPatternID = patterns.find(pattern => patternItemExtension?.patternFileUrl === pattern.fileUrl)?.id;

    return (
        <>
            <Box marginTop={isSmall ? 0 : "4"} marginX={"6"}>
                <Breadcrumbs>
                    <Breadcrumb onClick={returnCallback}>
                        <Link>{t(patternMessage.patternTitle.id)}</Link>
                    </Breadcrumb>
                    <Breadcrumb>
                        <Link>{selectedCategory}</Link>
                    </Breadcrumb>
                </Breadcrumbs>
                <Button
                    skin="unstyled"
                    iconPosition="left"
                    mb={"5"}
                    onClick={returnCallback}
                    ref={backButtonRef}
                    data-testid="pattern-detail-view-back-button"
                >
                    <Icon mr={"3"} iconType="arrowLeft" />
                    {t(patternMessage.patternTitle.id)}
                </Button>
            </Box>
            <Box pt={0} px={"6"} pb={"4"} className={styles.container}>
                {loading ? (
                    <PatternDetailViewSkeleton quantity={3} />
                ) : (
                    <ContentBox
                        allowMultiSelect={false}
                        allowDelete={false}
                        legend={t(patternMessage.patternTitle.id)}
                        onSelectedValueChange={handlePatternAdd}
                        selectedValue={selectedPatternID}
                        columns={isSmall ? 4 : 3}
                    >
                        {patterns.map((pattern, patternIndex) => {
                            const isPatternSelected = selectedPatternID === pattern.id;
                            return (
                                <ContentBoxItem value={pattern.id} key={pattern.id}>
                                    <ContentBoxButton
                                        aria-label={`${selectedCategory} ${patternIndex}`}
                                        aria-pressed={isPatternSelected}
                                    >
                                        <Img key={pattern.id} src={pattern.thumbnailUrl} />
                                    </ContentBoxButton>
                                </ContentBoxItem>
                            );
                        })}
                    </ContentBox>
                )}
            </Box>
        </>
    );
});
