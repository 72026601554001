import { PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { InteractiveDesignEngine } from "@design-stack-vista/interactive-design-engine-core";
import {
    BetweenBoundsProps,
    ImageResolutionProps,
    OutOfBoundsForPathsProps,
    ValidateItemsOverlapProps
} from "@design-stack-vista/vista-validations";
import { VistaAssetStore } from "@design-stack-vista/vista-assets-sdk";
import type { ImageResolutionValidatorItemConfig } from "@internal/utils-assets";
import {
    BetweenBoundsValidatorItemConfig,
    OutOfBoundsValidatorItemConfig,
    OverlapValidatorItemConfig,
    ValidationName
} from "../../types";

export type BetweenBoundArgument = BetweenBoundsProps & { id: string };

export type ImageResolutionArgument = ImageResolutionProps & { id: string };

export type OutOfBoundForPathsArgument = OutOfBoundsForPathsProps & { id: string };

export type OverlapArgument = ValidateItemsOverlapProps & { id: string };

export interface BetweenBoundsValidationLayerProps {
    panel: PanelState;
    config: BetweenBoundsValidatorItemConfig[];
    localizationKey: "betweenBoundaries.print";
}

export interface ImageResolutionValidationLayerProps {
    panel: PanelState;
    config: ImageResolutionValidatorItemConfig;
    localizationKey: string;
}

export interface OutOfBoundsValidationLayerProps {
    panel: PanelState;
    config: OutOfBoundsValidatorItemConfig[];
    validationName: ValidationName;
    localizationKey:
        | "outsideMargins.embroidery"
        | "outsideMargins.print"
        | "outsideBounds.embroidery"
        | "outsideBounds.print"
        | "outsideMargins.mailingValidation.print";
}

export interface OverlapValidationLayerProps {
    panel: PanelState;
    config: OverlapValidatorItemConfig[];
    localizationKey: "overlap";
}

export interface BetweenBoundsPanelItemMapperProps {
    config: BetweenBoundsValidatorItemConfig[];
    designEngine: InteractiveDesignEngine;
}

export type ImageResolutionPanelItemMapperProps = {
    config: ImageResolutionValidatorItemConfig;
    designEngine: InteractiveDesignEngine;
    assetStore: VistaAssetStore;
};

export interface OutOfBoundsPanelItemMapperProps {
    config: OutOfBoundsValidatorItemConfig[];
    designEngine: InteractiveDesignEngine;
}

export interface OverlapPanelItemMapperProps {
    config: OverlapValidatorItemConfig[];
    designEngine: InteractiveDesignEngine;
}

export enum ImageResolutionStatus {
    ERROR = "error",
    WARNING = "warning",
    OK = "ok"
}
