import type { PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { ItemPreloaderExtension } from "@internal/sim-framework";
import { override } from "mobx";
import { name } from "../simName";
/**
 * Handles preloading the extension
 */
export class PrototypeSimPreloaderPanelExtension extends ItemPreloaderExtension {
    declare designState: PanelState;

    get simName() {
        return name;
    }

    @override
    get shouldPreload() {
        // preload if there are shapes so we can test the preload functionality
        return this.designState.items.some(item => item.isShapeItem());
    }
}
