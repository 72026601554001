import type { ImageItem } from "@design-stack-vista/cdif-types";
import type { ModificationType } from "../types";
import { getRootPanel, isEmbroidery, type ItemState } from "@design-stack-vista/cimdoc-state-manager";
import type {
    ItemLocksExtension,
    ItemMetadataExtension,
    ItemTemplateExtension
} from "@design-stack-vista/interactive-design-engine-core";
import type { VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { getUploadDimensions } from "./validateAssets";
import { MAX_SHARPEN_PIXEL_SIZE, MIN_SHARPEN_PIXEL_SIZE } from "../constants";
import { CRISPIFY_SUPPORTED_FILE_TYPES } from "@design-stack-ct/image-mind-client";
import { isSingleColor } from "@internal/utils-deco-tech";

export function isImageModificationAllowed(
    modificationType: ModificationType,
    imageItem: ItemState<ImageItem>,
    metadataExtension: ItemMetadataExtension,
    templateExtension: ItemTemplateExtension,
    locksExtension: ItemLocksExtension,
    overrideLockRequirements: boolean,
    asset?: VistaAsset
) {
    let imageWidth;
    let imageHeight;
    if (asset) {
        const { height, width } = getUploadDimensions(asset);
        imageWidth = width;
        imageHeight = height;
    } else if (metadataExtension?.designMetadata?.naturalDimensions) {
        const { width, height } = metadataExtension.designMetadata.naturalDimensions;
        imageWidth = Number(width);
        imageHeight = Number(height);
    }

    if (!imageWidth || !imageHeight) {
        return false;
    }

    const isImageSharpenable =
        imageHeight * imageWidth < MAX_SHARPEN_PIXEL_SIZE && imageHeight * imageWidth > MIN_SHARPEN_PIXEL_SIZE;
    const isNonReplacedPlaceholder = templateExtension.isPlaceholderImage && !templateExtension.isPlaceholderReplaced;
    const mimeType = asset?.data?.info.storage?.contentType ?? metadataExtension.designMetadata?.mimeType;

    const supportedFileTypes =
        modificationType === "sharpen" ? CRISPIFY_SUPPORTED_FILE_TYPES : ["image/jpg", "image/png", "image/jpeg"];

    const { decorationTechnology } = getRootPanel(imageItem).panelProperties;

    const modificationAllowed = !!(
        !isNonReplacedPlaceholder &&
        (overrideLockRequirements || !locksExtension.isUserLocked) &&
        !isEmbroidery(decorationTechnology) &&
        !isSingleColor(decorationTechnology) &&
        supportedFileTypes.includes(mimeType ?? "")
    );

    const sharpenAllowed =
        modificationAllowed && (!locksExtension.isRestricted || locksExtension.overrides.sharpen) && isImageSharpenable;

    if (!modificationAllowed || (modificationType === "sharpen" && !sharpenAllowed)) {
        return false;
    }
    return true;
}
