import { type SimInterface } from "@internal/sim-framework";
import { name } from "./name";
import { VectorPluginPreloaderPanelExtension, VectorExtension } from "./extensions";
import defaultTranslations from "./i18n/en-US.json";
import { vectorMessages } from "./shared/vectorMessages";
import { VectorDraggableValue } from "./contentDiscoveryZone/dragAndDrop";
import { useHandleDropTarget } from "./contentDiscoveryZone/hooks";
import { ClipartsPanel } from "./panelTypes";

import { dialogTypes } from "./dialogTypes";

const vectorSim = {
    name,
    core: {
        defaultTranslations,
        extensions: [VectorPluginPreloaderPanelExtension, VectorExtension],
        selectedZone: {
            detailZoneIds: new Set([
                {
                    type: "ColorPicker",
                    activeDialogType: dialogTypes.VectorColorPicker
                }
            ])
        },
        contentDiscoveryZone: {
            GraphicsAndShapes: {
                [ClipartsPanel]: {
                    panelTitle: vectorMessages.panelTitle.id
                }
            }
        },
        dragAndDrop: {
            [VectorDraggableValue]: {
                useHandleDropTarget
            }
        }
    },
    deferredLoader: () => import("./index.deferred")
} satisfies SimInterface<"ContentDiscoveryZone" | "DragAndDrop" | "SelectedZone">;

export default vectorSim;

export { isVectorItemReference, isVectorItem } from "./shared/utils";
