import React, { useCallback } from "react";
import { H6, SelectionSetInput, SelectionSetLabel } from "@vp/swan";
import { qrCodeStore } from "../../../extensions/qrCodeStore";
import { useTranslationSSR } from "@vp/i18n-helper";
import { QRAsset, QRShapeType } from "../../types";
import { qrCodeMessages as messages } from "../../QRCodeMessages";
import { RenderWhen } from "../RenderWhen";
import classNames from "classnames";
import styles from "./SelectionSetInputField.module.scss";

export enum SelectionSetInputVariant {
    Small = "small",
    Medium = "medium",
    Large = "large"
}

interface SelectionSetInputProps {
    asset: QRAsset;
    imageUploaderLoading?: boolean;
    isDescriptionHidden?: boolean;
    previewImageType?: QRShapeType | undefined;
    variant?: SelectionSetInputVariant;
}

export const SelectionSetInputField: React.FC<SelectionSetInputProps> = ({
    asset,
    imageUploaderLoading,
    isDescriptionHidden,
    previewImageType,
    variant = SelectionSetInputVariant.Small
}) => {
    const { t } = useTranslationSSR();
    const { name, value, imgUrl, previewUrl } = asset;

    const setPreview = useCallback(() => {
        if (previewImageType) {
            qrCodeStore.setHoverNature(previewImageType);
            qrCodeStore.setHoverImage(previewUrl);
        }
    }, [previewImageType, previewUrl]);

    const unsetPreview = useCallback(() => {
        if (previewImageType) {
            qrCodeStore.setHoverNature(null);
            qrCodeStore.setHoverImage(null);
        }
    }, [previewImageType]);

    return (
        <div>
            <SelectionSetInput value={value} disabled={imageUploaderLoading}>
                <SelectionSetLabel
                    className={classNames(styles.selectionLabelStyle, styles[`variant-${variant}`])}
                    onMouseEnter={setPreview}
                    onMouseLeave={unsetPreview}
                    onClick={unsetPreview}
                >
                    <img src={imgUrl} alt={t(messages[name].id)} className={styles.imageStyle} />
                </SelectionSetLabel>
            </SelectionSetInput>
            <RenderWhen condition={!isDescriptionHidden}>
                <H6
                    fontSize={"xsmall"}
                    mb={0}
                    className={classNames(styles.descriptionTextStyle, `variant-${variant}`)}
                >
                    {t(messages[name].id)}
                </H6>
            </RenderWhen>
        </div>
    );
};
