import { useCallback, useState } from "react";
import { getOptionalExtension, useActivePanel, useDesignEngine } from "@design-stack-vista/core-features";
import {
    ColorGroup,
    defaultColorsPalettes,
    getPatternUrl,
    getRandomColorsFromPalette,
    replaceBackgroundPatternUrl
} from "@design-stack-vista/pattern-sdk";
import { getColorGroup } from "../shared";
import { PatternItemExtension } from "@pattern-sim/extensions";

export const usePatternThemeColors = () => {
    const [selectedThemeIndex, setSelectedThemeIndex] = useState<number>(-1);

    const designEngine = useDesignEngine();

    const randomColorsSet = defaultColorsPalettes.map(theme => theme.colors);
    const { activePanel } = useActivePanel();
    const patternItemExtension = activePanel && getOptionalExtension(designEngine, activePanel, PatternItemExtension);

    const onThemeSelect = useCallback(
        (index: number) => {
            if (patternItemExtension) {
                const { initialPatternColors, initialPatternUrl, setInitialPatternColors } = patternItemExtension;
                const selectedColorGroup = defaultColorsPalettes[index];
                const generatedColors = getRandomColorsFromPalette(selectedColorGroup, initialPatternColors.length);
                const newApplicableColors = generatedColors.map(color => color.cssBackground);
                const colorGroup: ColorGroup = getColorGroup(newApplicableColors);
                const newPatternUrl = getPatternUrl(initialPatternUrl, colorGroup);
                setInitialPatternColors(newApplicableColors);

                if (index !== -1) {
                    setSelectedThemeIndex(index);
                }
                designEngine.executeCommand(replaceBackgroundPatternUrl, {
                    panelId: activePanel.id,
                    patternUrl: newPatternUrl
                });
            }
        },
        [activePanel, designEngine, patternItemExtension]
    );
    return { onThemeSelect, selectedThemeIndex, randomColorsSet, setSelectedThemeIndex };
};
