import { action, makeObservable, observable } from "mobx";
import { QRCodeTabType, QRCodeURLPanelMode, QRShapeType } from "../shared/types";

class QRCodeStore {
    /**
     * @description The current mode of the QR code URL panel
     */
    @observable qrCodeURLPanelMode: QRCodeURLPanelMode = QRCodeURLPanelMode.Add;
    @observable hoverImage: string | null = null;
    @observable hoverNature: QRShapeType.Corners | QRShapeType.Dots | null = null;
    @observable isExtraImageColorEditMode: boolean = false;
    @observable isLogoColorSame: boolean = true;
    /**
     * @description The current tab to display in the QR content panel
     */
    @observable currentTab = QRCodeTabType.Shape;

    constructor() {
        makeObservable(this);
        this.currentTab = QRCodeTabType.Url;
    }

    /**
     * @description Sets the mode of the QR code URL panel to the given mode
     * @param mode - the mode to set the QR code URL panel to
     */
    @action.bound
    setURLPanelMode(mode: QRCodeURLPanelMode) {
        this.qrCodeURLPanelMode = mode;
    }

    @action.bound
    setHoverImage(image: string | null) {
        this.hoverImage = image;
    }

    @action.bound
    setHoverNature(nature: QRShapeType.Corners | QRShapeType.Dots | null) {
        this.hoverNature = nature;
    }

    @action.bound
    setExtraImageColorEditMode(mode: boolean) {
        this.isExtraImageColorEditMode = mode;
    }

    /**
     * @description Sets the current tab to the given tab
     * @param tab - the tab to set
     */
    @action.bound
    setCurrentTab(tab: QRCodeTabType) {
        this.currentTab = tab;
    }

    @action.bound
    setIsLogoColorSame(status: boolean) {
        this.isLogoColorSame = status;
    }
}

export const qrCodeStore = new QRCodeStore();
