import { type OpenContentPanelAfterItemSelectionCallbackProps } from "@internal/sim-framework";
import { dialogTypes } from "../dialogTypes";
import { qrCodeStore } from "../extensions/qrCodeStore";
import { isQrCodeTypeReference, isImageQRPlaceholder } from "../shared/utils";
import { QRCodeURLPanelMode, QRCodeTabType } from "../shared/types";

export function openContentPanelOnItemSelect({
    firstSelectedItem,
    designEngine
}: OpenContentPanelAfterItemSelectionCallbackProps) {
    if (isQrCodeTypeReference(firstSelectedItem)) {
        qrCodeStore.setCurrentTab(QRCodeTabType.Shape);
        qrCodeStore.setURLPanelMode(QRCodeURLPanelMode.Edit);
        return dialogTypes.qrcode;
    }

    /**
     * Check if the selected item is a QR code placeholder
     */
    const isSelectedItemQRCodePlaceholder =
        firstSelectedItem.isImageItem() && isImageQRPlaceholder(firstSelectedItem, designEngine);

    /**
     * When clicked on a QR code placeholder, open the url selection panel
     */
    if (isSelectedItemQRCodePlaceholder) {
        qrCodeStore.setCurrentTab(QRCodeTabType.Url);
        qrCodeStore.setURLPanelMode(QRCodeURLPanelMode.Add);
        return dialogTypes.qrcode;
    }

    /**
     * When clicked on any other item, open the url selection panel
     */
    qrCodeStore.setCurrentTab(QRCodeTabType.Url);
    qrCodeStore.setURLPanelMode(QRCodeURLPanelMode.Add);

    /**
     * Removed add from here, as clicking on the empty space should
     * show background color picker, not QR add selection
     */
    return;
}
