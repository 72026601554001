import { action, computed, makeObservable, observable } from "mobx";
import {
    isLineItem,
    parsePanelDimensionsToMm,
    type DesignState,
    type ItemState,
    type PanelState
} from "@design-stack-vista/cimdoc-state-manager";
import { BaseExtension } from "@design-stack-vista/interactive-design-engine-core";
import { ItemReference } from "@design-stack-vista/cdif-types";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
import { checkIfItemsBlockBackgroundOrPattern } from "@internal/utils-background-color";

/**
 * Extension that provides the Pattern data from panel.
 */
export class PatternItemExtension extends BaseExtension {
    protected declare designState: PanelState;
    @observable initialPatternColors: string[] = [];
    @observable initialPatternUrl: string = "";

    static supports(designState: DesignState): boolean {
        return designState.isPanelState();
    }

    constructor(designState: DesignState) {
        super(designState);
        makeObservable(this);
    }

    /**
     * @description Returns the pattern item if present in the **active panel**.
     */
    @computed
    get patternItem(): ItemState<ItemReference> | undefined {
        const patternItems = this.designState.items.filter(
            item => item.isItemReference() && item.model.type === ItemReferenceTypes.TILE_IMAGE
        );
        if (patternItems.length > 0) {
            return patternItems[0] as ItemState<ItemReference>;
        }
        return undefined;
    }

    @computed
    get patternUrl() {
        return this.patternItem?.model.data.image.previewUrl;
    }

    @computed
    get patternFileUrl() {
        if (!this.patternUrl) {
            return null;
        }
        return new URLSearchParams(new URL(this.patternUrl).search).get("fileUrl") || this.patternUrl;
    }

    @action.bound
    setInitialPatternColors(patternColors: string[]) {
        this.initialPatternColors = [...patternColors];
    }

    @action.bound
    setInitialPatternUrl(patternUrl: string) {
        this.initialPatternUrl = patternUrl;
    }

    /**
     * Determines if an item blocks the pattern, used to determine whether to show the pattern button.
     */
    @computed
    get itemBlocksPattern() {
        const currentItems = this.designState.items.filter(
            item =>
                item.isItemState() &&
                !isLineItem(item.model) &&
                item.model.position.height &&
                item.model.position.width &&
                (item.isImageItem() || (item.isShapeItem() && item.model.color))
        );
        const { width, height } = parsePanelDimensionsToMm(this.designState);
        return checkIfItemsBlockBackgroundOrPattern(currentItems, width, height);
    }
}
