import type { Crispify } from "../types";

export const crispify: Crispify = async crispifyConfig => {
    const { imageUrl, accessToken } = crispifyConfig;

    const body = JSON.stringify({
        input: {
            classificationType: "auto",
            url: imageUrl
        }
    });
    const crispifyResponse = await fetch("https://imagemind.ipa.cimpress.io/v2/imagemind/crispify", {
        method: "POST",
        body,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    });

    const result = await crispifyResponse.json();

    if (!crispifyResponse.ok) {
        throw new Error(`Failed to crispify: ${result.exception?.detail}`);
    }

    return result.output?.url;
};
