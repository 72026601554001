import { defineMessages } from "@vp/i18n-helper";

export const contentPanelMessages = defineMessages({
    title: {
        id: "sims.teamsName.contentDiscoveryZone.teamsNamePanel.title",
        defaultMessage: "Make each one unique!",
        description: {
            note: "Title for the groups and teams panel"
        }
    },
    contentTitle: {
        id: "sims.teamsName.contentDiscoveryZone.teamsNamePanel.contentTitle",
        defaultMessage: "Teams content panel"
    },
    info: {
        id: "sims.teamsName.contentDiscoveryZone.teamsNamePanel.info",
        defaultMessage:
            "Pick a placeholder and adjust its style, color, and position. In the next step you will add details for each person!",
        description: {
            note: "Information for the groups and teams panel"
        }
    }
});

export const placeholderButtonsMessages = defineMessages({
    addNamePlaceholder: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNamePlaceholder",
        defaultMessage: "Name"
    },
    addNameButtonTitle: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNameButtonTitle",
        defaultMessage: "Name",
        description: {
            note: "Title for Name placeholder button"
        }
    },
    addNameButtonDetails: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNameButtonDetails",
        defaultMessage: "Give everyone their own personal item"
    },
    addNameButtonImageAlt: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNameButtonImageAlt",
        defaultMessage: "Name placeholder"
    },
    addRolePlaceholder: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addRolePlaceholder",
        defaultMessage: "Role",
        description: {
            note: "Role placeholder name displayed on the canvas"
        }
    },
    addRoleButtonTitle: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addRoleButtonTitle",
        defaultMessage: "Role/job title",
        description: {
            note: "Title for Role/job placeholder button"
        }
    },
    addRoleButtonDetails: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addRoleButtonDetails",
        defaultMessage: "Ideal for your staff, colleagues and personnel"
    },
    addRoleButtonImageAlt: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addRoleButtonImageAlt",
        defaultMessage: "Role/Job title placeholder"
    },
    addNumberPlaceholder: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNumberPlaceholder",
        defaultMessage: "Number"
    },
    addNumberButtonTitle: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNumberButtonTitle",
        defaultMessage: "Number",
        description: {
            note: "Title for Number placeholder button"
        }
    },
    addNumberButtonDetails: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNumberButtonDetails",
        defaultMessage: "Perfect to give your sports team the pro look it deserves"
    },
    addNumberButtonImageAlt: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNumberButtonImageAlt",
        defaultMessage: "Number placeholder"
    },
    addTextPlaceholder: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addTextPlaceholder",
        defaultMessage: "Placeholder",
        description: {
            note: "Text placeholder name displayed on the canvas as Placeholder 1, Placeholder 2, Placeholder 3 ..."
        }
    },
    addTextPlaceholderButtonTitle: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addTextPlaceholderButtonTitle",
        defaultMessage: "Add your own placeholder",
        description: {
            note: "Title for Text placeholder button"
        }
    },
    addTextPlaceholderButtonDetails: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addTextPlaceholderButtonDetails",
        defaultMessage: "You can use this for phone numbers, emails and more"
    },
    addTextPlaceholderButtonImageAlt: {
        id: "sims.teamsName.contentDiscoveryZone.placeholderButtons.addTextPlaceholderButtonImageAlt",
        defaultMessage: "Text placeholder"
    }
});
