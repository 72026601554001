import React from "react";
import { Typography, Button, ModalDialogProps } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { StudioModal, StudioModalBody, StudioModalFooter } from "@vp/diy-editor-ui";
import { qrCodeMessages as messages } from "../../../shared/QRCodeMessages";

interface DeleteImageModalProps extends ModalDialogProps {
    showDeleteImageModal?: boolean;
    onConfirm: () => void;
}

export const DeleteImageModal = (props: DeleteImageModalProps) => {
    const { showDeleteImageModal, onRequestDismiss, onConfirm, ...rest } = props;
    const { t } = useTranslationSSR();

    return (
        <StudioModal
            isOpen={showDeleteImageModal}
            onRequestDismiss={onRequestDismiss}
            modalTitle={t(messages.extraImageModalDialogTitle.id)}
            contentAriaLabel={t(messages.extraImageModalAriaLabel.id)}
            closeButtonAccessibleText={t(messages.extraImageModalCloseButton.id)}
            onlyRenderWhenOpen
            {...rest}
        >
            <StudioModalBody>
                <Typography>{t(messages.extraImageModalDialogMessage.id)}</Typography>
            </StudioModalBody>
            <StudioModalFooter>
                <Button skin="primary" size="mini" onClick={onConfirm}>
                    {t(messages.extraImageModalConfirmButton.id)}
                </Button>
                <Button skin="secondary" size="mini" onClick={onRequestDismiss}>
                    {t(messages.extraImageModalCancelButton.id)}
                </Button>
            </StudioModalFooter>
        </StudioModal>
    );
};

DeleteImageModal.displayName = "DeleteImageModal";
