import { defineMessages } from "@vp/i18n-helper";

export const qrCodeMessages = defineMessages({
    panelTitle: {
        id: "easel.components.qrCodes.qrCodePanel.title",
        defaultMessage: "QR Code",
        description: {
            note: "Add QR Code Panel Title"
        }
    },
    editSheetTitle: {
        id: "easel.components.qrCodes.qrCodeSheet.title",
        defaultMessage: "QR Code",
        description: {
            note: "Edit QR Code Panel Title"
        }
    },
    editQrCodeSheetTitle: {
        id: "easel.components.qrCodes.qrCodeSheet.editQrCodeSheetTitle",
        defaultMessage: "Edit QR Code",
        description: {
            note: "Edit QR Code Panel Title"
        }
    },
    buttonTitle: {
        id: "easel.components.qrCodes.qrCodePanel.buttonTitle",
        defaultMessage: "Add QR Code",
        description: {
            note: "Add QR Code button text"
        }
    },
    addQRInstructions: {
        id: "easel.components.qrCodes.qrCodePanel.addQRInstructions",
        defaultMessage: "Enter a valid URL and click the ‘add’ button.",
        description: {
            note: "Instructions for adding a QR code"
        }
    },
    editQRInstructions: {
        id: "easel.components.qrCodes.qrCodePanel.editQRInstructions",
        defaultMessage: "Update the link for your QR code below.",
        description: {
            note: "Instructions for editing a QR code"
        }
    },
    url: {
        id: "easel.components.qrCodes.qrCodeForm.url",
        defaultMessage: "URL",
        description: {
            note: "label for the URL field"
        }
    },
    example: {
        id: "easel.components.qrCodes.qrCodeForm.example",
        defaultMessage: "https://www.example.com/",
        description: {
            note: "Example input"
        }
    },
    exampleForQrCode: {
        id: "easel.components.qrCodes.qrCodeForm.exampleForQrCode",
        defaultMessage: "QR code URL",
        description: {
            note: "Example input"
        }
    },
    required: {
        id: "easel.components.qrCodes.qrCodeForm.required",
        defaultMessage: "(Required)",
        description: {
            note: "additional message for URL field to indicate it is required"
        }
    },
    optional: {
        id: "easel.components.qrCodes.qrCodeDialog.optional",
        defaultMessage: "(Optional)",
        description: {
            note: "additional message for Label field to indicate it is optional"
        }
    },
    invalidURL: {
        id: "easel.components.qrCodes.qrCodeDialog.invalidURL",
        defaultMessage: "Invalid URL",
        description: {
            note: "error message when an invalid URL is entered for the QR code"
        }
    },
    wrongURL: {
        id: "easel.components.qrCodes.qrCodeDialog.wrongURL",
        defaultMessage: "Potentially wrong URL",
        description: {
            note: "error message when an wrong URL is entered for the QR code"
        }
    },
    edit: {
        id: "easel.components.qrCodes.qrCodeEditButton.text",
        defaultMessage: "Edit",
        description: {
            note: "text on the edit button to edit a QR Code"
        }
    },
    cancel: {
        id: "easel.components.qrCodes.qrCodeCancelButton.text",
        defaultMessage: "Cancel",
        description: {
            note: "text on the cancel button on the QR Code Edit Dialog"
        }
    },
    done: {
        id: "easel.components.qrCodes.qrCodeDoneButton.text",
        defaultMessage: "Done",
        description: {
            note: "text on the done button on the QR Code Edit Dialog"
        }
    },
    dialogHeader: {
        id: "easel.components.qrCodes.qrCodeEditor.header",
        defaultMessage: "Edit your QR Code:",
        description: {
            note: "header on the edit QR Code dialog"
        }
    },
    dialogAriaLabel: {
        id: "easel.components.qrCodes.qrCodeEditor.dialogAriaLabel",
        defaultMessage: "Edit your QR Code dialog",
        description: {
            note: "Aria label for edit QR Code dialog"
        }
    },
    ariaCloseButton: {
        id: "easel.components.qrCodes.qrCodeEditor.ariaCloseButton",
        defaultMessage: "Close QR Code Edit dialog"
    },
    panelLoading: {
        id: "easel.components.qrCodes.qrCodePanel.loading",
        defaultMessage: "Loading ..."
    },
    corners: {
        id: "easel.components.qrCodes.qrCodePanel.shapeTab.corners",
        defaultMessage: "Corners"
    },
    square: {
        id: "easel.components.qrCodes.qrCodePanel.shapeTab.square",
        defaultMessage: "Square"
    },
    curved: {
        id: "easel.components.qrCodes.qrCodePanel.shapeTab.curved",
        defaultMessage: "Curved"
    },
    leftLeaf: {
        id: "easel.components.qrCodes.qrCodePanel.shapeTab.leftLeaf",
        defaultMessage: "Left leaf"
    },
    rightLeaf: {
        id: "easel.components.qrCodes.qrCodePanel.shapeTab.rightLeaf",
        defaultMessage: "Right Leaf"
    },
    classyBlocks: {
        id: "easel.components.qrCodes.qrCodePanel.shapeTab.classyBlocks",
        defaultMessage: "Classy blocks"
    },
    dots: {
        id: "easel.components.qrCodes.qrCodePanel.shapeTab.dots",
        defaultMessage: "Dots"
    },
    connectHorizontal: {
        id: "easel.components.qrCodes.qrCodePanel.shapeTab.connectHorizontal",
        defaultMessage: "Connect horizontal"
    },
    urlTab: {
        id: "easel.components.qrCodes.customizationPanel.urlTab",
        defaultMessage: "URL"
    },
    shapeTab: {
        id: "easel.components.qrCodes.customizationPanel.shapeTab",
        defaultMessage: "Shape"
    },
    colorTab: {
        id: "easel.components.qrCodes.customizationPanel.colorTab",
        defaultMessage: "Color"
    },
    extraImageTab: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab",
        defaultMessage: "Extra Images"
    },
    none: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.none",
        defaultMessage: "None"
    },
    scanme: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.scanMe",
        defaultMessage: "Scan me"
    },
    instagram: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.instagram",
        defaultMessage: "Instagram"
    },
    facebook: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.facebook",
        defaultMessage: "Facebook"
    },
    linkedin: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.linkedIn",
        defaultMessage: "LinkedIn"
    },
    twitter: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.twitter",
        defaultMessage: "Twitter"
    },
    review: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.review",
        defaultMessage: "Review"
    },
    youtube: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.youTube",
        defaultMessage: "YouTube"
    },
    google: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.google",
        defaultMessage: "Google"
    },
    watch: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.watch",
        defaultMessage: "Watch"
    },
    imageBtnAriaLabel: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.imageDeleteBtnAriaLabel",
        defaultMessage: "image option for a QR code"
    },
    imageDeleteBtnAriaLabel: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.imageDeleteBtnAriaLabel",
        defaultMessage: "Delete image"
    },
    imagesContentLegend: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.imagesContentLegend",
        defaultMessage: "Select image or icon to add on a QR code"
    },
    updateButtonLabel: {
        id: "easel.components.qrCodes.customizationPanel.updateButtonLabel",
        defaultMessage: "Update",
        description: {
            note: "Add QR Code button text"
        }
    },
    addMoreQRCodeButtonLabel: {
        id: "easel.components.qrCodes.customizationPanel.addMoreQRCodeButtonLabel",
        defaultMessage: "Add Another QR Code",
        description: {
            note: "label for add another QR Code button"
        }
    },
    toastMessage: {
        id: "easel.components.qrCodes.customizationPanel.toastMessage",
        defaultMessage: "URL updated",
        description: {
            note: "alert message for qr code url update"
        }
    },
    updateErrorMessage: {
        id: "easel.components.qrCodes.customizationPanel.updateErrorMessage",
        defaultMessage: "Update failed. Please try again.",
        description: {
            note: "error message for qr code url update failed"
        }
    },
    closeButtonAccessibilityLabel: {
        id: "sims.qrCode.contentDiscoveryZone.closeButtonAccessibilityLabel",
        defaultMessage: "Close",
        description: {
            note: "text for accessibility for the closes button"
        }
    },
    backButtonAccessibilityLabel: {
        id: "sims.qrCode.contentDiscoveryZone.backButtonAccessibilityLabel",
        defaultMessage: "Back",
        description: {
            note: "text for accessibility for the back button"
        }
    },
    uploadOrSelectImage: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.uploadOrSelectImage",
        defaultMessage: "Accepted formats"
    },
    uploadConditions: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.uploadConditions",
        defaultMessage: "PNG, JPEG, or SVG. Files must be under 7MB"
    },
    selectionExtraImage: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.selectionExtraImage",
        defaultMessage: "Selection: "
    },
    imageUploadLoading: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.imageUploadLoading",
        defaultMessage: "loading"
    },
    largeImageError: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.largeImageError",
        defaultMessage: "Upload failed. Image must be under 7MB."
    },
    imageUploadFailed: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.imageUploadFailed",
        defaultMessage: "Upload failed. Please try again."
    },
    invalidExtraImageExtension: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.invalidExtraImageExtension",
        defaultMessage:
            "Oops! Looks like you uploaded a file that we do not support. You can upload files with following extensions:"
    },
    imageUploadButtonText: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.imageUploadButtonText",
        defaultMessage: " Upload logo or image"
    },
    invertButtonAccessibilityLabel: {
        id: "sims.qrCode.colorEditor.invertButtonAccessibilityLabel",
        defaultMessage: "Invert",
        description: {
            note: "Invert QR code colors"
        }
    },
    invertButtonAccessibilityLabelOn: {
        id: "sims.qrCode.colorEditor.invertButtonAccessibilityLabel.on",
        defaultMessage: "On",
        description: {
            note: "Invert QR code colors On status"
        }
    },
    invertButtonAccessibilityLabelOff: {
        id: "sims.qrCode.colorEditor.invertButtonAccessibilityLabel.off",
        defaultMessage: "Invert",
        description: {
            note: "Invert QR code colors Off status"
        }
    },
    changeImageColorButtonAccessibilityLabel: {
        id: "sims.qrCode.colorEditor.changeImageColorButtonAccessibilityLabel",
        defaultMessage: "Change",
        description: {
            note: "change color of the image inside QR code"
        }
    },
    changeImageColorHeadlineAccessibilityLabel: {
        id: "sims.qrCode.colorEditor.changeImageColorHeadlineAccessibilityLabel",
        defaultMessage: "Extra Image",
        description: {
            note: "change button headline"
        }
    },
    sameAsQrCodeButtonAccessibilityLabel: {
        id: "sims.qrCode.colorEditor.sameAsQrCodeButtonAccessibilityLabel",
        defaultMessage: "Same as QR code",
        description: {
            note: "Same as QR code colors"
        }
    },
    extraImageHeadlineAccessibilityLabel: {
        id: "sims.qrCode.colorEditor.extraImageHeadlineAccessibilityLabel",
        defaultMessage: "Extra Image",
        description: {
            note: "Extra Image headline"
        }
    },
    extraImageModalCloseButton: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.uploads.deleteImageDialog.closeButton",
        defaultMessage: "Close Delete Image Dialog"
    },
    extraImageModalDialogTitle: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.uploads.deleteImageDialog.modalDialogTitle",
        defaultMessage: "Delete the image?"
    },
    extraImageModalDialogMessage: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.uploads.deleteImageDialog.modalDialogBody",
        defaultMessage: "Are you sure you want to delete this image?"
    },
    extraImageModalCancelButton: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.uploads.deleteImageDialog.cancelButton",
        defaultMessage: "Cancel"
    },
    extraImageModalConfirmButton: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.uploads.deleteImageDialog.confirmButton",
        defaultMessage: "Delete"
    },
    extraImageModalAriaLabel: {
        id: "easel.components.qrCodes.customizationPanel.extraImageTab.uploads.deleteImageDialog.extraImageModalAriaLabel",
        defaultMessage: "Delete the qr code panel extra image dialog"
    },
    qrCodeSheet: {
        id: "sims.qrCode.qrCodeSmallScreenSheet",
        defaultMessage: "QR Code Small Screen Sheet"
    },
    toolbarColorButtonLabel: {
        id: "sims.qrCode.selectedZoneToolbar.toolbarColorButtonLabel",
        defaultMessage: "Color"
    },
    toolbarEditButtonLabel: {
        id: "sims.qrCode.selectedZoneToolbar.toolbarEditButtonLabel",
        defaultMessage: "Edit"
    }
});
