import { Events, useTrackingClient } from "@internal/utils-tracking";
import { useCallback } from "react";

export type TrackingParameters = {
    hasMultipleSafetyOrTrim: boolean;
    isItemInCart: boolean;
    emptyByConfigurationFlag: boolean;
    isNotPrintDecorationTechnology: boolean;
    disabledByDesignVariations: boolean;
    disabledByDesignAttributes: boolean;
};

const eventPrefix = "sizeAttributes";
const buildEventParameters = (parameters: TrackingParameters) => {
    return Object.entries(parameters)
        .map(([key, value]) => (value ? key : null))
        .filter(Boolean)
        .join(",");
};
const getEventState = (parameters?: TrackingParameters) => {
    return parameters ? `empty:${buildEventParameters(parameters)}` : "available";
};

export const useSizeOptionsAvailabilityTracking = () => {
    const trackingClient = useTrackingClient();

    return useCallback(
        (parameters?: TrackingParameters) => {
            trackingClient.track(Events.ChangeSizeButtonAvailability, {
                eventDetail: `${eventPrefix}:${getEventState(parameters)}`,
                label: `Change size is ${parameters ? "not" : ""} available`
            });
        },
        [trackingClient]
    );
};
