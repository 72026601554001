import { BaseTrackingClient, Events } from "@internal/utils-tracking";
import { getDebouncedByType } from "./debounce";
import { NewrelicError, getScopedNewrelicWrapper } from "@internal/utils-newrelic";
import type { StudioError } from "./types";

interface ConvertedError {
    moduleFunction?: string;
}

export function reportErrorToNewRelic(
    originalError: StudioError,
    convertedError: ConvertedError,
    errorCode: string,
    fullErrorMessage: string,
    cleanErrorMessage: string,
    scope: string
) {
    const errorData = {
        errorCode,
        moduleFunction: convertedError.moduleFunction,
        errorMessage: cleanErrorMessage,
        fullErrorMessage,
        fetchUrl: originalError?.url,
        bodyLength: originalError?.bodyLength
    };

    const newRelicError = new NewrelicError(cleanErrorMessage, {
        code: errorCode,
        error: originalError as Error,
        url: errorData.fetchUrl,
        extra: errorData
    });
    newRelicError.stack = originalError.stack;

    getScopedNewrelicWrapper(scope).noticeError(newRelicError, errorData);
}

function reportRawErrorToSegment(
    message: string | object,
    code: string,
    stack: string,
    trackingClient: BaseTrackingClient,
    errorBoundary = false
) {
    let updatedMessage = message;
    if (typeof updatedMessage === "object" && updatedMessage !== null) {
        try {
            updatedMessage = JSON.stringify(updatedMessage);
        } catch {
            if (
                typeof updatedMessage === "object" &&
                "message" in updatedMessage &&
                typeof updatedMessage.message === "string"
            ) {
                updatedMessage = updatedMessage.message;
            } else {
                updatedMessage = "Error message could not be stringified in reportRawErrorToSegment";
            }
        }
    }
    // filter out some unimportant errors
    if (updatedMessage?.includes("ResizeObserver") || updatedMessage === "Script error.") {
        return;
    }

    trackingClient.track(Events.StudioErrors, {
        event: "Studio Errors",
        eventDetail: errorBoundary ? "Error Page" : "Error",
        label: "Error",
        extraData: (state: any) => ({
            errorMessage: updatedMessage,
            stack,
            errorCode: code,
            easelLoaded: state.easelLoaded
        })
    });
}

export const debouncedReportRawErrorToSegment = getDebouncedByType(reportRawErrorToSegment, 1000);

export function reportErrorToSegment(
    originalError: StudioError,
    convertedError: ConvertedError,
    errorCode: string,
    fullErrorMessage: string,
    cleanErrorMessage: string,
    trackingClient: BaseTrackingClient,
    showErrorPage: boolean
) {
    trackingClient.track(Events.StudioErrors, {
        eventDetail: showErrorPage ? "Error Page" : "Error Toast",
        label: "Error",
        extraData: (state: any) => ({
            errorCode,
            moduleFunction: convertedError.moduleFunction,
            errorMessage: cleanErrorMessage,
            fullErrorMessage,
            easelLoaded: state.easelLoaded,
            fetchUrl: originalError?.url,
            bodyLength: originalError?.bodyLength
        })
    });
}
