import { upgradeCimDoc } from "@design-stack-vista/cimdoc-state-manager";
import { DSS, DTR } from "@vp/types-ddif";

function moveTextFieldIDsToTextAreas(designDocument: DTR.EditDocV2) {
    designDocument.document.panels.forEach((panel): void => {
        panel.textAreas.forEach((textArea): void => {
            // If there are no textFields or the first textField does not have an ID, return
            // This should never happen if the content is valid
            if (textArea.textFields.length < 1 || !textArea.textFields[0].id) {
                return;
            }

            // Set the textArea ID to the first textField's ID
            if (!textArea.id) {
                textArea.id = textArea.textFields[0].id;
            }

            // Remove the ID from the textFields
            textArea.textFields.forEach((textField): void => {
                delete textField.id;
            });
        });
    });
}

function seekAndDestroyText(cimDoc: DTR.EditDocV2): void {
    if (!cimDoc.metadata?.template) {
        return;
    }
    // This will sweep up image placeholders as well -
    //  but because of the individual logic for text & word art, images will get ignored
    const idsWithPlaceholders = cimDoc.metadata.template
        .filter((element: DTR.TemplateMetadata): boolean => !!(element as DTR.TextTemplateMetadataV2).placeholder) // Filter to ids with placeholders
        .map((element: DTR.TemplateMetadata): string => element.id); // Find all the ids of items with placeholders

    cimDoc.document.panels.forEach((panel): void => {
        // Remove content for all the textFields
        panel.textAreas?.forEach((textArea): void => {
            if (textArea.id && idsWithPlaceholders.includes(textArea.id)) {
                textArea.textFields.forEach((textField): void => {
                    textField.content = "";
                });
            }
        });

        // Remove content for all the WordArt fields
        panel.itemReferences
            ?.filter((itemReference): boolean => itemReference.type === "Word Art")
            .filter((wordArt): boolean => idsWithPlaceholders.includes(wordArt.id))
            .forEach((wordArt): void => {
                if (wordArt.data) {
                    wordArt.data.content = "";
                }
            });
    });
}

/**
 * Cleans up documents generated from colormatched documents
 * They resemble templates from DTeR and aren't up-to-date cimdocs
 * Returns a new document
 */
export function cleanupTemplateDocument(colorMatchedTemplate: DTR.EditDocV2): DSS.DesignDocument {
    moveTextFieldIDsToTextAreas(colorMatchedTemplate);
    seekAndDestroyText(colorMatchedTemplate);
    return upgradeCimDoc(colorMatchedTemplate) as DSS.DesignDocument;
}
