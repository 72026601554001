import { ItemPreloaderExtension } from "@internal/sim-framework";
import type { PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { override } from "mobx";
import { name } from "../name";
import { isTeamsNameItemReference } from "../utils";

export class TeamsNamePluginPreloaderPanelExtension extends ItemPreloaderExtension {
    declare designState: PanelState;

    get simName() {
        return name;
    }

    @override
    get shouldPreload() {
        return this.designState.items.some(isTeamsNameItemReference);
    }
}
