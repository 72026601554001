import React, { useEffect, useState } from "react";
import { usePatternsContentManager } from "@design-stack-vista/pattern-sdk";
import { Box } from "@vp/swan";
import { observer } from "mobx-react-lite";
import { Status } from "./Status";
import { DesktopPatternDelete } from "./DesktopPatternDelete";
import { CategorySkeletonLoader } from "./CategorySkeletonLoader";
import { useDesignEngine, getOptionalExtension, useActivePanel } from "@design-stack-vista/core-features";
import { PatternItemExtension } from "@pattern-sim/extensions";
import { CategoryRow } from "./CategoryRow";
interface CategoryListProps {
    handleSelectedCategory: (category: string) => void;
}

export const CategoryList = observer(({ handleSelectedCategory }: CategoryListProps) => {
    const { categories, patternsByCategory } = usePatternsContentManager();
    const { activePanel } = useActivePanel();
    const designEngine = useDesignEngine();
    const patternItemExtension = activePanel && getOptionalExtension(designEngine, activePanel, PatternItemExtension);

    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const [status, setStatus] = useState<Status>(Status.Loading);

    useEffect(() => {
        if (patternItemExtension && categories.length > 0) {
            setStatus(Status.Idle);
        } else {
            setStatus(Status.Loading);
        }
    }, [categories, categories.length, handleSelectedCategory, patternItemExtension, selectedCategory]);

    const handleViewAllClick = (categoryName: string) => {
        setSelectedCategory(categoryName);
        handleSelectedCategory(categoryName);
    };

    return (
        <>
            {status === Status.Loading ? (
                <Box mx={"6"}>
                    <CategorySkeletonLoader quantity={3} />
                </Box>
            ) : (
                <>
                    <Box pt={0} px={"6"} pb={"3"} overflow="auto">
                        {categories.map(category => {
                            /**
                             * There are certain categories in the list that
                             * do not have any patterns associated with them.
                             * We don't want to show them in the list.
                             */
                            const localizedPatternFound =
                                Object.keys(patternsByCategory).length > 0 &&
                                patternsByCategory[category.localizedValue]?.length > 0;

                            if (!localizedPatternFound) {
                                return null;
                            }

                            return (
                                <CategoryRow
                                    category={category}
                                    patternsList={patternsByCategory[category.localizedValue]}
                                    handleViewAllClick={handleViewAllClick}
                                    status={status}
                                    key={`${category.name}-${category.position}`}
                                />
                            );
                        })}
                    </Box>
                    <DesktopPatternDelete />
                </>
            )}
        </>
    );
});
