import { useAvailableDesignEngine } from "@design-stack-vista/core-features";
import type { GetDocument } from "@internal/utils-cimdoc";
import produce from "immer";
// `structuredClone` is not yet supported in Safari 15.0; see https://vistaprint.atlassian.net/wiki/spaces/NTEO/pages/60132385/Vistaprint+browser+support+
// eslint-disable-next-line
import cloneDeep from "lodash/cloneDeep";
import { useCallback } from "react";
import {
    addPlaceholderMetadataToCimDoc,
    isUnreplacedPlaceholderImage
} from "@design-stack-vista/studio-document-metadata-management";
import { CimDoc } from "@design-stack-vista/cdif-types";
import { ERROR_CODES, useErrors } from "@internal/utils-errors";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { filterNonServerRenderableItems } from "../UploadsAndAssets/helpers";
import {
    ENGINE_PROCESSING_COMPLETED_EVENT,
    ENGINE_PROCESSING_TIMEOUT_EVENT,
    isEngineProcessing,
    ProcessingTimeoutError,
    ProcessingType,
    waitForEngineProcessingCompletion
} from "../DesignEngine";

interface Props {
    // Remove and store unreplaced image placeholders
    removeUnreplacedPlaceholders?: boolean;
    /* whether to wait for engine processing to complete, defaults to true */
    waitForProcessingToComplete?: boolean;
    /* if waiting for processing, can provide the types to wait for,  Default to UPLOAD */
    waitForProcessingTypes?: ProcessingType[];
    /**
     * Is the document going to be used for transient purpose
     */
    isTransientDocument?: boolean;
    /* action the user performed that requries document.  Used for tracking. */
    action?: string;
    /* if engine processing timeouts, we may want to show an error toast depending on the action */
    hideProcessingErrorToast?: boolean;
    removeNonServerRenderableItems?: boolean;
}

// By default we only want to wait for Instant Uploads to finish uploading
const DEFAULT_PROCESSING_TYPES = [ProcessingType.Upload];

/**
 * Gets the current CimDoc available from the design engine.
 * @note Only returns a *savable* document, meaning any client-side images (i.e. an image with any "blob:" urls) on the document will not be included
 */
export function useGetDesignDocument({
    removeUnreplacedPlaceholders,
    waitForProcessingToComplete = true,
    waitForProcessingTypes = DEFAULT_PROCESSING_TYPES,
    isTransientDocument = false,
    action,
    hideProcessingErrorToast = true,
    removeNonServerRenderableItems = true
}: Props) {
    const designEngine = useAvailableDesignEngine();
    const trackingClient = useTrackingClient();
    const { handleError } = useErrors();

    return useCallback<GetDocument>(async () => {
        if (!designEngine) {
            throw new Error("Cannot get document without design engine");
        }

        if (waitForProcessingToComplete && isEngineProcessing(designEngine, waitForProcessingTypes)) {
            const start = performance.now();
            try {
                // Waits for any items that are processing to complete before getting the document
                await waitForEngineProcessingCompletion(designEngine, waitForProcessingTypes);
                if (action) {
                    trackingClient.track(Events.InteractionTimed, {
                        eventName: ENGINE_PROCESSING_COMPLETED_EVENT,
                        timing: performance.now() - start,
                        extraData: {
                            action: `getDocument:${action}`,
                            types: waitForProcessingTypes
                        }
                    });
                }
            } catch (e) {
                if (e instanceof ProcessingTimeoutError) {
                    // Handle the error, but continue getting the document to prevent breaking behavior.
                    // This may result in some changes (e.g. Instant Uploads and Image Processing) being lost.
                    handleError(
                        e,
                        ERROR_CODES.DESIGN_ENGINE_PROCESSING_TIMEOUT,
                        ENTITY_CODE,
                        false,
                        true,
                        hideProcessingErrorToast
                    );
                    if (action) {
                        trackingClient.track(Events.InteractionTimed, {
                            eventName: ENGINE_PROCESSING_TIMEOUT_EVENT,
                            timing: performance.now() - start,
                            extraData: {
                                action: `getDocument:${action}`,
                                types: waitForProcessingTypes
                            }
                        });
                    }
                }
            }
        }

        let newDocument = cloneDeep(await designEngine.getCimDoc());
        // TODO: add validations

        // save image placeholders if they're being removed
        if (removeUnreplacedPlaceholders) {
            newDocument = removeUnreplacedPlaceholdersFromCimDoc(newDocument);
        }

        if (removeNonServerRenderableItems) {
            newDocument = filterNonServerRenderableItems(newDocument);
        }

        // The above commands may freeze the document.  That can cause issues elsewhere in studio that are trying to manipulate the document
        // Seeing quite a few errors in NR scattered throughout studio so just cloning it here
        const retDoc = cloneDeep(newDocument);

        // if document is going to be used for transient save then remove metadata like id, owner and tenant info.
        // Those aren't useful for transient docs and this reduces size
        if (isTransientDocument) {
            delete retDoc.documentId;
            delete retDoc.owner;
            delete retDoc.tenant;
        }

        return retDoc;
    }, [
        designEngine,
        waitForProcessingToComplete,
        waitForProcessingTypes,
        removeUnreplacedPlaceholders,
        removeNonServerRenderableItems,
        isTransientDocument,
        action,
        trackingClient,
        hideProcessingErrorToast,
        handleError
    ]);
}

export const removeUnreplacedPlaceholdersFromCimDoc = (cimDoc: CimDoc): CimDoc => {
    const newDocument = addPlaceholderMetadataToCimDoc(cimDoc);
    return produce(newDocument, draftCimdoc => {
        const placeholderImageIds = (newDocument.metadata?.template?.filter(isUnreplacedPlaceholderImage) ?? []).map(
            imageTemplate => imageTemplate.id
        );
        draftCimdoc.document.panels.forEach(panel => {
            // Remove any images from the document that are placeholder images
            // eslint-disable-next-line no-param-reassign
            panel.images = panel.images?.filter(image => !placeholderImageIds.includes(image.id)) ?? [];
        });
    });
};
