import type { PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { ItemPreloaderExtension } from "@internal/sim-framework";
import { override } from "mobx";
import { name } from "../name";
import { isQrCodeTypeReference } from "../shared/utils";

export class QRCodePluginPreloaderPanelExtension extends ItemPreloaderExtension {
    declare designState: PanelState;

    get simName() {
        return name;
    }

    @override
    get shouldPreload() {
        return this.designState.items.some(item => isQrCodeTypeReference(item));
    }
}
