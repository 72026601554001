import React from "react";
import { withPrefix } from "gatsby";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Carousel, CarouselSlide, Typography, ResponsiveImage } from "@vp/swan";
import styles from "./MobileCarousel.module.scss";
import { CAROUSEL_DATA } from "../../common";

const messages = defineMessages({
    carouselPreviousLabel: {
        id: "sims.teamsName.contentDiscoveryZone.howToDialog.carouselPreviousLabel",
        defaultMessage: "Go to previous slide"
    },
    carouselNextLabel: {
        id: "sims.teamsName.contentDiscoveryZone.howToDialog.carouselNextLabel",
        defaultMessage: "Go to next slide"
    },
    carouselStep: {
        id: "sims.teamsName.contentDiscoveryZone.howToDialog.carouselStep",
        defaultMessage: "Step [[currentStep]] of 3"
    }
});

export const MobileCarousel = () => {
    const { t } = useTranslationSSR();

    return (
        <Carousel
            className={styles.overflowVisible}
            skin="full"
            accessibleTextForPrevious={t(messages.carouselPreviousLabel.id)}
            accessibleTextForNext={t(messages.carouselNextLabel.id)}
        >
            {CAROUSEL_DATA.map(({ localeKey, imgURL, step }) => (
                <CarouselSlide key={localeKey}>
                    <ResponsiveImage
                        src={ASSET_PREFIX + withPrefix(imgURL)}
                        alt={t(localeKey)}
                        className={styles.image}
                    />
                    <Typography fontSize="standard" fontWeight="bold" textAlign="center" mb={"2"}>
                        {t(messages.carouselStep.id, { currentStep: step })}
                    </Typography>
                    <Typography fontSize="standard" textAlign="center">
                        {t(localeKey)}
                    </Typography>
                </CarouselSlide>
            ))}
        </Carousel>
    );
};
