import isURL from "validator/lib/isURL";
import { FileTypeMapping } from "../types";
// eslint-disable-next-line no-restricted-imports -- FIXME use scopedNewrelicWrapper
import { newRelicWrapper } from "@internal/utils-newrelic";

//Image upload validation
const MEGA_BYTE = 1000000; // 1MB
const imageMaxSize = 7 * MEGA_BYTE;

export const supportedImageFileTypes: FileTypeMapping[] = [
    {
        fileType: "image/jpeg",
        extensions: ["jpg", "jpeg", "jfif"]
    },
    {
        fileType: "image/png",
        extensions: ["png"]
    },
    {
        fileType: "image/svg+xml",
        extensions: ["svg"]
    }
];
const acceptedImageExtensions = [
    ...new Set(supportedImageFileTypes.map(item => item.extensions).reduce((array, item) => array.concat(item), []))
];

export const acceptedImageTypes = [...new Set(supportedImageFileTypes.map(item => item.fileType))];

export const getFileExtension = (fileName: string): string => {
    const splitName = fileName.split(".");
    return splitName[splitName.length - 1].toLocaleLowerCase();
};

export const isImageSizeValid = (file: File): boolean => {
    if (file.size > imageMaxSize) {
        newRelicWrapper.noticeError(new Error("Image size exceeded maximum size"), {
            imageSize: file.size / MEGA_BYTE,
            imageMaxSize: imageMaxSize
        });
        return false;
    }
    return true;
};

export const isImageExtensionValid = (file: File): boolean => {
    const fileExtension = getFileExtension(file.name);
    if (!acceptedImageExtensions.includes(fileExtension)) {
        newRelicWrapper.noticeError(new Error("Image type is not supported"), {
            fileType: file.type
        });
        return false;
    }
    return true;
};

export function checkUrlValidity(str: string) {
    return isURL(str, { protocols: ["http", "https"], require_protocol: false, require_valid_protocol: true });
}
