import React from "react";
import classNames from "classnames";
import { withPrefix } from "gatsby";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Column, FlexBox, GridContainer, ModalDialogBody, ResponsiveImage, Row, Typography } from "@vp/swan";
import styles from "./DesktopBody.module.scss";
import { CAROUSEL_DATA } from "../../common";

export const DesktopBody = () => {
    const { t } = useTranslationSSR();

    return (
        <ModalDialogBody my={"7"}>
            <GridContainer>
                <Row>
                    {CAROUSEL_DATA.map(({ localeKey, step, isCentral }) => (
                        <Column key={localeKey} span={4}>
                            <FlexBox
                                justifyContent="center"
                                alignItems="center"
                                mx="auto"
                                className={classNames(styles.circle, {
                                    [styles.line]: isCentral
                                })}
                            >
                                <Typography fontWeight="bold">{step}</Typography>
                            </FlexBox>
                        </Column>
                    ))}
                </Row>
                <Row mt={"5"} mb={"4"}>
                    {CAROUSEL_DATA.map(({ localeKey, imgURL }) => (
                        <Column key={localeKey} span={4} verticallyCenterContent textAlign="center">
                            <ResponsiveImage
                                src={ASSET_PREFIX + withPrefix(imgURL)}
                                alt={t(localeKey)}
                                className={styles.image}
                            />
                        </Column>
                    ))}
                </Row>
                <Row>
                    {CAROUSEL_DATA.map(({ localeKey }) => (
                        <Column key={localeKey} span={4}>
                            <Typography className={styles.text} fontSize="small" textAlign="center">
                                {t(localeKey)}
                            </Typography>
                        </Column>
                    ))}
                </Row>
            </GridContainer>
        </ModalDialogBody>
    );
};
