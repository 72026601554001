import { TemplateType, AppDispatch, Store, loadSurfaceUpsells } from "@shared/redux";
import keyBy from "lodash/keyBy";

/**
 * Update selected value of the matching surface upsell
 * @param {String} panelName name of the current panel
 * @param {String} selectedTemplate valid template token, create your own design, or blank
 */
export const setSelectedSurfaceUpsellTemplate = (panelName: string, selectedTemplate: TemplateType | string) => {
    return async (dispatch: AppDispatch, getState: typeof Store.getState) => {
        const { surfaceUpsells } = getState();

        if (surfaceUpsells[panelName]) {
            const updatedSurfaceUpsells = {
                ...surfaceUpsells,
                [panelName]: {
                    ...surfaceUpsells[panelName],
                    selectedTemplate
                }
            };

            dispatch(loadSurfaceUpsells(keyBy(updatedSurfaceUpsells, upsell => upsell.panelName)));
        }
    };
};
