import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { ColorPickerPanel, ItemColorsWrapper } from "@internal/feature-color-picker";
import { PatternItemExtension } from "@pattern-sim/extensions";
import {
    convertArrayToSelectableColors,
    getColorOverrideKey,
    getInitialColorOverrides,
    rgbString2hex,
    cmykString2hex,
    SelectableColor
} from "@internal/utils-color";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useDesignEngine, useActivePanel, getOptionalExtension } from "@design-stack-vista/core-features";
import {
    getColorsArrayFromPatternUrl,
    getPatternColorsFromSvgUrl,
    patternMessage as messages,
    replaceColorAndGetSVGPathGroup
} from "@pattern-sim/shared";
import { applyPatternColor } from "../../commands";
import { reaction } from "mobx";
import { ColorGroup } from "@design-stack-vista/pattern-sdk";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { useActiveDialogSetters } from "@internal/utils-active-dialog";
import { dialogTypes } from "dialogTypes";
import styles from "./EditColors.module.scss";
import { useSims } from "@internal/sim-framework";

export const EditColors = observer(() => {
    const { t } = useTranslationSSR();
    const [selectedColorIndex, setSelectedColorIndex] = useState<number>(-1);
    const designEngine = useDesignEngine();
    const { isSmall } = useStudioLayout();
    const { setCurrentActiveDialog } = useActiveDialogSetters();
    const simsManager = useSims();

    const { activePanel } = useActivePanel();

    const patternItemExtension = activePanel && getOptionalExtension(designEngine, activePanel, PatternItemExtension);

    if (!patternItemExtension) {
        return null;
    }

    const { initialPatternColors, setInitialPatternColors, setInitialPatternUrl, initialPatternUrl } =
        patternItemExtension;

    const [patternColors, setPatternColors] = useState<string[]>(initialPatternColors);

    const patternItem = patternItemExtension?.patternItem;
    const patternId = patternItem?.model.id;

    const selectableColors = convertArrayToSelectableColors(initialPatternColors, patternColors);
    const colorOverridesList = useMemo(() => getInitialColorOverrides(initialPatternColors), [initialPatternColors]);
    const selectedColor = selectedColorIndex >= 0 ? patternColors[selectedColorIndex] : undefined;
    const selectedColorOverride = selectedColorIndex >= 0 ? colorOverridesList[selectedColorIndex] : undefined;

    useEffect(() => {
        if (!patternItem) {
            setCurrentActiveDialog(dialogTypes.pattern);
            return;
        }
        const { patternUrl } = patternItemExtension;

        // set colors from patternItemExtension's URL the first time if a url exists
        const colorsArr = getColorsArrayFromPatternUrl(patternUrl);
        setPatternColors([...(colorsArr?.length ? colorsArr : initialPatternColors)]);

        // mobx reaction to watch for url change in the cimdoc through PatternItemExtension, and reflect the colors accordingly
        const reactionDisposer = reaction(
            () => patternItemExtension?.patternUrl,
            newUrl => {
                if (newUrl) {
                    const colorsArr = getColorsArrayFromPatternUrl(newUrl);
                    setPatternColors([...(colorsArr?.length ? colorsArr : initialPatternColors)]);
                }
            }
        );

        // Run on load => for when we load a saved document that has patterns already
        if (patternItemExtension?.patternUrl && initialPatternColors?.length === 0) {
            const { patternUrl } = patternItemExtension;
            const colorsArr = getColorsArrayFromPatternUrl(patternUrl);
            let fileUrl = patternUrl;
            // If the saved pattern has its colors already overridden
            if (colorsArr?.length) {
                // Get the URL without color overrides
                fileUrl = new URLSearchParams(new URL(patternUrl).search).get("fileUrl");
                setPatternColors([...colorsArr]);
            }
            setInitialPatternUrl(fileUrl);
            (async () => {
                const svgColors = await getPatternColorsFromSvgUrl(fileUrl);
                setInitialPatternUrl(fileUrl);
                setInitialPatternColors([...(svgColors ?? [])]);
            })();
        }

        // clean up mobx reaction
        return reactionDisposer;
    }, [
        initialPatternColors,
        patternItemExtension,
        setInitialPatternUrl,
        setInitialPatternColors,
        patternItem,
        setCurrentActiveDialog,
        activePanel?.id
    ]);

    const onSelectColor = useCallback(
        (color: SelectableColor) => {
            if (!colorOverridesList) {
                return;
            }
            const index = colorOverridesList.findIndex(item => {
                return getColorOverrideKey(item) === color.value;
            });

            if (index !== -1) {
                setSelectedColorIndex(index);
            }
        },
        [colorOverridesList, setSelectedColorIndex]
    );

    const handleOnChange = useCallback(
        (cdifColor: string) => {
            const newColor = cdifColor.startsWith("cmyk") ? cmykString2hex(cdifColor) : rgbString2hex(cdifColor);

            const colorGroup = replaceColorAndGetSVGPathGroup(
                patternColors,
                newColor,
                selectedColorIndex
            ) as ColorGroup;

            if (patternId) {
                designEngine.executeCommand(applyPatternColor, {
                    itemId: patternId,
                    newColors: colorGroup,
                    url: initialPatternUrl
                });
            }
        },
        [designEngine, initialPatternUrl, patternColors, patternId, selectedColorIndex]
    );

    return (
        <ColorPickerPanel
            className={styles.editColorStyle}
            title={isSmall ? undefined : t(messages.patternColorsTitle.id)}
            color={selectedColor}
            onChange={handleOnChange}
            renderItemColors={
                selectableColors?.length && selectableColors.length > 0 ? (
                    <ItemColorsWrapper
                        colorsList={selectableColors}
                        selectedColor={selectedColorOverride}
                        onSelectColor={onSelectColor}
                    />
                ) : undefined
            }
            showColorPickerPanelWrapper={selectedColorIndex >= 0}
            enableToggleBackground={simsManager.studioConfigurationManagerDelegate.data.enableToggleBackground ?? false}
        />
    );
});

EditColors.displayName = "EditColors";
