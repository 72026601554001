import { useCreateDefaultLogoData } from "@internal/dex-framework";

export { sims } from "./sims";
export { useStudioConfigurationManager } from "./StudioConfigurationManager/useStudioConfigurationManager";
export { useHandleErrorPageForLocale } from "./StudioConfigurationManager/useHandleErrorPageForLocale";
export const useCreateLogoData = useCreateDefaultLogoData;
export { ProductLoadingProvider, useProductLoadingContext } from "./ProductLoadingProvider";
export const studioTitle = "VistaPrint | Studio";
export { workTenant } from "./workTenant";
export { udsTenant } from "./udsTenant";
export { useCreateSecondaryConfigQueryOptions } from "./SecondaryConfig";
export { useSave } from "./Save";
export { useCustomContinueAction } from "./CustomContinueAction";
