import React from "react";
import type { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";
import { PatternIcon } from "@internal/ui-studio-chrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import { patternMessage } from "@pattern-sim/shared";
import { PatternItemExtension } from "extensions";
import { useActivePanel, useDesignEngine, getOptionalExtension } from "@design-stack-vista/core-features";

export const PatternContentDiscoveryZoneButton: ContentDiscoveryZoneButton = ({ screenSize, children }) => {
    const strokeColor = screenSize === "smallScreen" ? "white" : "black";
    const { activePanel } = useActivePanel();
    const designEngine = useDesignEngine();
    const patternItemExtension = activePanel && getOptionalExtension(designEngine, activePanel!, PatternItemExtension);
    const itemBlocksPattern = patternItemExtension && patternItemExtension?.itemBlocksPattern;

    const { t } = useTranslationSSR();

    if (itemBlocksPattern) {
        return null;
    }

    return children({
        icon: <PatternIcon aria-hidden="true" color={strokeColor} />,
        label: t(patternMessage.patternTitleLabel.id)
    });
};
