import { defineMessages } from "@vp/i18n-helper";

export const vectorMessages = defineMessages({
    panelTitle: {
        id: "sims.vector.contentDiscoveryZone.panelTitle",
        defaultMessage: "Clipart",
        description: {
            note: "Title for the clipart section of the graphics panel"
        }
    },
    showMoreDescription: {
        id: "sims.vector.contentDiscoveryZone.showMoreDescription",
        defaultMessage: "Show more clipart",
        description: {
            note: "Aria label for button to show more clipart"
        }
    },
    searchPlaceholder: {
        id: "sims.vector.contentDiscoveryZone.searchPlaceholder",
        defaultMessage: "Search for clipart...",
        description: {
            note: "Placeholder text shown in the search text field, before a user starts to type"
        }
    },
    searchButton: {
        id: "sims.vector.contentDiscoveryZone.searchButton",
        defaultMessage: "Search clipart",
        description: {
            note: "Label for the search button for clipart"
        }
    }
});
