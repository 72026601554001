import {
    previewOpen,
    designReviewState,
    cropImageModalState,
    studioErrorDialogOpen,
    reviewInstructions,
    isLoading,
    warnings,
    easelLoaded,
    surfaceUpsellsLoadedState,
    surfaceUpsells,
    currency,
    saveSuccess,
    saveStatus,
    switchingProject,
    loginReason,
    nextStepUrl,
    predictedSteps,
    pricingInitialized,
    scenesConfiguration,
    matchingTemplates,
    showSaveAs,
    isDuplicatingDesign,
    isRenamingProject,
    showFullBleedPremiumFinishErrorModal,
    alerts,
    showRulers,
    showSafetyArea,
    showBleed,
    showGridlines,
    hideSaveToast,
    showMyProjects,
    trackModalTransitionEvent,
    changeDesignData,
    showPDFProofModal,
    showSpecsAndTemplate,
    processingPDF,
    isPremiumFinishModalOpen,
    premiumFinishModalOpenContext,
    isPremiumFinishWarningOn,
    showFontBrowser,
    showTeamsGuideModal,
    hideAlertToast,
    embroideryImageColorModalOpen,
    singleColorImageModalOpen,
    showImageSearchFiltersPanel,
    currentImageTab,
    editInCartModal,
    loadingMessage,
    shouldSaveOnChange,
    showValidations,
    showDesignIssues,
    showReplaceImageModal,
    showPremiumFinishMaskUploadModal,
    specsAndTemplateModalViewed,
    isReplaceOpenOnCanvasChange,
    showTableEditorModal,
    confirmImageDeletion,
    status,
    isChangingTemplate,
    isUploadModalAutoLoaded,
    tracking,
    hasDesigns,
    designDialogsSeen,
    showBlankDocumentAlert
} from "./slices";

export const reducers = {
    previewOpen,
    designReviewState,
    cropImageModalState,
    studioErrorDialogOpen,
    reviewInstructions,
    isLoading,
    warnings,
    easelLoaded,
    surfaceUpsellsLoadedState,
    surfaceUpsells,
    currency,
    saveSuccess,
    saveStatus,
    switchingProject,
    loginReason,
    nextStepUrl,
    predictedSteps,
    pricingInitialized,
    scenesConfiguration,
    matchingTemplates,
    showSaveAs,
    isDuplicatingDesign,
    isRenamingProject,
    showFullBleedPremiumFinishErrorModal,
    alerts,
    showRulers,
    showSafetyArea,
    showBleed,
    showPremiumFinishMaskUploadModal,
    showGridlines,
    hideSaveToast,
    showMyProjects,
    trackModalTransitionEvent,
    changeDesignData,
    showPDFProofModal,
    showSpecsAndTemplate,
    processingPDF,
    isPremiumFinishModalOpen,
    premiumFinishModalOpenContext,
    isPremiumFinishWarningOn,
    showFontBrowser,
    showTeamsGuideModal,
    hideAlertToast,
    embroideryImageColorModalOpen,
    singleColorImageModalOpen,
    showImageSearchFiltersPanel,
    currentImageTab,
    editInCartModal,
    loadingMessage,
    shouldSaveOnChange,
    showValidations,
    showDesignIssues,
    showReplaceImageModal,
    specsAndTemplateModalViewed,
    isReplaceOpenOnCanvasChange,
    showTableEditorModal,
    confirmImageDeletion,
    status,
    isChangingTemplate,
    isUploadModalAutoLoaded,
    tracking,
    hasDesigns,
    designDialogsSeen,
    showBlankDocumentAlert
};
