import { type OpenContentPanelAfterItemSelectionCallbackProps } from "@internal/sim-framework";
import { prototypeActiveDialogTypes } from "../prototypeContentSymbols";

export function openContentPanelAfterItemSelectionCallback({
    firstSelectedItem
}: OpenContentPanelAfterItemSelectionCallbackProps) {
    if (firstSelectedItem.isShapeItem()) {
        return prototypeActiveDialogTypes["thing A"];
    }
    return undefined;
}
