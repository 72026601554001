import { useDesignEngine, useActivePanel, getOptionalExtension } from "@design-stack-vista/core-features";
import { Category, type Pattern } from "@design-stack-vista/pattern-sdk";
// eslint-disable-next-line no-restricted-imports -- FIXME use scopedNewrelicWrapper
import { newRelicWrapper } from "@internal/utils-newrelic";
import { ContentBoxButton, ContentBoxContext, ContentBoxItem, SelectionState } from "@vp/diy-editor-ui";
import { Img } from "@vp/swan";
import { PatternItemExtension } from "extensions";
import { useBackgroundPattern } from "hooks";
import React, { useCallback, useMemo } from "react";
import { ElementsRow } from "./ElementsRow";
import { Status } from "./Status";
import styles from "./CategoryRow.module.scss";
import { observer } from "mobx-react-lite";

interface CategoryRowProps {
    category: Category;
    patternsList: Pattern[];
    handleViewAllClick: (categoryName: string) => void;
    status: Status;
}

export const CategoryRow = observer(({ category, patternsList, handleViewAllClick, status }: CategoryRowProps) => {
    const { addPattern } = useBackgroundPattern();
    const designEngine = useDesignEngine();
    const { activePanel } = useActivePanel();
    const patternItemExtension = activePanel && getOptionalExtension(designEngine, activePanel, PatternItemExtension);

    const selectedPatternID = patternsList.find(
        pattern => patternItemExtension?.patternFileUrl === pattern.fileUrl
    )?.id;

    const handlePatternAdd = useCallback(
        (patternID: SelectionState) => {
            if (!patternID) {
                return;
            }
            const pattern = patternsList.find(({ id }) => id === patternID);
            if (!pattern) {
                return;
            }
            try {
                addPattern(pattern);
            } catch (error) {
                newRelicWrapper.noticeError(error as Error, {
                    category: category.name,
                    patternID
                });
            }
        },
        [addPattern, category, patternsList]
    );

    const top10Patterns: Pattern[] = patternsList.slice(0, 10);

    const contentBoxContextValue = useMemo(
        () => ({
            allowDelete: false,
            allowMultiSelect: false,
            layout: "standard" as "standard" | "masonry",
            selectionState: selectedPatternID ?? null,
            onSelectionStateChange: (patternID: SelectionState) => handlePatternAdd(patternID)
        }),
        [handlePatternAdd, selectedPatternID]
    );

    return (
        // TODO: diy editor ui lib should extent the ContentBox to support this without customizing it this way
        <ContentBoxContext.Provider value={contentBoxContextValue}>
            <ElementsRow
                handleViewAllClick={() => handleViewAllClick(category.localizedValue)}
                rowTitle={category.localizedValue}
                showViewAll={true}
                status={status}
            >
                {top10Patterns.map((pattern, patternIndex) => {
                    const isPatternSelected = selectedPatternID === pattern.id;
                    return (
                        // implement the css adjustments here
                        <ContentBoxItem value={pattern.id} key={pattern.id}>
                            <ContentBoxButton
                                aria-label={`${category.localizedValue} ${patternIndex}`}
                                aria-pressed={isPatternSelected}
                                className={styles.contentBoxButton}
                            >
                                <Img key={pattern.id} src={pattern.thumbnailUrl} />
                            </ContentBoxButton>
                        </ContentBoxItem>
                    );
                })}
            </ElementsRow>
        </ContentBoxContext.Provider>
    );
});
