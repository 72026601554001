import { parseQueryParams } from "@design-stack-vista/utility-core";
import {
    ColorGroup,
    getColorsFromPatternUrl,
    getColorsFromSvgData,
    getSvgDataWithColorOverride
} from "@design-stack-vista/pattern-sdk";

export const getPatternColorsFromSvgUrl = async (patternUrl: string) => {
    try {
        const hasColorInUrl = !!parseQueryParams(patternUrl).colorGroup;

        let colorGroup: ColorGroup = {};
        if (hasColorInUrl) {
            colorGroup = getColorsFromPatternUrl(patternUrl);
        }

        const uploadFileUrl = parseQueryParams(patternUrl).fileUrl || patternUrl;
        const svgData = await getSvgDataWithColorOverride(uploadFileUrl, colorGroup);

        if (!svgData) {
            return;
        }
        return getColorsFromSvgData(svgData);
    } catch {
        throw new Error("Could not extract data from pattern SVG");
    }
};

export const getColorsArrayFromPatternUrl = (patternUrl: string) => {
    const svgColors = getColorsFromPatternUrl(patternUrl);
    return Object.entries(svgColors).map(color => color[1]);
};

export const replaceColorAndGetSVGPathGroup = (colors: string[], colorToReplace: string, colorIndex: number): object =>
    colors.reduce((acc, currentColor, index) => {
        const patternColor = index === colorIndex ? colorToReplace : currentColor;
        return { ...acc, [`pathgroup${index + 1}`]: patternColor };
    }, {});
