import { UnitlessDimensions, getRandomPositionWithinArea } from "@design-stack-vista/utility-core";
import { useDesignEngine } from "@design-stack-vista/core-features";
import { PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { InteractiveDesignEngine } from "@design-stack-vista/interactive-design-engine-core";
import { addVectorItem } from "../commands";
import { BoundingBox, getDefaultSizeForShape, getLargestSafeArea, getPanel, getPanelColors } from "../shared/utils";
import { CoordinatesInMm } from "@design-stack-vista/cdif-types";
import { getFullColorOverridesWithDefaultValues, getInitialColorOverrides } from "@internal/utils-color";
import { COLOR_SWATCHES_LIMIT } from "../constants";
import { mergeColorOverrides } from "../shared/utils/mergeColorOverrides";

export interface getDimensionsParams {
    designEngine: InteractiveDesignEngine;
    currentPanel: PanelState;
}

const getPosition = (
    designEngine: InteractiveDesignEngine,
    currentPanel: PanelState,
    naturalDimensions: UnitlessDimensions,
    safeAreaDimensions: BoundingBox,
    location?: CoordinatesInMm
) => {
    const { width, height } = getDefaultSizeForShape({
        currentPanel,
        designEngine,
        initialDimensions: naturalDimensions
    });

    const { x, y } = getRandomPositionWithinArea(safeAreaDimensions, {
        width,
        height
    });

    return {
        width: `${width}mm`,
        height: `${height}mm`,
        x: `${location ? location.x - width / 2 : safeAreaDimensions.x + x}mm`,
        y: `${location ? location.y - height / 2 : safeAreaDimensions.y + y}mm`
    };
};

const getColorOverrides = (colorValues: string[]) => {
    return getInitialColorOverrides(colorValues).slice(0, COLOR_SWATCHES_LIMIT);
};

const getColorOverridesFromSubpanel = async (colorValues: string[], subpanelUrl: string) => {
    const panelTask = getPanel(subpanelUrl);
    const initialColorOverrides = getInitialColorOverrides(colorValues);
    const panel = await panelTask;
    const colors = getPanelColors(panel);
    const overrides = getFullColorOverridesWithDefaultValues(colors);

    return mergeColorOverrides(initialColorOverrides, overrides, true).slice(0, COLOR_SWATCHES_LIMIT);
};

export function useAddVector() {
    const designEngine = useDesignEngine();
    const { executeCommand, idaStore, cimDocStore } = designEngine;
    const currentPanelId = idaStore.activeDesignPanelId ?? designEngine.cimDocStore.panels[0].id;
    const currentPanel = cimDocStore.panels.find(p => p.panelProperties.id === currentPanelId);
    if (!currentPanel) {
        throw new Error(`no active panel`);
    }

    const safeAreaDimensions = getLargestSafeArea(designEngine, currentPanel);

    return async (
        subpanelUrl: string,
        colorValues: string[],
        naturalDimensions: UnitlessDimensions,
        location?: CoordinatesInMm
    ) => {
        const position = getPosition(designEngine, currentPanel, naturalDimensions, safeAreaDimensions, location);
        const colorOverrides = colorValues.length
            ? await getColorOverridesFromSubpanel(colorValues, subpanelUrl)
            : getColorOverrides(colorValues);
        const { commandOutput } = executeCommand(addVectorItem, {
            position,
            panelId: idaStore.activeDesignPanelId ?? designEngine.cimDocStore.panels[0].id,
            data: {
                subpanelUrl,
                colorOverrides
            }
        });
        const newItem = commandOutput;

        idaStore.setSelectedIds([newItem.id]);
    };
}
