import React from "react";
import { observer } from "mobx-react-lite";
import { patternMessage } from "@pattern-sim/shared";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Icon, Button } from "@vp/swan";
import { useDesignEngine, useActivePanel, getOptionalExtension } from "@design-stack-vista/core-features";
import { useBackgroundPattern } from "@pattern-sim/hooks";
import { PatternItemExtension } from "@pattern-sim/extensions";
import { useStudioLayout } from "@internal/feature-responsive-design";

export const DeletePattern = observer(() => {
    const designEngine = useDesignEngine();
    const { activePanel } = useActivePanel();
    const { removePattern } = useBackgroundPattern();
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();

    const patternItemExtension = activePanel && getOptionalExtension(designEngine, activePanel, PatternItemExtension);

    const handleRemovePattern = () => {
        removePattern();
    };

    return (
        <>
            {patternItemExtension?.patternItem && patternItemExtension?.patternItem.iid && (
                <Button size="mini" width="full-width" iconPosition="left" onClick={handleRemovePattern}>
                    {isSmall && <Icon iconType="deleteStudio" />}
                    {t(patternMessage.deletePattern.id)}
                </Button>
            )}
        </>
    );
});
