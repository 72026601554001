import type { Dimensions } from "@design-stack-vista/ida-framework";
import { getImageAsPromise } from "@design-stack-vista/utility-core";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { getImageAsPromiseFromSvg } from "../../shared/getImageAsPromiseFromSvg";
import { useTranslationSSR } from "@vp/i18n-helper";
import { patternMessage } from "@pattern-sim/shared";

export interface EditPatternItemPreviewProps {
    show: boolean;
    patternFileUrl?: string;
    patternTileWidth: number;
    patternTileHeight: number;
    width: number;
    height: number;
    repeatPattern: boolean;
    isUrlSvg: boolean;
    svgData?: string;
    originalPreviewUrl?: string;
    fallbackToRenderingPreview: () => void;
}

export const PatternItemPreview = ({
    show,
    isUrlSvg,
    svgData,
    height,
    width,
    patternFileUrl,
    patternTileWidth,
    patternTileHeight,
    fallbackToRenderingPreview
}: EditPatternItemPreviewProps) => {
    const visibleCanvasRef = useRef<HTMLCanvasElement>(null);
    const { t } = useTranslationSSR();

    const patternTileDimension = useMemo(() => {
        return { width: patternTileWidth, height: patternTileHeight };
    }, [patternTileHeight, patternTileWidth]);

    const modificationCanvasRef = useRef<HTMLCanvasElement | null>(null);
    if (!modificationCanvasRef.current) {
        modificationCanvasRef.current = document.createElement("canvas");
    }

    const repeatImageOnCanvas = (
        canvas: HTMLCanvasElement,
        tileDimension: Dimensions,
        image: HTMLCanvasElement | HTMLImageElement
    ) => {
        const context = canvas.getContext("2d");
        const { height: tileDimensionHeight, width: tileDimensionWidth } = tileDimension;
        // Draw tile image by repeating them horizontally and then vertically . Drawing the pattern row by row.
        if (context) {
            for (let heightOffset = 0; heightOffset < canvas.height; heightOffset += tileDimensionHeight) {
                for (let widthOffset = 0; widthOffset < canvas.width; widthOffset += tileDimensionWidth) {
                    context.drawImage(image, widthOffset, heightOffset, tileDimensionWidth, tileDimensionHeight);
                }
            }
        }
    };

    const drawCanvas = useCallback(async () => {
        if (patternFileUrl) {
            // convert svg data into image and draw it on canvas
            if (visibleCanvasRef.current && modificationCanvasRef.current) {
                try {
                    let image;
                    if (!isUrlSvg) {
                        image = await getImageAsPromise(patternFileUrl);
                    } else if (svgData) {
                        image = await getImageAsPromiseFromSvg(svgData, patternTileDimension);
                    }

                    if (image) {
                        const visibleCanvas = visibleCanvasRef.current;
                        if (visibleCanvas) {
                            visibleCanvas.height = height;
                            visibleCanvas.width = width;
                        }
                        // creating temporary canvas for image modification
                        const modificationCanvas = modificationCanvasRef.current;
                        modificationCanvas.height = height;
                        modificationCanvas.width = width;

                        const context = modificationCanvas.getContext("2d")!;
                        context.clearRect(0, 0, modificationCanvas.width, modificationCanvas.height);
                        // repeat the pattern tile image on temporary canvas using patternTileDimension to get the bigger image
                        repeatImageOnCanvas(modificationCanvas, patternTileDimension, image);

                        const visibleContext = visibleCanvas.getContext("2d");
                        if (visibleContext) {
                            visibleContext.drawImage(modificationCanvas, 0, 0, width, height);
                        }
                    }
                } catch (error) {
                    fallbackToRenderingPreview();
                }
            }
        }
    }, [patternFileUrl, isUrlSvg, svgData, patternTileDimension, height, width, fallbackToRenderingPreview]);

    useEffect(() => {
        if (visibleCanvasRef.current) {
            drawCanvas();
        }
    }, [drawCanvas]);

    return (
        <canvas
            style={{ display: show ? "block" : "none" }}
            aria-label={t(patternMessage.patternPreviewCanvas.id)}
            role="img"
            ref={visibleCanvasRef}
        ></canvas>
    );
};
