import React from "react";
import { Button, Icon } from "@vp/swan";
import styles from "./ElementsRowArrowButton.module.scss";

export const ElementsRowArrowButton = (props: Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "children">) => {
    return (
        <Button className={styles.elementsRowArrowButton} buttonShape="round" size="mini" mb={"5"} {...props}>
            <Icon iconType="chevronRight" size="16p" />
        </Button>
    );
};

ElementsRowArrowButton.displayName = "ElementsRowArrowButton";
